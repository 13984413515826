import React, { useState, useMemo } from 'react';
import {
	Box,
	Paper,
	Typography,
	Checkbox,
	IconButton,
	FormGroup,
	FormControlLabel,
	MenuItem,
	FormControl,
	InputLabel,
	Button,
	Grid,
	TextField,
	Modal,
	Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useRequest, useToggle } from 'ahooks';
import useToast from 'src/components/Toast/useToast';
import dayjs from 'dayjs';
// import { getDuration } from "src/utils/libs";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import certificationApi from 'src/api/Dashboard.certificate.api';
import { useLocation } from 'react-router-dom';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 400,
	width: '100%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 3,
};

// const testData = [
// 	{
// 		"id": "f5c80496-fde7-4a5a-b7ca-90d78173f5d3",
// 		"createdAt": "2024-09-05T18:53:05.151Z",
// 		"updatedAt": "2024-09-05T18:53:05.151Z",
// 		"deletedAt": null,
// 		"resumeId": "9a9db5e3-aa07-4782-a947-99c7c2c2ce50",
// 		"certificationName": "test",
// 		"provider": "testdfgsdf",
// 		"issueYear": "2024",
// 		"issueMonth": "07",
// 		"expireYear": null,
// 		"expireMonth": null,
// 		"noExpire": true,
// 		"certificationId": null,
// 		"certificationUrl": null
// 	},
// 	{
// 		"id": "89fa3326-4183-4bd5-91f4-7eeecaaad30e",
// 		"createdAt": "2024-09-05T18:52:31.021Z",
// 		"updatedAt": "2024-09-05T18:52:31.021Z",
// 		"deletedAt": null,
// 		"resumeId": "9a9db5e3-aa07-4782-a947-99c7c2c2ce50",
// 		"certificationName": "test",
// 		"provider": "testset",
// 		"issueYear": "2019",
// 		"issueMonth": "02",
// 		"expireYear": null,
// 		"expireMonth": null,
// 		"noExpire": true,
// 		"certificationId": null,
// 		"certificationUrl": "asfsadfasf"
// 	}
// ]

export default function Certification({
	certifications = [],
	primaryResume = {},
	getPrimaryResume = async () => {},
}) {
	// console.log(`primaryResume`)
	// console.log(primaryResume)
	const { showSuccess, showError } = useToast();
	const location = useLocation();

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors: formErrors },
	} = useForm({
		values: {},
	});

	const [editStatus, setEditStatus] = useState(false);
	const [selectedCertification, setSelectedCertification] = useState({});
	const [noExpireDate, setNoExpireDate] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		reset();
		setOpen(false);
		setNoExpireDate(false);
	};
	const [locale, setLocale] = useState('en');

	const { loading: loadingAddCertification, run: addCertiffication } =
		useRequest(certificationApi.addCertification, {
			manual: true,
			onSuccess: async (res) => {
				showSuccess('Certification add');
				await getPrimaryResume();
				handleClose();
			},
			onError: () => {
				showError('Something went wrong!');
			},
		});

	const { loading: loadingEditCertification, run: editCertiffication } =
		useRequest(certificationApi.editCertification, {
			manual: true,
			onSuccess: async (res) => {
				showSuccess('Certification Updated');
				await getPrimaryResume();
				handleClose();
			},
			onError: () => {
				showError('Something went wrong!');
			},
		});

	const { loading: loadingDeleteCertification, run: deleteCertiffication } =
		useRequest((id) => certificationApi.deleteCertification(id), {
			manual: true,
			onSuccess: async (res) => {
				showSuccess('Certification Deleted');
				await getPrimaryResume();
				handleClose();
			},
			onError: () => {
				showError('Something went wrong!');
			},
		});

	// const saveCertificationData = async (data) => {
	// 	console.log('saved data: ', data);
	// }

	const onSave = (data) => {
		const payload = {
			certificationName: data.certificationName,
			provider: data.provider,
			issueYear: dayjs(data.issuedAt).format('YYYY'),
			issueMonth: dayjs(data.issuedAt).format('MM'),
			expireYear: !noExpireDate ? dayjs(data.expireAt).format('YYYY') : null,
			expireMonth: !noExpireDate ? dayjs(data.expireAt).format('MM') : null,
			noExpire: noExpireDate,
			certificationId: data.credentialId,
			certificationUrl: data.credentialUrl,
		};
		// console.log(payload)
		if (editStatus) {
			payload.id = selectedCertification.id;
			editCertiffication(payload);
		} else {
			payload.resumeId = primaryResume.id;
			addCertiffication(payload);
		}
	};

	const loading =
		loadingAddCertification ||
		loadingDeleteCertification ||
		loadingEditCertification;

	return (
		<Box>
			<Paper
				sx={{
					p: 2,
					minHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div className="w-full">
					<div className="w-full flex justify-between pb-4">
						<Typography variant="h6" gutterBottom>
							Certifications
						</Typography>
						{Object.keys(primaryResume).length !== 0 ? (
							<IconButton
								color="primary"
								aria-label="edit-my-ideal-job"
								onClick={() => {
									setEditStatus(false);
									handleOpen();
								}}
								sx={{
									display: location.pathname.includes('public')
										? 'none'
										: 'flex',
								}}
							>
								<AddCircleIcon />
							</IconButton>
						) : null}
					</div>
					<div className="overflow-y-scroll max-h-60">
						{certifications.map((value, index) => {
							return (
								<div
									key={index}
									className="flex items-center justify-between border-b border-solid border-[#f9f9f9] hover:bg-zinc-200 pl-2"
								>
									<Stack direction="column">
										<Typography variant="h6">
											{value.certificationName}
										</Typography>
										<Typography variant="caption">
											{value.provider} - Issued {value.issueYear}/
											{value.issueMonth}
										</Typography>
									</Stack>
									<IconButton
										color="primary"
										aria-label="edit-my-ideal-job"
										onClick={() => {
											setEditStatus('edit');
											setSelectedCertification(value);

											setValue('certificationName', value.certificationName);
											setValue('provider', value.provider);
											setValue(
												'issuedAt',
												dayjs(`${value.issueYear}/${value.issueMonth}/01`)
											);
											if (!value.noExpire) {
												setValue(
													'expireAt',
													dayjs(`${value.expireYear}/${value.expireMonth}/01`)
												);
											}
											setValue('credentialId', value.certificationId);
											setValue('credentialUrl', value.certificationUrl);

											handleOpen();
										}}
										sx={{
											display: location.pathname.includes('public')
												? 'none'
												: 'flex',
										}}
									>
										<EditOutlinedIcon />
									</IconButton>
								</div>
							);
						})}
					</div>
				</div>
			</Paper>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography variant="h6" className="pb-4">
						{!editStatus ? 'Add Certification' : 'Edit Certification'}
					</Typography>
					<Grid container rowSpacing={2} spacing={1} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={12}>
							<Controller
								name="certificationName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="certificationName"
										size="small"
										value={value}
										onChange={onChange}
										required
										label="Name"
										variant="outlined"
										error={!!error}
										helperText={error?.message}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Controller
								name="provider"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Issuing organization is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="provider"
										size="small"
										required
										value={value}
										onChange={onChange}
										label="Issuing organization"
										variant="outlined"
										error={!!error}
										helperText={error?.message}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale={locale}
							>
								<Controller
									name="issuedAt"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Issue Time is required',
										},
									}}
									render={({
										field: { onChange, value, ref },
										fieldState: { error },
									}) => (
										<DatePicker
											size="small"
											label="Issued At"
											views={['year', 'month']}
											ref={ref}
											value={value}
											onChange={onChange}
											slotProps={{
												textField: {
													fullWidth: true,
													helperText: error?.message,
													error: !!error,
													rules: {
														required: {
															value: true,
															message: 'Issue Time is required',
														},
													},
												},
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} sm={12}>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale={locale}
							>
								<Controller
									name="expireAt"
									control={control}
									rules={{
										required: {
											value: !noExpireDate,
											message: 'Expire Time is required',
										},
									}}
									render={({
										field: { onChange, value, ref },
										fieldState: { error },
									}) => (
										<DatePicker
											size="small"
											label="Expire At"
											views={['year', 'month']}
											ref={ref}
											value={value}
											onChange={onChange}
											disabled={noExpireDate}
											slotProps={{
												textField: {
													fullWidth: true,
													helperText: !noExpireDate ? error?.message : '',
													error: !noExpireDate && !!error,
													rules: {
														required: {
															value: !noExpireDate,
															message: 'Expire Time is required',
														},
													},
												},
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} sm={12}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											value={true}
											checked={noExpireDate}
											onChange={(e) => {
												setNoExpireDate(e.target.checked);
											}}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
									label="The credential does not expire"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Controller
								name="credentialId"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										size="small"
										label="Credential ID"
										value={value}
										onChange={onChange}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Controller
								name="credentialUrl"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										size="small"
										label="Credential URL"
										value={value}
										onChange={onChange}
										fullWidth
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
													// onClick={() => { window.open(newCertificationData.certificationUrl, '_blank'); }}
													>
														<DirectionsIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} sx={{ textAlign: 'right' }}>
							{editStatus ? (
								<LoadingButton
									sx={{ textTransform: 'none', mr: 1, float: 'left' }}
									variant="text"
									size="small"
									color="error"
									onClick={() => {
										deleteCertiffication(selectedCertification?.id);
									}}
									loading={loading}
								>
									Delete
								</LoadingButton>
							) : (
								<></>
							)}
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => handleClose()}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={() => {
									handleSubmit((data) => onSave(data))();
								}}
								endIcon={<SaveIcon />}
								loading={loading}
								loadingPosition="end"
								variant="contained"
								// disabled={isSaving || !!Object.keys(formErrors).length}
							>
								Save
							</LoadingButton>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Box>
	);
}
