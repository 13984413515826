import useBackdrop from 'src/components/Backdrop/useBackdrop';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function SubscribeFail() {
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();

	return (
		<>
			<div className="flex flex-col items-center h-full">
				<div className="text-red-500" style={{ fontSize: '96px' }}>
					<HighlightOffIcon color="inherit" fontSize="inherit" />
				</div>
				<span className="text-slate-700 text-2xl font-bold">
					Payment Failed
				</span>
				<div className="w-96 mt-4 text-slate-600">
					Sorry, your order could not be completed because the payment for this
					transaction has failed. Please check your payment details. In casse of
					queries please contact us{' '}
					<span className="text-slate-800 font-semibold">
						helpdesk@jobbly.io
					</span>
				</div>
			</div>
			<BackdropComponent></BackdropComponent>
		</>
	);
}
