import React, { useState } from 'react';
import {
	Box,
	Paper,
	Typography,
	Chip,
	IconButton,
	FormControl,
	OutlinedInput,
	MenuItem,
	InputLabel,
	Select,
	Button,
	Grid,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
	JOB_LOCATIONS,
	JOB_TYPES,
	JOB_LEVELS,
	WORK_TYPES,
} from 'src/constants/dashboard';
import { useRequest, useToggle } from 'ahooks';
import { mainClass } from './styles';
import { Margin } from '@mui/icons-material';
import useToast from 'src/components/Toast/useToast';
import idealJobApi from 'src/api/Dashboard.idealJob.api';
import { useLocation } from 'react-router-dom';

const renderSelection = (selected) => (
	<Box sx={mainClass.chipSelection}>
		{selected.map((value) => (
			<Chip size="small" key={value} label={value} />
		))}
	</Box>
);

const renderMenuItems = (item) => (
	<MenuItem key={item} value={item}>
		{item}
	</MenuItem>
);

const renderChips = (val) => (
	<Chip size="small" key={val} label={val} sx={{ m: 0.1 }} />
);

export default function IdealJob({
	jobLocations = [],
	jobType = [],
	jobLevel = [],
	workType = [],
	userId = '',
}) {
	const [isEditing, { toggle: setIsEditing }] = useToggle(false);
	const [locations, setLocations] = useState([...jobLocations]);
	const [jobTypes, setJobTypes] = useState([...jobType]);
	const [jobLevels, setJobLevels] = useState([...jobLevel]);
	const [workTypes, setWorkTypes] = useState([...workType]);
	const { showSuccess, showError } = useToast();
	const location = useLocation();

	const handleIdealJobChange = (event, action) => {
		const {
			target: { value },
		} = event;

		const setState = {
			setLocations,
			setJobTypes,
			setJobLevels,
			setWorkTypes,
		};
		setState[action](typeof value === 'string' ? value.split(',') : value);
	};

	const {
		data,
		loading,
		run: updateIdealJob,
	} = useRequest(
		(id, location, jobType, jobLevel, workType) => {
			idealJobApi.updateIdealJob(id, location, jobType, jobLevel, workType);
		},
		{
			manual: true,
			onSuccess: () => {
				showSuccess('My ideal job updated');
				setLocations([...locations]);
				setJobTypes([...jobTypes]);
				setJobLevels([...jobLevels]);
				setWorkTypes([...workTypes]);
			},
			onError: (err) => {
				showError('Something went wrong!');
			},
		}
	);

	const onIdealJobSave = (event) => {
		updateIdealJob(userId, locations, jobTypes, jobLevels, workTypes);
		setIsEditing();
	};

	return (
		<Box>
			<Paper
				sx={{
					p: 2,
					minHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box sx={{ flex: '0 0 auto' }}>
					<Typography variant="h6" gutterBottom>
						My Ideal Job:
					</Typography>
				</Box>
				<Box sx={{ flex: '0 0 auto', mb: 1 }}>
					{isEditing ? (
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<InputLabel id="locations">Locations</InputLabel>
									<Select
										labelId="locations"
										id="locations"
										multiple
										value={locations}
										onChange={(event) =>
											handleIdealJobChange(event, 'setLocations')
										}
										input={
											<OutlinedInput
												id="select-lang-speak"
												label="Locations"
												fullWidth
											/>
										}
										renderValue={renderSelection}
									>
										{JOB_LOCATIONS.map(renderMenuItems)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<InputLabel id="jobTypes">Job Types</InputLabel>
									<Select
										labelId="jobTypes"
										id="jobTypes"
										multiple
										value={jobTypes}
										onChange={(event) =>
											handleIdealJobChange(event, 'setJobTypes')
										}
										input={
											<OutlinedInput
												id="select-job-types"
												label="Job Types"
												fullWidth
											/>
										}
										renderValue={renderSelection}
									>
										{JOB_TYPES.map(renderMenuItems)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<InputLabel id="jobLevels">Job Levels</InputLabel>
									<Select
										labelId="jobLevels"
										id="jobLevels"
										multiple
										value={jobLevels}
										onChange={(event) =>
											handleIdealJobChange(event, 'setJobLevels')
										}
										input={
											<OutlinedInput
												id="select-job-levels"
												label="Job Levels"
												fullWidth
											/>
										}
										renderValue={renderSelection}
									>
										{JOB_LEVELS.map(renderMenuItems)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<InputLabel id="workTypes">Work Types</InputLabel>
									<Select
										labelId="workTypes"
										id="workTypes"
										multiple
										value={workTypes}
										onChange={(event) =>
											handleIdealJobChange(event, 'setWorkTypes')
										}
										input={
											<OutlinedInput
												id="select-work-types"
												label="Work Types"
												fullWidth
											/>
										}
										renderValue={renderSelection}
									>
										{WORK_TYPES.map(renderMenuItems)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					) : (
						<>
							<Typography variant="subtitle1">Locations:</Typography>
							<Box>{locations.map(renderChips)}</Box>
							<Typography variant="subtitle1">Types:</Typography>
							<Box>{jobTypes.map(renderChips)}</Box>
							<Typography variant="subtitle1">Level:</Typography>
							<Box>{jobLevels.map(renderChips)}</Box>
							<Typography variant="subtitle1">Work Type:</Typography>
							<Box>{workTypes.map(renderChips)}</Box>
						</>
					)}
				</Box>
				<Box
					sx={{
						marginTop: 'auto',
						justifyContent: 'flex-end',
						display: location.pathname.includes('public') ? 'none' : 'flex',
					}}
				>
					{isEditing ? (
						<Button
							color="primary"
							aria-label="save-my-ideal-job"
							variant="contained"
							size="small"
							onClick={onIdealJobSave}
						>
							Save
						</Button>
					) : (
						<IconButton
							color="primary"
							aria-label="edit-my-ideal-job"
							onClick={setIsEditing}
						>
							<EditOutlinedIcon />
						</IconButton>
					)}
				</Box>
			</Paper>
		</Box>
	);
}
