import axios from 'axios';
import history from '../libs/history';
const dayjs = require('dayjs');

export default class PublicApi {
	constructor() {
		const baseUrl = process.env.REACT_APP_API_SERVER_URL;
		const prefix = '/public';
		const apiInstance = axios.create({
			baseURL: baseUrl,
			timeout: 9 * 1000,
		});

		apiInstance.interceptors.request.use(
			(req) => {
				const userId = localStorage.getItem('user-id');
				req.headers['x-user-id'] = userId;
				req.headers['tz-offset'] = dayjs().utcOffset(); // minutes
				req.url = prefix + req.url;
				return req;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		apiInstance.interceptors.response.use(this.handleSuccess, this.handleError);

		this.prefix = prefix;
		this.instance = apiInstance;
	}

	handleSuccess(response) {
		return response;
	}
	handleError(error) {
		switch (error.response.status) {
			case 401:
				history.push('/sign-in');
				break;
			case 500:
				return Promise.reject(error.response.data);
			default:
				return Promise.reject(error.response.data);
		}
	}

	get(...args) {
		return this.instance.get(...args);
	}

	post(...args) {
		return this.instance.post(...args);
	}

	put(...args) {
		return this.instance.put(...args);
	}

	patch(...args) {
		return this.instance.patch(...args);
	}

	delete(...args) {
		return this.instance.delete(...args);
	}
}
