import ApiFactory from 'src/api/ApiFactory';
import dayjs from 'dayjs';

const api = {
	getCurrentWebUser() {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/user/me`);
	},
	updateUser(
		id,
		{
			firstName,
			lastName,
			phone,
			citizenship,
			employmentStatus,
			visaStatus,
			address,
			city,
			state,
			zipCode,
			isPublic,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			firstName,
			lastName,
			phone,
			citizenship,
			employmentStatus,
			visaStatus,
			address,
			city,
			state,
			zipCode,
			isPublic,
		};
		return apiInstance.put(`/user`, data);
	},
	// activities
	getUserActivities(
		startDate = dayjs().subtract(1, 'y').format('YYYY-MM-DD'),
		endDate = dayjs().format('YYYY-MM-DD')
	) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/payment/activity/accumulate`, {
			params: {
				startDate,
				endDate,
			},
		});
	},
	getUserActivittDetail(startDate, endDate) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/payment/activity/event`, {
			params: {
				startDate,
				endDate,
			},
		});
	},
	getUserPaymentHistory() {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/payment`, {
			params: {
				sortby: 'paidAt',
				order: 'desc',
			},
		});
	},
};
export default api;
