import React, { useState } from 'react';
import {
	Box,
	Paper,
	Typography,
	Chip,
	IconButton,
	Select,
	MenuItem,
	OutlinedInput,
	FormControl,
	InputLabel,
	Button,
	Stack,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useRequest, useToggle } from 'ahooks';
import langApi from 'src/api/Dashboard.lang.api';
import useToast from 'src/components/Toast/useToast';
import { LANGUAGES } from 'src/constants/dashboard';
import { useLocation } from 'react-router-dom';

export default function MyLanguage({ langs = [], userId = '' }) {
	const [isEditing, { toggle: setIsEditing }] = useToggle(false);
	const [speakLangs, setSpeakLangs] = React.useState([...langs]);
	const { showSuccess, showError } = useToast();
	const location = useLocation();

	const {
		data: userLangs,
		loading,
		run: UpdateUserLangs,
	} = useRequest(
		(id, languages) => {
			langApi.updateLangs(id, languages);
		},
		{
			manual: true,
			onSuccess: () => {
				showSuccess('Languages updated');
				setSpeakLangs([...speakLangs]);
			},
			onError: () => {
				showError('Something went wrong!');
			},
		}
	);

	const onSpeakLangsSave = (event) => {
		UpdateUserLangs(userId, speakLangs);
		setIsEditing();
	};

	const handleSpeakLangsChange = (event) => {
		const {
			target: { value },
		} = event;
		setSpeakLangs(typeof value === 'string' ? value.split(',') : value);
	};

	return (
		<Box>
			<Paper
				sx={{
					p: 2,
					minHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box sx={{ flex: '0 0 auto' }}>
					<Typography variant="h6" gutterBottom>
						Languages I Speak:
					</Typography>
				</Box>
				{isEditing ? (
					<>
						<Box sx={{ flex: '0 0 auto' }}>
							<FormControl fullWidth>
								<InputLabel id="lang-speak">Languages</InputLabel>
								<Select
									labelId="lang-speak"
									id="lang-speak"
									multiple
									value={speakLangs}
									onChange={handleSpeakLangsChange}
									input={
										<OutlinedInput
											id="select-lang-speak"
											label="Languages"
											fullWidth
										/>
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: 'flex',
												flexWrap: 'wrap',
												gap: 0.5,
											}}
										>
											{selected.map((value) => (
												<Chip size="small" key={value} label={value} />
											))}
										</Box>
									)}
								>
									{LANGUAGES.map((lang) => (
										<MenuItem key={lang} value={lang}>
											{lang}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box
							sx={{
								marginTop: 'auto',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Stack spacing={2} direction="row">
								{/* <Button
									size="small"
									variant="outlined"
									onClick={onSpeakLangsCancel}
								>
									Cancel
								</Button> */}
								<Button
									size="small"
									variant="contained"
									onClick={onSpeakLangsSave}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</>
				) : (
					<>
						<Box sx={{ flex: '0 0 auto' }}>
							{speakLangs.map((value) => (
								<Box key={value}>
									<Chip
										size="small"
										key={value}
										label={value}
										sx={{ m: 0.5 }}
									/>
								</Box>
							))}
						</Box>
						<Box
							sx={{
								marginTop: 'auto',
								display: location.pathname.includes('public') ? 'none' : 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<IconButton
								color="primary"
								aria-label="edit-my-language"
								onClick={setIsEditing}
							>
								<EditOutlinedIcon />
							</IconButton>
						</Box>
					</>
				)}
			</Paper>
		</Box>
	);
}
