import React, { useState, useEffect, useMemo } from 'react';
import userApi from 'src/api/user.api';
import { useBoolean, useRequest } from 'ahooks';
import CircularProgress from '@mui/material/CircularProgress';
//
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import styled from 'styled-components';
import DetailDialog from './components/DetailDialog';

export default function Activities() {
	const [selectedMonth, setSelectedMonth] = useState(false);
	const [showDialog, { toggle, setFalse: closeDialog }] = useBoolean(false);

	const { data, loading } = useRequest(() => userApi.getUserActivities());
	const rowData = useMemo(() => {
		const list = data?.data || [];
		return list
			.filter((i) => dayjs(i.month, 'YYYYMM').isValid())
			.map((i) => ({
				...i,
				datetime: dayjs(i.month, 'YYYYMM'),
				datetimeFormatted: dayjs(i.month, 'YYYYMM').format('YYYY-MM'),
			}));
	}, [data]);

	return (
		<div>
			<span className="text-xl text-slate-500 font-bold">Activities</span>
			<DetailDialog
				show={showDialog}
				selectedMonth={selectedMonth}
				closeDialog={closeDialog}
			/>
			<TableContainer component={Paper} style={{ marginTop: 20 }}>
				<Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 600 }}>Month</TableCell>
							<TableCell style={{ fontWeight: 600 }}>Usage</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell colSpan={2} align="center">
									<CircularProgress />
								</TableCell>
							</TableRow>
						) : rowData.length > 0 ? (
							rowData.map((row, index) => (
								<StyledTableRow
									key={index}
									onClick={() => {
										setSelectedMonth(row.datetime);
										toggle();
									}}
								>
									<TableCell component="th" scope="row">
										{row.datetimeFormatted}
									</TableCell>
									<TableCell>{row.count_USAGE}</TableCell>
								</StyledTableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={2} component="th" scope="row">
									No Activity Data Found
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

const StyledTableRow = styled(TableRow)`
	cursor: pointer;
	:hover {
		background-color: #ecf2ff;
	}
`;
