import LoadingButton from '@mui/lab/LoadingButton';
import {
	Grid,
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Stack,
} from '@mui/material';
import { useRequest } from 'ahooks';
import paymentApi from 'src/api/payment.api';
import CircularProgress from '@mui/material/CircularProgress';
import { useMemo, useState } from 'react';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import alertStore from 'src/stores/alert.store';
// import userAuthStore from 'src/stores/auth.store';

export default function Subscriptions({ user = {} }) {
	// const redirectUrl = `${window.location.origin}/subscribe/confirm`
	const [loadingButton, setLoadingButton] = useState(false);

	const { data: planListData, loading } = useRequest(() =>
		paymentApi.getSubscriptionPlans()
	);

	const planList = useMemo(
		() => planListData?.data?.data?.plans || [],
		[planListData]
	);

	const cancelSubscriptionClicked = async () => {
		setLoadingButton(true);
		try {
			await paymentApi.patchSubscriptionCancel({
				reason: 'cancel next subscription',
			});
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Cancel subscription Success!',
				alertSeverity: 'success',
			});
		} catch (err) {
			alertStore.setState({
				alertOpen: true,
				alertMessage: err?.errorMsg,
				alertSeverity: 'error',
			});
			setLoadingButton(false);
		}
	};

	// const user = userAuthStore((state) => state);
	// console.log(user);

	const onPlanClicked = async (planId) => {
		setLoadingButton(true);
		try {
			const response = await paymentApi.postSubscription({
				plan_id: planId,
				application_context: {
					return_url: `${window.location.origin}/subscribe/confirm`,
					cancel_url: `${window.location.origin}/subscribe/fail`,
				},
				custom_id: user?.userId,
			});
			const approveLink = response.data.data.links.filter(
				(link) => link.rel === 'approve'
			);

			window.location.href = `${approveLink[0].href}`;
		} catch (err) {
			console.error(err);
			setLoadingButton(false);
		}
	};

	const pricingTable = {
		'Weekly Plan': '$ 4.99',
		'Monthly Plan': '$ 9.99',
		'Quarterly Plan': '$ 29.99',
	};

	return (
		<div className="bg-white border border-gray-200 rounded-lg shadow w1024:px-5 w1024:py-5 px-10 py-8 w-full">
			{user?.isPaymentPending ? (
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<div className="text-xl text-slate-500 font-bold">
							Payment is still processing <br /> ID: {user?.subscriptionId}
						</div>
					</Grid>
				</Grid>
			) : user?.isVip ? (
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<div className="text-xl text-slate-500 font-bold">
							Hello! <br /> Expiration Date: {user?.vipExpiredAt}
						</div>
					</Grid>
					<Grid>
						{user?.subscriptionId ? (
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={cancelSubscriptionClicked}
								loadingPosition="end"
								variant="contained"
							>
								<span>Cancel Next Subscription</span>
							</LoadingButton>
						) : (
							<span>Already Cancel Subscription</span>
						)}
					</Grid>
				</Grid>
			) : (
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						<div className="text-xl text-slate-500 font-bold">Jobbly Plus</div>
						<div className="text-sm text-slate-500">
							A weekly, monthly, or quarterly subscription plan designed to fuel
							your job search journey.
						</div>
					</Grid>
					<Grid container item xs={12}>
						{/* {loading ? (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress />
							</Box>
						) : (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								{planList?.map((item, index) => (
									<LoadingButton
										loading={loadingButton}
										variant="outlined"
										key={index}
										onClick={() => onPlanClicked(item.id)}
									>
										{item.name}
									</LoadingButton>
								))}
							</Box>
						)} */}
						<Grid item xs={8} sm={8}>
							<List>
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<AutoFixHighOutlinedIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Unlimited Resume Tailoring Based on Job Descriptions and Keywords" />
								</ListItem>
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<MailOutlinedIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Unlimited Cover Letter Personalization" />
								</ListItem>
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<SupportAgentOutlinedIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Priority Customer Support" />
								</ListItem>
							</List>
						</Grid>
						{loading ? (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress />
							</Box>
						) : (
							<Grid item xs={4} sm={4}>
								<Stack spacing={2}>
									{planList?.map((item, index) => (
										<LoadingButton
											loading={loadingButton}
											variant="outlined"
											key={index}
											onClick={() => onPlanClicked(item.id)}
										>
											{item.name} - {pricingTable[item.name]}
										</LoadingButton>
									))}
								</Stack>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</div>
	);
}
