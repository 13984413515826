import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import PDFRenderer from './PDFRenderer';

export default function ExportResumePDFDialog({
	showExportPDFDialog,
	setShowExportPDFDialog,
	resume,
}) {
	return (
		<Dialog
			// keepMounted
			disableEscapeKeyDown={true}
			fullWidth={true}
			maxWidth="lg"
			open={showExportPDFDialog}
			onClose={(_, reason) => {}}
		>
			<DialogTitle>Exporting Resume</DialogTitle>
			<DialogContent>
				<PDFRenderer
					resume={resume}
					closeDialog={() => {
						setShowExportPDFDialog(false);
					}}
				/>
			</DialogContent>
			{/* <DialogActions>

      </DialogActions> */}
		</Dialog>
	);
}
