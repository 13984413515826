import { Alert, Snackbar } from '@mui/material';
import alertStore from 'src/stores/alert.store';

function useToast() {
	const alertOpen = alertStore((state) => state.alertOpen);
	const alertMessage = alertStore((state) => state.alertMessage);
	const alertSeverity = alertStore((state) => state.alertSeverity);

	const handleClose = () => {
		alertStore.setState({ alertOpen: false });
	};

	const showSuccess = (message) => {
		alertStore.setState({
			alertOpen: true,
			alertMessage: message,
			alertSeverity: 'success',
		});
	};

	const showError = (message = 'Something went wrong!') => {
		alertStore.setState({
			alertOpen: true,
			alertMessage: message,
			alertSeverity: 'error',
		});
	};

	const ToastComponent = () => {
		return (
			<>
				<Snackbar
					open={alertOpen}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					autoHideDuration={3000}
					sx={{ marginTop: '-10px', marginRight: '-15px' }}
				>
					<Alert severity={alertSeverity} onClose={handleClose}>
						{alertMessage}
					</Alert>
				</Snackbar>
			</>
		);
	};

	return { ToastComponent, showSuccess, showError };
}

export default useToast;
