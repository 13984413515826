import React, { useEffect, useState, useRef } from 'react';
import Basic from '../../../../../components/Resume/Basic';
import Profile from '../../../../../components/Resume/Profile';
import Summary from '../../../../../components/Resume/Summary';
import Experience from '../../../../../components/Resume/Experience';
import Education from '../../../../../components/Resume/Education';
import Project from '../../../../../components/Resume/Project';
import Skill from '../../../../../components/Resume/Skill';
import Certification from '../../../../../components/Resume/Certification';
import formStore from '../../../../../stores/form.store';
import jobApi from '../../../../../api/job.api';
import resumeApi from '../../../../../api/resume.api';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import { Link } from 'react-router-dom';

function ConfirmationDialogRaw(props) {
	const { onClose, open, ...other } = props;
	const [value, setValue] = React.useState();
	const radioGroupRef = React.useRef(null);
	const [options, setOptions] = useState([]);

	const listResume = async () => {
		try {
			const response = await resumeApi.listResume({
				sortby: 'updatedAt',
				order: 'desc',
				limit: 0,
				field: 'id,resumeName',
			});
			setOptions(response.data.rows);
		} catch (err) {}
	};

	useEffect(() => {
		console.log("the data of 'Resume' component is loaded!!!!!!");
		listResume();
	}, []);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		onClose(value);
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth="xs"
			TransitionProps={{ onEntering: handleEntering }}
			open={open}
			{...other}
		>
			<DialogTitle>Select existing resume</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					aria-label="ringtone"
					name="ringtone"
					onChange={handleChange}
				>
					{options.map((option) => (
						<FormControlLabel
							value={option.id}
							key={option.id}
							control={<Radio />}
							label={option.resumeName}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<div className="flex flex-row w-full py-1">
					<Button
						component={Link}
						to="/resume-builder"
						sx={{ textTransform: 'none' }}
					>
						Create Resume
					</Button>
					<div className="grow"></div>
					<Button
						autoFocus
						onClick={handleCancel}
						sx={{ textTransform: 'none' }}
					>
						Cancel
					</Button>
					<Button onClick={handleOk} sx={{ textTransform: 'none' }}>
						Ok
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

ConfirmationDialogRaw.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default function Resume({ setUpdatedAt }) {
	const hasResume = useRef(false);
	const selectedJobId = formStore((state) => state.selectedJobId);
	const [resumeData, setResumeData] = useState({});
	const [open, setOpen] = useState(false);
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();

	const getResumeData = async () => {
		try {
			showBackdrop();
			const response = await jobApi.getResume(selectedJobId);
			setResumeData(response.data);
			formStore.setState({ selectedResumeId: response.data.id });
			hasResume.current = true;
			getJobData();
		} catch (err) {
			hasResume.current = false;
			console.error(err);
		} finally {
			closeBackdrop();
		}
	};

	const getJobData = async () => {
		const response = await jobApi.getJob(selectedJobId);
		setUpdatedAt(response.data.updatedAt);
	};

	const handleClose = async (newValue) => {
		setOpen(false);
		console.log('handleClose', newValue);

		if (newValue) {
			console.log('selectedResumeId', newValue);
			try {
				await resumeApi.duplicateResume({
					resumeId: newValue,
					jobId: selectedJobId,
				});
				getResumeData();
			} catch (err) {
				console.error(err);
			}
		}
	};

	useEffect(() => {
		if (selectedJobId) {
			getResumeData();
		}
		return () => {};
	}, [selectedJobId]);

	return (
		<div className="flex flex-col gap-y-4">
			{hasResume.current ? (
				<React.Fragment>
					<Button
						size="medium"
						variant="outlined"
						sx={{ textTransform: 'none', width: '280px' }}
						onClick={() => setOpen(true)}
					>
						Select existing resume
					</Button>
					<ConfirmationDialogRaw
						keepMounted
						open={open}
						onClose={handleClose}
					/>

					{/* <Basic resumeData={resumeData} onSuccessSaved={getResumeData}></Basic> */}
					<Profile
						profileData={resumeData.profile}
						onSuccessSaved={getResumeData}
					></Profile>
					<Summary
						summaryData={resumeData.summary}
						onSuccessSaved={getResumeData}
					></Summary>
					<Experience
						experiencesData={resumeData.experiences}
						onSuccessSaved={getResumeData}
					></Experience>
					<Education
						educationsData={resumeData.educations}
						onSuccessSaved={getResumeData}
					></Education>
					<Project
						projectsData={resumeData.projects}
						onSuccessSaved={getResumeData}
					></Project>
					<Certification
						certificationsData={resumeData.certifications}
						onSuccessSaved={getResumeData}
					></Certification>
					<Skill
						skillsData={resumeData.skills}
						onSuccessSaved={getResumeData}
					></Skill>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Button
						size="medium"
						variant="outlined"
						sx={{ textTransform: 'none', width: '280px' }}
						onClick={() => setOpen(true)}
					>
						Select existing resume
					</Button>
					<ConfirmationDialogRaw
						keepMounted
						open={open}
						onClose={handleClose}
					/>
				</React.Fragment>
			)}
			<BackdropComponent></BackdropComponent>
		</div>
	);
}
