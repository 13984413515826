import PrivateApi from './PrivateApi';
import PublicApi from './PublicApi';

class ApiFactory {
	hi() {
		console.log('hello world');
	}
	create(type) {
		// TODO: is there a better way????????
		if (window.location.href.indexOf('public') > -1) {
			return new PublicApi();
		}
		return new PrivateApi();
	}
}

export default new ApiFactory();
