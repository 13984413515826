import { useEffect, useRef, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRequest } from 'ahooks';
import userApi from 'src/api/user.api';
import dayjs from 'dayjs';
import { ActivityEvents } from '../ActivityEvents';
// import { ActivityEvents } from '../ActivityEvents';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

export default function DetailDialog({
	selectedMonth,
	show = false,
	closeDialog = () => {},
}) {
	// if (selectedMonth) {
	//     console.log(`selectedMonth.startOf('month'), selectedMonth.endOf('month')`);
	//     console.log(selectedMonth.startOf('month'), selectedMonth.endOf('month'));
	// }
	const { data, loading } = useRequest(
		() =>
			userApi.getUserActivittDetail(
				selectedMonth.startOf('month').format('YYYY-MM-DD'),
				selectedMonth.endOf('month').format('YYYY-MM-DD')
			),
		{
			ready: !!selectedMonth,
			refreshDeps: [selectedMonth],
		}
	);

	const rowData = useMemo(() => {
		const list = data?.data || [];
		return list.map((i) => ({
			...i,
			datetimeFormatted: dayjs(i.time).format('YYYY-MM-DD HH:mm:ss'),
			event: ActivityEvents[i.action],
		}));
	}, [data]);
	// console.log(`rowData`)
	// console.log(rowData)

	const descriptionElementRef = useRef(null);
	useEffect(() => {
		if (show) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [show]);

	return (
		<div>
			<Dialog
				open={show}
				onClose={closeDialog}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					{selectedMonth && selectedMonth.format('YYYY-MM')}
				</DialogTitle>
				<DialogContent dividers={true}>
					<Table sx={{ minWidth: 500 }} aria-label="">
						<TableHead>
							<TableRow>
								<TableCell style={{ fontWeight: 600 }}>Activity</TableCell>
								<TableCell style={{ fontWeight: 600 }}>Date Time</TableCell>
							</TableRow>
						</TableHead>
						{loading ? (
							<TableRow>
								<TableCell colSpan={2} align="center">
									<CircularProgress />
								</TableCell>
							</TableRow>
						) : (
							<TableBody>
								{rowData.length > 0 ? (
									rowData.map((row, index) => (
										<TableRow key={index}>
											<TableCell component="th" scope="row">
												{row.event}
											</TableCell>
											<TableCell>{row.datetimeFormatted}</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={3} component="th" scope="row">
											No Activity Data Found
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						)}
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
