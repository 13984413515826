import { create } from 'zustand';

export default create((set) => ({
	alertOpen: false,
	alertMessage: 'hello world test',
	alertSeverity: 'info',
	setAlertOpen: () =>
		set((state) => ({ ...state, alertOpen: state.alertOpen })),
	setAlertMessage: () =>
		set((state) => ({ ...state, alertMessage: state.alertMessage })),
	setAlertSeverity: () =>
		set((state) => ({ ...state, alertSeverity: state.alertSeverity })),

	spinnerOpen: false,
	setSpinner: () =>
		set((state) => ({ ...state, spinnerOpen: state.spinnerOpen })),
}));
