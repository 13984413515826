import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import TagManager from 'react-gtm-module';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={theme}>
		<App />
	</ThemeProvider>
);
reportWebVitals();
