import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Logo from '../../assets/logo.svg';
import Button from '@mui/material/Button';

function ResponsiveAppBar() {
	const navigate = useNavigate();
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	return (
		<AppBar position="fixed">
			<Container maxWidth="false">
				<Toolbar disableGutters>
					<img alt="jobbly-logo" className="h-8" src={Logo} />
					<Button
						component={Link}
						to="/"
						sx={{
							my: 2,
							color: 'white',
							display: 'block',
							textTransform: 'none',
						}}
					>
						<div className="flex flex-row items-end gap-x-1">
							<span className="font-bold text-white text-2xl cursor-pointer">
								Jobbly
							</span>
							<span className="text-xs text-gray-400">beta</span>
						</div>
					</Button>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;
