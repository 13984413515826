import '../../styles/theme.scss';
import Logo from '../../assets/logo-title.svg';
import {
	GoogleLoginButton,
	LinkedInLoginButton,
} from 'react-social-login-buttons';
import { useSearchParams } from 'react-router-dom';

function SignIn() {
	const [searchParams] = useSearchParams();

	const goToNext = (application) => {
		const locationProtocol = window.location.protocol;
		const locationHostname = window.location.hostname;
		const locationPort = window.location.port ? `:${window.location.port}` : '';
		const applicationType = searchParams.get('type')
			? searchParams.get('type')
			: 'app';
		window.location.replace(
			`${process.env.REACT_APP_API_SERVER_URL}oauth/${application}?frontend_url=${locationProtocol}//${locationHostname}${locationPort}/oauth/result/${applicationType}`
		);
	};
	return (
		<div className="h-screen w-screen flex justify-center items-center">
			<div className="border px-6 py-10 rounded-md shadow-md bg-gray-50">
				<div className="grid grid-cols-1 gap-y-1">
					<div className="flex flex-row items-center justify-center">
						<img alt="jobbly-logo" src={Logo} className="h-12" />
						{/* <span className='text-3xl font-bold text-gray-700'>{applicationName}</span> */}
					</div>
					<GoogleLoginButton onClick={() => goToNext('google')}>
						<span className="pl-2 text-base font-medium text-gray-700">
							Sign in with Google
						</span>
					</GoogleLoginButton>
					{/* <AppleLoginButton onClick={() => alert("Hello, apple")} >
            <span className='pl-2 text-base font-medium text-gray-700'>Sign in with Apple</span>
          </AppleLoginButton>
          <FacebookLoginButton onClick={() => alert("Hello, facebook")}>
            <span className='pl-2 text-base font-medium'>Sign in with Facebook</span>
          </FacebookLoginButton> */}
					<LinkedInLoginButton onClick={() => goToNext('linkedin')}>
						<span className="pl-2 text-base font-medium">
							Sign in with Linkedin
						</span>
					</LinkedInLoginButton>
				</div>
			</div>
		</div>
	);
}

export default SignIn;
