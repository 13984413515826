import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import List from './components/List';
import Top from './components/Top';
import jobApi from 'src/api/job.api';
import * as R from 'ramda';
import formStore from 'src/stores/form.store';
import useBackdrop from 'src/components/Backdrop/useBackdrop';

function JobTracker() {
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [pageLimit] = useState(20);
	const [list, setList] = useState([]);
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [checkedList, setCheckedList] = useState([]);
	const isAllChecked = list.length > 0 && checkedList.length === list.length;
	const jobMode = formStore((state) => state.jobMode);
	const [filter, setFilter] = useState({
		keyword: null,
		company: null,
		title: null,
		description: null,
		selectedLabelList: [],
		status: 'APPLY',
	});

	const handleFilterChange = (value, fieldName) => {
		filter[fieldName] = value;
		setFilter({ ...filter });
		setPage(1);
	};

	const handlePageChange = (event, value) => {
		console.log('handlePageChange', value);
		setPage(value);
	};

	const handleCheckChange = (event) => {
		const value = event.target.value;

		if (value === 'all') {
			console.log(R.map((x) => x.id, list));
			setCheckedList(
				checkedList.length === list.length ? [] : R.map((x) => x.id, list)
			);
			return;
		}

		const originCheckedList = [...checkedList];
		const index = originCheckedList.indexOf(value);
		index === -1
			? originCheckedList.push(value)
			: originCheckedList.splice(index, 1);
		setCheckedList(originCheckedList);
	};

	const listJob = async () => {
		try {
			showBackdrop();
			const queryList = [];
			const containList = [];
			const likeList = [];

			if (filter?.status) queryList.push(`status:${filter.status}`);
			if (filter?.title) likeList.push(`title:${filter.title}`);
			if (filter?.company) likeList.push(`company:${filter.company}`);
			if (filter?.description)
				likeList.push(`description:${filter.description}`);

			if (filter?.selectedLabelList.length) {
				containList.push(
					`labels=${R.map((x) => x.name, filter.selectedLabelList).join('|')}`
				);
			}

			const searchCriteria = {
				sortby: 'updatedAt',
				order: 'desc',
				limit: pageLimit,
				offset: (page - 1) * pageLimit,
				query: queryList.join(','),
				contain: containList.join(','),
				like: likeList.join(','),
				keyword: filter.keyword
					? filter.keyword + ':title|company|description'
					: null,
			};

			const response = await jobApi.listJob(searchCriteria);
			setList(response.data.rows);
			setPageCount(Math.ceil(response.data.count / pageLimit));
			setCheckedList([]);
		} catch (err) {
			console.error(err);
		} finally {
			closeBackdrop();
		}
	};

	useEffect(() => {
		if (jobMode === 'detail') {
			return;
		}
		listJob();
	}, [page, jobMode, filter.status]);

	useEffect(() => {
		return () => {
			formStore.setState({ selectedJobId: null, jobMode: 'list' });
		};
	}, []);

	return (
		<div className="grid grid-cols-[240px_1fr] h-full pb-4 gap-x-10">
			<Sidebar listJob={listJob} handleFilterChange={handleFilterChange} />
			<div className="flex flex-col gap-y-1">
				<Top
					checkedList={checkedList}
					isAllChecked={isAllChecked}
					handleCheckChange={handleCheckChange}
					handlePageChange={handlePageChange}
					listJob={listJob}
					pageCount={pageCount}
					page={page}
				></Top>
				<List
					className="mb-4 grow h-4 overflow-y-auto"
					list={list}
					listJob={listJob}
					handleCheckChange={handleCheckChange}
					checkedList={checkedList}
				></List>
			</div>
			<BackdropComponent></BackdropComponent>
		</div>
	);
}

export default JobTracker;
