import React, { useState, useEffect, useMemo } from 'react';
import styles from './Project.module.scss';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import dayjs from 'dayjs';
import {
	trimHtml,
	getYears,
	getMonths,
	getQuillSimpleFormats,
	getQuillSimpleModules,
} from '../../../libs/utils';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useToast from 'src/components/Toast/useToast';
import resumeApi from 'src/api/resume.api';
import { useForm, Controller } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputAdornment from '@mui/material/InputAdornment';
const classNames = require('classnames');

export default function Item({
	selectedResumeId,
	projectData,
	handleCancelItem,
	handleEditItem,
	onSuccessSaved,
}) {
	const [newProjectData, setNewProjectData] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			projectName: newProjectData.projectName,
		},
	});

	const startDate = useMemo(() => {
		return dayjs(`${projectData.startYear}-${projectData.startMonth}`).format(
			'MMM YYYY'
		);
	}, [projectData.startYear, projectData.startMonth]);

	const endDate = useMemo(() => {
		return dayjs(`${projectData.endYear}-${projectData.endMonth}`).format(
			'MMM YYYY'
		);
	}, [projectData.endYear, projectData.endMonth]);

	const handleInputChange = (value, fieldName) => {
		newProjectData[fieldName] = value;
		setNewProjectData({ ...newProjectData });
	};

	const handleDeleteItem = async (id) => {
		try {
			await resumeApi.deleteProject(id);
			onSuccessSaved();
			showSuccess('Your project has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	const handleSaveItem = async (itemData) => {
		try {
			setIsSaving(true);
			if (itemData.id) {
				await resumeApi.updateProject(itemData.id, { ...itemData });
			} else {
				await resumeApi.createProject({
					resumeId: selectedResumeId,
					...itemData,
				});
			}
			onSuccessSaved();
			showSuccess('Your project has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		console.log("the data of 'Project/Item' component is loaded!!!!!!");
		setNewProjectData({ ...projectData });
	}, [projectData.isEdit]);

	return (
		<div
			className={classNames(
				'flex flex-col',
				styles.item,
				projectData.isEdit ? styles.edit : styles.view
			)}
		>
			{!projectData.isEdit && (
				<React.Fragment>
					<div className="flex flex-row justify-between items-center">
						<span className={`${styles.position}`}>
							{newProjectData.projectName}
						</span>
						<div className="basis-10">
							<IconButton
								color="primary"
								size="small"
								onClick={() => {
									handleEditItem(newProjectData.id);
								}}
							>
								<BorderColorOutlinedIcon />
							</IconButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!!projectData.isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-6 pb-4">
						<div className="grid grid-cols-1">
							<Controller
								name="projectName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Project Name',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="projectName"
										size="small"
										required
										value={newProjectData.projectName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'projectName');
										}}
										label="Project Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<FormControl fullWidth>
								<InputLabel shrink size="small">
									Start Month
								</InputLabel>
								<Select
									label="Start Month"
									size="small"
									value={newProjectData.startMonth}
									input={<OutlinedInput notched label="Start Month" />}
									onChange={(e) => {
										handleInputChange(e.target.value, 'startMonth');
									}}
								>
									{getMonths().map((month, index) => (
										<MenuItem key={index} value={month.value} size="small">
											{month.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel shrink size="small">
									Start Year
								</InputLabel>
								<Select
									label="Start Year"
									size="small"
									value={newProjectData.startYear}
									input={<OutlinedInput notched label="Start Year" />}
									onChange={(e) => {
										handleInputChange(e.target.value, 'startYear');
									}}
								>
									{getYears().map((year, index) => (
										<MenuItem key={index} value={year} size="small">
											{year}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<FormControl fullWidth>
								<InputLabel shrink size="small">
									End Month
								</InputLabel>
								<Select
									label="End Month"
									size="small"
									value={newProjectData.endMonth}
									input={<OutlinedInput notched label="End Month" />}
									onChange={(e) => {
										handleInputChange(e.target.value, 'endMonth');
									}}
								>
									{getMonths().map((month, index) => (
										<MenuItem key={index} value={month.value} size="small">
											{month.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel shrink size="small">
									End Year
								</InputLabel>
								<Select
									label="End Year"
									size="small"
									value={newProjectData.endYear}
									input={<OutlinedInput notched label="End Year" />}
									onChange={(e) => {
										handleInputChange(e.target.value, 'endYear');
									}}
								>
									{getYears().map((year, index) => (
										<MenuItem key={index} value={year} size="small">
											{year}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="grid grid-cols-1">
							<TextField
								size="small"
								label="URL"
								value={newProjectData.projectUrl}
								onChange={(e) => {
									handleInputChange(e.target.value, 'projectUrl');
								}}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													window.open(newProjectData.projectUrl, '_blank');
												}}
											>
												<DirectionsIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
						<div className="">
							<ReactQuill
								value={newProjectData.description}
								onChange={(value) => {
									handleInputChange(value, 'description');
								}}
								formats={getQuillSimpleFormats()}
								modules={getQuillSimpleModules()}
							/>
						</div>
						<div className="flex flex-row gap-x-4">
							{!!newProjectData.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteItem(newProjectData.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => {
									handleCancelItem(newProjectData.id);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit(() => handleSaveItem(newProjectData))}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!projectData.isEdit && (
				<React.Fragment>
					<span className={`${styles.date}`}>
						{startDate} - {endDate}
					</span>
					<span className={styles.url}>{projectData.projectUrl}</span>
					<div className="basis-4"></div>
					<span className={`${styles.description}`}>
						{trimHtml(projectData.description)}
					</span>
				</React.Fragment>
			)}
		</div>
	);
}
