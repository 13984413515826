import React, { useEffect, useMemo, useState } from 'react';
import styles from './Skill.module.scss';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { useResponsive, useRequest } from 'ahooks';
import { Grid } from '@mui/system';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import resumeApi from 'src/api/resume.api';
import useToast from 'src/components/Toast/useToast';
import { useLocation } from 'react-router-dom';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 600,
	width: `100%`,
	bgcolor: 'background.paper',
	boxShadow: 24,
	// p: 4,
};

const SkillDetails = ({ skills }) => {
	const [keyword, setKeyword] = useState('');

	const list = useMemo(() => {
		return keyword
			? _.filter(
					skills,
					(i) =>
						i.skillName?.toLowerCase()?.indexOf(keyword?.toLowerCase()) > -1
				)
			: skills;
	}, [skills, keyword]);

	return (
		<div className="overflow-y-auto bg-white rounded-md p-5 shadow-md max-h-[500px]">
			<div className="w-full flex flex-row justify-between mb-2 items-center">
				<div className="text-xl text-slate-700 font-bold mb-2">Skills</div>
				<TextField
					label="Search"
					placeholder="type to find..."
					size="small"
					onChange={(e) => setKeyword(e.target.value)}
				/>
			</div>
			{list?.length ? (
				list.map((skillData) => (
					<div
						key={skillData.id}
						className={`${styles.item} flex flex-row justify-between items-center`}
					>
						<div>
							<span className={`${styles.company}`}>{skillData.skillName}</span>
						</div>
						<Rating
							size="small"
							value={parseFloat(skillData.value)}
							precision={0.5}
							readOnly
							emptyIcon={
								<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
							}
						/>
					</div>
				))
			) : (
				<>No Data Found</>
			)}
		</div>
	);
};

const EditSkillDetails = ({ skills = [], updateSkills = () => {} }) => {
	const { register, handleSubmit, setValue, reset, getValues } = useForm({
		defaultValues: { skills }, // Initialize form with the list of objects
	});

	const [_skills, setSkills] = useState(skills);

	const onSubmit = (formData) => {
		// Handle batch update logic here
		updateSkills({
			skills: formData?.skills?.map((item) => ({
				skillName: item.skillName,
				checked: true,
				value: parseFloat(item.value),
			})),
		});
	};

	const addSkill = () => {
		const newSkills = [..._skills, { skillName: '', value: 0 }];
		setSkills(newSkills);
		reset({
			skills: newSkills,
		});
	};

	const deleteSkill = (index) => {
		const currentValues = getValues();
		// Filter out the skill to be deleted
		const updatedSkills = currentValues?.skills?.filter((_, i) => i !== index);
		setSkills(updatedSkills);
		// Preserve the existing values in the form state while resetting
		reset({
			skills: updatedSkills,
		});
	};

	return (
		<div className="bg-white rounded-md p-5 shadow-md">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full flex flex-row justify-between mb-2 items-center">
					<div className="text-xl text-slate-700 font-bold mb-2">Skills</div>
					<LoadingButton
						type="submit"
						variant="outlined"
						size="small"
						onClick={addSkill}
					>
						Add Skill
					</LoadingButton>
				</div>
				<div className="overflow-y-auto max-h-[500px] w-full pt-5 pr-5">
					{_skills.map((skill, objIndex) => (
						<div
							key={objIndex}
							className="gap-2 flex justify-between pt-4 items-center"
						>
							<TextField
								required
								fullWidth
								size="small"
								label={`Skill Name`}
								variant="outlined"
								{...register(`skills.${objIndex}.skillName`)}
								defaultValue={skill.skillName}
								onChange={(e) => {
									setValue(`skills.${objIndex}.skillName`, e.target.value);
								}}
							/>
							<Rating
								defaultValue={parseFloat(skill.value)}
								precision={0.5}
								onChange={(_, v) => {
									setValue(`skills.${objIndex}.value`, v?.toString() || 0);
								}}
							/>
							<IconButton
								// color="secondary"
								aria-label="delete"
								onClick={() => deleteSkill(objIndex)}
							>
								<DeleteForeverIcon />
							</IconButton>
						</div>
					))}
				</div>
				<div className="w-full flex flex-row mb-2 items-center justify-end pt-4">
					<LoadingButton type="submit" variant="contained">
						Save
					</LoadingButton>
				</div>
			</form>
		</div>
	);
};

export default function Skill({
	skills = [],
	primaryResume = {},
	getPrimaryResume = async () => {},
}) {
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [isEditStatus, setIsEditStatus] = useState(false);
	const handleOpen = () => setOpenDetailModal(true);
	const handleClose = () => setOpenDetailModal(false);
	const responsive = useResponsive();
	const { showSuccess, showError } = useToast();
	const location = useLocation();

	const { loading: loadingUpdateSkills, run: updateSkills } = useRequest(
		({ skills }) =>
			resumeApi.createSkills({ resumeId: primaryResume.id, skills }),
		{
			manual: true,
			onSuccess: async (res) => {
				showSuccess('Skills Updated');
				await getPrimaryResume();
				handleClose();
			},
			onError: () => {
				showError('Something went wrong!');
			},
		}
	);

	const skillsData = useMemo(() => {
		return skills ? skills.filter((skill) => skill.checked === true) : [];
	}, [skills]);

	const skillSummary = useMemo(() => {
		return responsive?.md ? _.take(skillsData, 20) : _.take(skillsData, 5);
	}, [skillsData, responsive]);

	return (
		<div className="overflow-y-auto bg-white rounded-md p-5 shadow-md max-h-[500px]">
			<Grid container justifyContent="space-between">
				<Grid>
					<div className="text-xl text-slate-700 font-bold mb-2">Skills</div>
				</Grid>
				<Grid>
					{Object.keys(primaryResume).length !== 0 ? (
						<IconButton
							color="primary"
							aria-label="edit-my-ideal-job"
							onClick={() => {
								setIsEditStatus(true);
								handleOpen();
							}}
							sx={{
								display: location.pathname.includes('public') ? 'none' : 'flex',
							}}
						>
							<EditOutlinedIcon />
						</IconButton>
					) : null}
				</Grid>
			</Grid>

			{skillSummary.length === 0 ? (
				<div className="h-36 mt-8 flex flex-col gap-y-8">
					<span className="text-sm text-slate-700">
						Oops! It looks like you haven't added any skills, please upload
						resume.
					</span>
				</div>
			) : (
				<div className="w-full pt-2 relative">
					<div className="w-full grid grid-cols-4 tablet:grid-cols-1 gap-y-1 gap-x-6">
						{skillSummary.map((skillData, _index) => (
							<Tooltip
								key={_index}
								title={`${skillData.skillName} : ${parseFloat(skillData.value)}`}
								followCursor={true}
							>
								<div
									className={`${styles.item} grid grid-cols-2 gap-3 border-b border-solid border-[#f9f9f9] p-1`}
									key={skillData.id}
								>
									<div className={'ellipsis'}>
										<span className={`${styles.company}`}>
											{skillData.skillName}
										</span>
									</div>

									<Rating
										size="small"
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
										value={parseFloat(skillData.value)}
										precision={0.5}
										readOnly
										emptyIcon={
											<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
										}
									/>
								</div>
							</Tooltip>
						))}
					</div>
					<Grid container direction="row-reverse">
						<Grid>
							<Button
								onClick={() => {
									setIsEditStatus(false);
									handleOpen();
								}}
								variant="text"
							>
								More
							</Button>
						</Grid>
					</Grid>
					<Modal
						open={openDetailModal}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={modalStyle}>
							{isEditStatus ? (
								<EditSkillDetails
									skills={skillsData}
									updateSkills={updateSkills}
								/>
							) : (
								<SkillDetails skills={skillsData} />
							)}
						</Box>
					</Modal>
				</div>
			)}
		</div>
	);
}
