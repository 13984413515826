import React, { useState, useEffect, useMemo } from 'react';
import styles from './Certification.module.scss';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import dayjs from 'dayjs';
import { getSimpleYears, getMonths } from '../../../libs/utils';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useToast from 'src/components/Toast/useToast';
import resumeApi from 'src/api/resume.api';
import { useForm, Controller } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputAdornment from '@mui/material/InputAdornment';
const classNames = require('classnames');

export default function Item({
	selectedResumeId,
	certificationData,
	handleCancelItem,
	handleEditItem,
	onSuccessSaved,
}) {
	const [newCertificationData, setNewCertificationData] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			certificationName: newCertificationData.certificationName,
			provider: newCertificationData.provider,
			issueYear: newCertificationData.issueYear,
			issueMonth: newCertificationData.issueMonth,
		},
	});

	const startDate = useMemo(() => {
		return dayjs(
			`${certificationData.issueYear}-${certificationData.issueMonth}`
		).format('MMM YYYY');
	}, [certificationData.issueYear, certificationData.issueMonth]);

	const expireDate = useMemo(() => {
		if (certificationData.noExpire) return 'No Expiration Date';
		return (
			'Expires ' +
			dayjs(
				`${certificationData.expireYear}-${certificationData.expireMonth}`
			).format('MMM YYYY')
		);
	}, [
		certificationData.noExpire,
		certificationData.expireYear,
		certificationData.expireMonth,
	]);

	const handleInputChange = (value, fieldName) => {
		newCertificationData[fieldName] = value;
		setNewCertificationData({ ...newCertificationData });
	};

	const handleDeleteItem = async (id) => {
		try {
			await resumeApi.deleteCertification(id);
			onSuccessSaved();
			showSuccess(
				'Your licenses & certifications has been deleted successfully!'
			);
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	const handleSaveItem = async (itemData) => {
		try {
			setIsSaving(true);
			if (itemData.id) {
				await resumeApi.updateCertification(itemData.id, { ...itemData });
			} else {
				await resumeApi.createCertification({
					resumeId: selectedResumeId,
					...itemData,
				});
			}
			onSuccessSaved();
			showSuccess(
				'Your licenses & certifications has been saved successfully!'
			);
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		if (newCertificationData?.noExpire) {
			handleInputChange(null, 'expireMonth');
			handleInputChange(null, 'expireYear');
		}
	}, [newCertificationData?.noExpire]);

	useEffect(() => {
		console.log("the data of 'Certification/Item' component is loaded!!!!!!");
		setNewCertificationData({ ...certificationData });
	}, [certificationData.isEdit]);

	return (
		<div
			className={classNames(
				'flex flex-col',
				styles.item,
				certificationData.isEdit ? styles.edit : styles.view
			)}
		>
			{!certificationData.isEdit && (
				<React.Fragment>
					<div className="flex flex-row justify-between items-center">
						<span className={`${styles.position}`}>
							{newCertificationData.certificationName}
						</span>
						<div className="basis-10">
							<IconButton
								color="primary"
								size="small"
								onClick={() => {
									handleEditItem(newCertificationData.id);
								}}
							>
								<BorderColorOutlinedIcon />
							</IconButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!!certificationData.isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-6 pb-4">
						<div className="grid grid-cols-1">
							<Controller
								name="certificationName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="certificationName"
										size="small"
										required
										value={newCertificationData.certificationName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'certificationName');
										}}
										label="Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-1">
							<Controller
								name="provider"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Issuing organization is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="provider"
										size="small"
										required
										value={newCertificationData.provider}
										onChange={(e) => {
											handleInputChange(e.target.value, 'provider');
										}}
										label="Issuing organization"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="issueMonth"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Issue Month is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Issue Month
										</InputLabel>
										<Select
											name="issueMonth"
											label="Issue Month"
											size="small"
											value={newCertificationData.issueMonth}
											input={
												<OutlinedInput required notched label="Issue Month" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'issueMonth');
											}}
											error={!!error}
										>
											{getMonths().map((month, index) => (
												<MenuItem key={index} value={month.value} size="small">
													{month.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
							<Controller
								name="issueYear"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Issue Year is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Issue Year
										</InputLabel>
										<Select
											name="issueYear"
											label="Issue Year"
											size="small"
											value={newCertificationData.issueYear}
											input={
												<OutlinedInput required notched label="Issue Year" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'issueYear');
											}}
											error={!!error}
										>
											{getSimpleYears().map((year, index) => (
												<MenuItem key={index} value={year} size="small">
													{year}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
						</div>
						{!newCertificationData?.noExpire && (
							<div className="grid grid-cols-2 gap-x-4">
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										Expire Month
									</InputLabel>
									<Select
										label="Expire Month"
										size="small"
										value={newCertificationData.expireMonth}
										input={<OutlinedInput notched label="Expire Month" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'expireMonth');
										}}
									>
										{getMonths().map((month, index) => (
											<MenuItem key={index} value={month.value} size="small">
												{month.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										Expire Year
									</InputLabel>
									<Select
										label="Expire Year"
										size="small"
										value={newCertificationData.expireYear}
										input={<OutlinedInput notched label="Expire Year" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'expireYear');
										}}
									>
										{getSimpleYears().map((year, index) => (
											<MenuItem key={index} value={year} size="small">
												{year}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						)}
						<div className="grid grid-cols-1">
							<div className="justify-self-start">
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												value={true}
												checked={newCertificationData.noExpire}
												onChange={(e) => {
													handleInputChange(e.target.checked, 'noExpire');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label="The credential does not expire"
									/>
								</FormGroup>
							</div>
						</div>
						<div className="grid grid-cols-1">
							<TextField
								size="small"
								label="Credential ID"
								value={newCertificationData.certificationId}
								onChange={(e) => {
									handleInputChange(e.target.value, 'certifcationId');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="grid grid-cols-1">
							<TextField
								size="small"
								label="Credential URL"
								value={newCertificationData.certificationUrl}
								onChange={(e) => {
									handleInputChange(e.target.value, 'certificationUrl');
								}}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													window.open(
														newCertificationData.certificationUrl,
														'_blank'
													);
												}}
											>
												<DirectionsIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
						<div className="flex flex-row gap-x-4">
							{!!newCertificationData.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteItem(newCertificationData.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => {
									handleCancelItem(newCertificationData.id);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit(() =>
									handleSaveItem(newCertificationData)
								)}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!certificationData.isEdit && (
				<React.Fragment>
					<span className={`${styles.company}`}>
						{certificationData.provider}
					</span>
					<span className={`${styles.date}`}>
						Issued {startDate} - {expireDate}
					</span>
				</React.Fragment>
			)}
		</div>
	);
}
