import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getHashParams } from '../../libs/utils';

function OauthResult() {
	const navigate = useNavigate();
	const token = getHashParams()['token'];
	localStorage.setItem('token', token);
	useEffect(() => {
		if (localStorage.getItem('prevPathname')) {
			navigate(
				localStorage.getItem('prevPathname') + localStorage.getItem('preSearch')
			);
		} else {
			navigate('/job-tracker');
		}
		console.log('componentDidMount');
		// eslint-disable-next-line
	}, []);

	return (
		<Box
			sx={{ display: 'flex' }}
			className="w-full flex justify-center min-h-screen items-center"
		>
			<CircularProgress />
		</Box>
	);
}

export default OauthResult;
