import useBackdrop from 'src/components/Backdrop/useBackdrop';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as dayjs from 'dayjs';
import Button from '@mui/material/Button';

export default function SubscribeConfirm() {
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [paymentData, setPaymentData] = useState({});
	const [isReady, setIsReady] = useState(false);
	const [userData, setUserData] = useState({});
	const [searchParams] = useSearchParams();
	const subscriptionId = searchParams.get('subscription_id');

	// const getPayment = async () => {
	// 	try {
	// 		showBackdrop();
	// 		const response = await paymentApi.getPayment(id);
	// 		const userResponse = await userApi.getCurrentWebUser();
	// 		setPaymentData(response.data);
	// 		setUserData(userResponse.data);
	// 	} catch (err) {
	// 		console.error(err);
	// 	} finally {
	// 		setIsReady(true);
	// 		closeBackdrop();
	// 	}
	// };

	// useEffect(() => {
	// 	getPayment();
	// }, []);

	return (
		<>
			<div className="flex flex-col items-center h-full">
				<div className="text-green-500" style={{ fontSize: '96px' }}>
					<CheckCircleOutlineIcon color="inherit" fontSize="inherit" />
				</div>
				<span className="text-slate-700 text-2xl font-bold">
					Subscription Proccessing
				</span>
				<div className="w-96 mt-4 text-slate-600">
					Thank you for subscribing Jobbly! your payment is under proccessing.
				</div>
				<div className="w-96 mt-8">
					<div className="border-b-2 border-gray-400 pb-4 text-slate-700 font-semibold">
						ORDER DETAILS
					</div>
					<div className="mt-4 grid grid-cols-[1fr_2fr] gap-x-8 gap-y-2">
						<span className="text-right text-slate-800 font-medium">
							Payment ID
						</span>
						<span className="text-slate-500 font-medium">{subscriptionId}</span>
					</div>
				</div>
				<div className="flex flex-row justify-end w-96 mt-8">
					<Button
						component={Link}
						to="/account"
						variant="contained"
						sx={{ textTransform: 'none' }}
					>
						<span className="font-bold text-white text-sm cursor-pointer">
							My Account
						</span>
					</Button>
				</div>
			</div>
			{/* {isReady && paymentData.status !== 'COMPLETED' && (
				<div className="flex flex-col items-center h-full">
					<div className="text-red-500" style={{ fontSize: '96px' }}>
						<HighlightOffIcon color="inherit" fontSize="inherit" />
					</div>
					<span className="text-slate-700 text-2xl font-bold">
						Payment Failed
					</span>
					<div className="w-96 mt-4 text-slate-600">
						Sorry, your order could not be completed because the payment for
						this transaction has failed. Please check your payment details. In
						casse of queries please contact us{' '}
						<span className="text-slate-800 font-semibold">
							helpdesk@jobbly.io
						</span>
					</div>
				</div>
			)} */}
			<BackdropComponent></BackdropComponent>
		</>
	);
}
