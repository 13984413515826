import React, { useEffect } from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import resumeApi from 'src/api/resume.api';

export default function PDFRenderer({ resume, closeDialog }) {
	const { id } = resume;
	// console.log(`resume`);
	const runGetResumePDF = async (id) => {
		try {
			const res = await resumeApi.getResumePdf(id);
			//Create a Blob from the PDF Stream
			const file = new Blob([res.data], { type: 'application/pdf' });
			const mockFileURL = URL.createObjectURL(file);
			window.open(mockFileURL);
			closeDialog();
		} catch (err) {
			console.log(`render PDF error:`);
			console.error(err);
		} finally {
		}
	};

	useEffect(() => {
		runGetResumePDF(id);
	}, []);

	return (
		<PDFViewerContainer>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		</PDFViewerContainer>
	);
}

const PDFViewerContainer = styled.div`
	width: 100%;
`;
