import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import resumeApi from '../../../api/resume.api';
import formStore from '../../../stores/form.store';
import {
	trimHtml,
	getQuillSimpleFormats,
	getQuillSimpleModules,
} from 'src/libs/utils';
import { useForm, Controller } from 'react-hook-form';
import alertStore from 'src/stores/alert.store';
import useToast from 'src/components/Toast/useToast';
import styles from './Summary.module.scss';

export default function Summary({ className, summaryData, onSuccessSaved }) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newSummaryData, setNewSummaryData] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			description: newSummaryData.description,
		},
	});

	const handleInputChange = (value, fieldName) => {
		console.log('value', value);
		console.log('fieldName', fieldName);
		newSummaryData[fieldName] = value;
		setNewSummaryData({ ...newSummaryData });
	};

	const wordCounter = (value) => {
		return value ? trimHtml(value).length : 0;
	};

	const saveNewSummaryData = async () => {
		try {
			setIsSaving(true);
			const { id, description } = newSummaryData;
			if (id) {
				await resumeApi.updateSummary(id, { description });
			} else {
				console.log('description', description);
				await resumeApi.createSummary({
					resumeId: selectedResumeId,
					description,
				});
			}
			onSuccessSaved();
			setIsEdit(false);
			alertStore.setState({
				alertOpen: true,
				alertMessage:
					'Your professional summaries has been saved successfully!',
				alertSeverity: 'success',
			});
		} catch (err) {
			console.error(err);
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Something went wrong!',
				alertSeverity: 'error',
			});
		} finally {
			setIsSaving(false);
		}
	};

	const handleDeleteItem = async (id) => {
		try {
			await resumeApi.deleteSummary(id);
			onSuccessSaved();
			setIsEdit(false);
			showSuccess('Your professional summary has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	useEffect(() => {
		console.log("the data of 'Summary' component is loaded!!!!!!");
		setNewSummaryData({ ...summaryData });
	}, [summaryData]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm hover:bg-zinc-200`}
		>
			<div className="grid grid-cols-[1fr_60px] h-12">
				<div className="text-2xl font-semibold text-slate-700">
					Professional Summaries
				</div>
				{!isEdit && (
					<div className="">
						{newSummaryData.id ? (
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								color="primary"
								onClick={() => setIsEdit(true)}
								startIcon={<BorderColorOutlinedIcon />}
							>
								Edit
							</Button>
						) : (
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								color="primary"
								onClick={() => setIsEdit(true)}
								startIcon={<AddIcon />}
							>
								Add
							</Button>
						)}
					</div>
				)}
			</div>
			{!isEdit && !!newSummaryData?.id && (
				<React.Fragment>
					<div className="text-slate-600 font-medium text-sm">
						<div
							className={`${styles.summary}`}
							dangerouslySetInnerHTML={{ __html: newSummaryData.description }}
						/>
					</div>
				</React.Fragment>
			)}
			{!!isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-4">
						<div>
							<Controller
								name="description"
								control={control}
								rules={{
									validate: (value) =>
										wordCounter(value) > 0 || 'Description is required',
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<ReactQuill
										value={newSummaryData.description || ''}
										formats={getQuillSimpleFormats()}
										modules={getQuillSimpleModules()}
										onChange={(value) => {
											handleInputChange(value, 'description');
										}}
									/>
								)}
							/>
						</div>
						<div className="flex flex-row justify-end gap-x-4 items-center">
							{!!newSummaryData.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteItem(newSummaryData.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<span className="text-xs text-red-700">
								{formErrors?.description?.message}
							</span>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => {
									setIsEdit(false);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit(() => saveNewSummaryData())}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}
