import { create } from 'zustand';

export default create((set) => ({
	userId: null,
	displayName: null,
	primaryResumeId: null,
	credits: null,
	isVip: null,
	vipExpiredAt: null,
	setUserId: () => set((state) => ({ ...state, userId: state.userId })),
	setDisplayName: () =>
		set((state) => ({ ...state, userId: state.displayName })),
	setPrimaryResumeId: () =>
		set((state) => ({ ...state, primaryResumeId: state.primaryResume })),
	setCredits: () => set((state) => ({ ...state, credits: state.credits })),
}));
