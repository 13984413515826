import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './DefaultLayout.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import userApi from '../../api/user.api';
import userAuthStore from 'src/stores/auth.store';
import alertStore from 'src/stores/alert.store';
import useToast from 'src/components/Toast/useToast';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ResumeImage from '../../assets/resume.png';
import CloseIcon from '@mui/icons-material/Close';
const classNames = require('classnames');

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '4px',
	p: 4,
};

export default function DefaultLayout() {
	const [ready, setReady] = useState(false);
	const alertOpen = alertStore((state) => state.alertOpen);
	const { ToastComponent } = useToast();
	const location = useLocation();
	const [openModal, setOpenModal] = useState(false);
	const navigate = useNavigate();
	const [imageReady, setImageReady] = useState(false);

	const fetchUserProfile = async () => {
		// set user profile
		try {
			const response = await userApi.getCurrentWebUser();
			// console.log(`response`)
			// console.log(response.data)
			userAuthStore.setState({
				primaryResumeId: response.data.primaryResumeId,
				displayName: response.data.displayName,
				userId: response.data.id,
				isVip: response.data.isVip,
				vipExpiredAt: response.data.vipExpiredAt,
			});
			// userAuthStore.setState({ displayName: response.data.displayName });
			// userAuthStore.setState({ userId: response.data.id });
			// userAuthStore.setState({
			// 	primaryResumeId: response.data.primaryResumeId,
			// });
			// if (!response.data.primaryResumeId) {
			// 	setTimeout(() => {
			// 		setOpenModal(true);
			// 	}, 800);
			// }
		} catch (err) {
		} finally {
			setReady(true);
		}
	};

	useEffect(() => {
		console.log('Location changed!', location.pathname);
		localStorage.setItem('prevPathname', location.pathname);
		localStorage.setItem('preSearch', location.search);
	}, [location]);

	useEffect(() => {
		fetchUserProfile();
		console.log('env', process.env.REACT_APP_ENV);
	}, []);

	const goToResumeBuilder = () => {
		navigate('/resume-builder');
		setOpenModal(false);
	};

	return (
		<>
			{ready ? (
				<React.Fragment>
					<div className="flex flex-col h-full relative">
						<Navbar></Navbar>
						<div className="default-container grow shrink basis-auto">
							<Outlet />
						</div>
						<div
							className="sidemenu"
							style={{ color: '#1976d2', fontSize: '40px' }}
							onClick={() =>
								window.open('https://forms.gle/tjmihuXFVYC45JAe7', '_blank')
							}
						>
							<Tooltip title="Contact Support" placement="left">
								<HelpOutlineIcon fontSize="inherit" color="inherit" />
							</Tooltip>
						</div>
					</div>
					<Modal
						open={openModal}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
						closeAfterTransition
						disableEnforceFocus
						disableEscapeKeyDown
						disableAutoFocus
					>
						<Fade in={openModal}>
							<Box
								sx={style}
								className={classNames(
									'w-[650px] 3xl:w-[720px] 2k:w-[860px] 4k:w-[1080px] relative',
									!imageReady && 'hidden'
								)}
							>
								<div
									style={{ top: '10px', right: '10px' }}
									className="absolute cursor-pointer text-slate-700 text-xl font-bold hover:bg-slate-100 rounded-full h-8 w-8 flex justify-center items-center"
									onClick={() => setOpenModal(false)}
								>
									<CloseIcon fontSize="inherit" color="inherit"></CloseIcon>
								</div>
								<div className="flex flex-row gap-x-6">
									<img
										alt="resume-image"
										onLoad={() => setImageReady(true)}
										src={ResumeImage}
										className="h-52 3xl:h-64 2k:h-72 4k:h-80"
									/>
									<div className="w-full grow my-auto">
										<div className="text-lg text-slate-500 font-bold leading-tight">
											You have not filled out your resume yet. Would you like to
											explore more features?{' '}
										</div>
										<div className="mt-5 text-base text-slate-500 font-medium">
											Create your resume now!
										</div>
										<Button
											variant="contained"
											sx={{ marginTop: '8px', textTransform: 'none' }}
											onClick={goToResumeBuilder}
										>
											<span className="font-bold text-white text-sm cursor-pointer">
												Try it now
											</span>
										</Button>
									</div>
								</div>
							</Box>
						</Fade>
					</Modal>
				</React.Fragment>
			) : (
				<Box
					sx={{ display: 'flex' }}
					className="w-full flex justify-center min-h-screen items-center"
				>
					<CircularProgress />
				</Box>
			)}
			{alertOpen && <ToastComponent></ToastComponent>}
		</>
	);
}
