import ApiFactory from 'src/api/ApiFactory';
import dayjs from 'dayjs';

const api = {
	updateLangs(id, languages) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			languages,
		};
		return apiInstance.put(`/user/languages`, data);
	},
};
export default api;
