import React, { useState, useEffect, useMemo } from 'react';
import styles from './Education.module.scss';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
	trimHtml,
	getStartDate,
	getEndDate,
	getYears,
	getMonths,
	getQuillSimpleFormats,
	getQuillSimpleModules,
} from '../../../libs/utils';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useToast from 'src/components/Toast/useToast';
import resumeApi from 'src/api/resume.api';
import { useForm, Controller } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
const classNames = require('classnames');

export default function Item({
	selectedResumeId,
	educationData,
	handleCancelItem,
	handleEditItem,
	onSuccessSaved,
}) {
	const [newEducationData, setNewEducationData] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			schoolName: newEducationData.schoolName,
			degree: newEducationData.degree,
			startYear: newEducationData.startYear,
			startMonth: newEducationData.startMonth,
		},
	});

	const startDate = useMemo(() => {
		return getStartDate(educationData.startYear, educationData.startMonth);
	}, [educationData.startYear, educationData.startMonth]);

	const endDate = useMemo(() => {
		return getEndDate(
			educationData.isCurrent,
			educationData.endYear,
			educationData.endMonth
		);
	}, [educationData.isCurrent, educationData.endYear, educationData.endMonth]);

	const handleInputChange = (value, fieldName) => {
		newEducationData[fieldName] = value;
		setNewEducationData({ ...newEducationData });
	};

	const handleDeleteItem = async (id) => {
		try {
			await resumeApi.deleteEducation(id);
			onSuccessSaved();
			showSuccess('Your education has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	const handleSaveItem = async (itemData) => {
		try {
			setIsSaving(true);
			if (itemData.id) {
				await resumeApi.updateEducation(itemData.id, { ...itemData });
			} else {
				await resumeApi.createEducation({
					resumeId: selectedResumeId,
					...itemData,
				});
			}
			onSuccessSaved();
			showSuccess('Your education has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		if (newEducationData?.isCurrent) {
			handleInputChange(null, 'endMonth');
			handleInputChange(null, 'endYear');
		}
	}, [newEducationData?.isCurrent]);

	useEffect(() => {
		console.log("the data of 'Education/Item' component is loaded!!!!!!");
		setNewEducationData({ ...educationData });
	}, [educationData.isEdit]);

	return (
		<div
			className={classNames(
				'flex flex-col',
				styles.item,
				educationData.isEdit ? styles.edit : styles.view
			)}
		>
			{!educationData.isEdit && (
				<React.Fragment>
					<div className="flex flex-row justify-between items-center">
						<span className={`${styles.position}`}>
							{newEducationData.schoolName}
						</span>
						<div className="basis-10">
							<IconButton
								color="primary"
								size="small"
								onClick={() => {
									handleEditItem(newEducationData.id);
								}}
							>
								<BorderColorOutlinedIcon />
							</IconButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!!educationData.isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-6 pb-4">
						<div className="grid grid-cols-1">
							<Controller
								name="schoolName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'School or University is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="schoolName"
										size="small"
										required
										value={newEducationData.schoolName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'schoolName');
										}}
										label="School or University"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="degree"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Degree is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Degree
										</InputLabel>
										<Select
											name="degree"
											label="Degree"
											size="small"
											value={newEducationData.degree}
											input={<OutlinedInput required notched label="Degree" />}
											onChange={(e) => {
												handleInputChange(e.target.value, 'degree');
											}}
											error={!!error}
										>
											<MenuItem value={'GED'} size="small">
												GED
											</MenuItem>
											<MenuItem value={'High School'} size="small">
												High School
											</MenuItem>
											<MenuItem value={'Associations'} size="small">
												Associations
											</MenuItem>
											<MenuItem value={'Bachelors'} size="small">
												Bachelors
											</MenuItem>
											<MenuItem value={'Masters'} size="small">
												Masters
											</MenuItem>
											<MenuItem value={'Doctorate'} size="small">
												Doctorate
											</MenuItem>
										</Select>
									</FormControl>
								)}
							/>
							<TextField
								size="small"
								label="Field of Study"
								value={newEducationData.studyField}
								onChange={(e) => {
									handleInputChange(e.target.value, 'studyField');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="grid grid-cols-1">
							<div className="justify-self-start">
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												value={true}
												checked={newEducationData.isCurrent}
												onChange={(e) => {
													handleInputChange(e.target.checked, 'isCurrent');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label="I am currently studying in this school"
									/>
								</FormGroup>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="startMonth"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Start Month is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Start Month
										</InputLabel>
										<Select
											name="startMonth"
											label="Start Month"
											size="small"
											value={newEducationData.startMonth}
											input={
												<OutlinedInput required notched label="Start Month" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'startMonth');
											}}
											error={!!error}
										>
											{getMonths().map((month, index) => (
												<MenuItem key={index} value={month.value} size="small">
													{month.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
							<Controller
								name="startYear"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Start Year is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Start Year
										</InputLabel>
										<Select
											name="startYear"
											label="Start Year"
											size="small"
											value={newEducationData.startYear}
											input={
												<OutlinedInput required notched label="Start Year" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'startYear');
											}}
											error={!!error}
										>
											{getYears().map((year, index) => (
												<MenuItem key={index} value={year} size="small">
													{year}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
						</div>
						{!newEducationData?.isCurrent && (
							<div className="grid grid-cols-2 gap-x-4">
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										End Month
									</InputLabel>
									<Select
										label="End Month"
										size="small"
										value={newEducationData.endMonth}
										input={<OutlinedInput notched label="End Month" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'endMonth');
										}}
									>
										{getMonths().map((month, index) => (
											<MenuItem key={index} value={month.value} size="small">
												{month.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										End Year
									</InputLabel>
									<Select
										label="End Year"
										size="small"
										value={newEducationData.endYear}
										input={<OutlinedInput notched label="End Year" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'endYear');
										}}
									>
										{getYears().map((year, index) => (
											<MenuItem key={index} value={year} size="small">
												{year}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						)}
						<div className="grid grid-cols-2">
							<TextField
								size="small"
								label="GPA"
								value={newEducationData.gpa}
								onChange={(e) => {
									handleInputChange(e.target.value, 'gpa');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="">
							<ReactQuill
								value={newEducationData.description}
								onChange={(value) => {
									handleInputChange(value, 'description');
								}}
								formats={getQuillSimpleFormats()}
								modules={getQuillSimpleModules()}
							/>
						</div>
						<div className="flex flex-row gap-x-4">
							{!!newEducationData.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteItem(newEducationData.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => {
									handleCancelItem(newEducationData.id);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit(() => handleSaveItem(newEducationData))}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!educationData.isEdit && (
				<React.Fragment>
					<span className={`${styles.company}`}>
						{educationData.degree}, {educationData.studyField}
					</span>
					<span className={`${styles.date}`}>
						{startDate} - {endDate}
					</span>
					<div className="basis-4"></div>
					<span className={`${styles.description}`}>
						{trimHtml(educationData.description)}
					</span>
				</React.Fragment>
			)}
		</div>
	);
}
