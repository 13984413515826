import styles from './NoPage.module.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function NoPage() {
	const navigate = useNavigate();

	return (
		<div className="m-0 p-0 w-full h-full flex justify-center items-center">
			<div className={styles.section}>
				<h1 className={styles.error}>404</h1>
				<div className={styles.page}>
					Ooops!!! The page you are looking for is not found
				</div>
				<button className={styles.back_home} onClick={() => navigate('/')}>
					Back to home
				</button>
			</div>
		</div>
	);
}

export default NoPage;
