import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Box, Paper, Typography, Chip, alpha } from '@mui/material';
import { TagCloud } from 'react-tagcloud';
import jobAPi from 'src/api/job.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';

dayjs.extend(utc);

const jobTitleChip = (tag, size, color) => {
	return (
		<Chip
			label={tag.value}
			key={tag.value}
			sx={{
				fontSize: `${size}px`,
				height: `${size + 10}px`,
				borderRadius: `${size + 5}px`,
				m: 0.3,
				backgroundColor: alpha(color, 0.7),
			}}
		/>
	);
};

const sigmoid = (x) => {
	return Math.exp(x) / (Math.exp(x) + 1);
};

export default function TitleTracking() {
	const [alignment, setAlignment] = useState(90);
	const [titleList, setTitleList] = useState([]);

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment);
		}
	};

	const titleData = useMemo(() => {
		let wordList = [];
		titleList.forEach((item) => {
			for (let i = 0; i < item.cnt; i++) {
				wordList = wordList.concat(item.title.match(/[A-Za-z0-9]+/g));
			}
		});
		const wordCountObj = R.countBy(R.toLower)(wordList);
		const wordCountList = [];
		for (const [key, value] of Object.entries(wordCountObj)) {
			wordCountList.push({
				value: key,
				count: sigmoid(value),
			});
		}
		// console.log('wordCountList', wordCountList);
		return wordCountList;
	}, [titleList]);

	const colorOptions = {
		luminosity: 'light',
		hue: 'blue',
	};

	useEffect(() => {
		(async () => {
			const response = await jobAPi.getTitleTracking({
				startDate: dayjs().subtract(alignment, 'd').format('YYYY-MM-DD'),
				endDate: dayjs().format('YYYY-MM-DD'),
			});
			setTitleList(response.data);
		})();
	}, [alignment]);

	return (
		<Paper
			sx={{
				p: 2,
				minHeight: '300px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box sx={{ flex: '0 0 auto' }}>
				<Typography variant="h6" gutterBottom>
					Saved Job Titles
				</Typography>
			</Box>
			{!!titleList?.length && (
				<TagCloud
					minSize={12}
					maxSize={36}
					tags={titleData}
					className="mt-2"
					colorOptions={colorOptions}
					renderer={jobTitleChip}
				/>
			)}
		</Paper>
	);
}
