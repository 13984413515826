import IconButton from '@mui/material/IconButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import styles from './UploadDialog.module.scss';

const UploadResume = ({
	selectedfile = [],
	DeleteSelectFile = () => {},
	InputChange = () => {},
}) => {
	return (
		<div className="h-80 debug-borderblue flex flex-col">
			<div className="text-base font-semibold text-slate-500">
				Upload and parse your resume file
			</div>
			<div className="basis-10"></div>
			<div className="grow flex flex-row justify-center items-center">
				<div className="relative">
					<IconButton>
						<CloudUploadOutlinedIcon style={{ fontSize: 160 }} />
					</IconButton>
					<input type="file" className={styles.file} onChange={InputChange} />
				</div>
			</div>
			<div className="basis-16">
				{selectedfile.map((data, index) => {
					const { id, filename, datetime } = data;
					return (
						<div className="file-atc-box" key={id}>
							<div className="flex flex-col items-start gap-y-1">
								<span className="text-xs text-slate-700 font-bold pl-0.5">
									{filename}
								</span>
								<span className="text-xs text-slate-700 pl-0.5">
									Modified Time : {datetime}
								</span>
								<button
									type="button"
									className="text-xs text-slate-500 border-b border-slate-500 hover:text-slate-900 hover:border-slate-900 px-1 pb-0.5"
									onClick={() => DeleteSelectFile(id)}
								>
									Delete
								</button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default UploadResume;
