import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Button from '@mui/material/Button';
import LoopIcon from '@mui/icons-material/Loop';
import jobApi from 'src/api/job.api';
import Pagination from '@mui/material/Pagination';
import formStore from 'src/stores/form.store';
import useToast from 'src/components/Toast/useToast';

export default function Top({
	checkedList,
	handleCheckChange,
	isAllChecked,
	handlePageChange,
	pageCount,
	page,
	listJob,
}) {
	const { showSuccess, showError } = useToast();

	const batchUpdateStatus = async (e, status) => {
		try {
			await jobApi.batchUpdateJob(checkedList, { status });
			listJob();
			showSuccess('Your job status has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	return (
		<div className="flex flex-row items-center justify-start">
			<div className="flex flex-row pl-6 items-center">
				<Checkbox
					value="all"
					onChange={handleCheckChange}
					checked={isAllChecked}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
				<Stack
					direction="row"
					spacing={0}
					sx={{ display: checkedList.length === 0 ? 'none' : 'block' }}
				>
					<Tooltip title="Saved">
						<IconButton
							size="small"
							disabled={checkedList.length === 0}
							onClick={(e) => batchUpdateStatus(e, 'APPLY')}
						>
							<BookmarksOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Interviewing">
						<IconButton
							disabled={checkedList.length === 0}
							onClick={(e) => batchUpdateStatus(e, 'INTERVIEW')}
						>
							<PermContactCalendarOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Job Offered">
						<IconButton
							disabled={checkedList.length === 0}
							onClick={(e) => batchUpdateStatus(e, 'OFFER')}
						>
							<MilitaryTechOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Archived">
						<IconButton
							disabled={checkedList.length === 0}
							onClick={(e) => batchUpdateStatus(e, 'ARCHIVE')}
						>
							<ArchiveOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Stack>
				<Tooltip title="Refresh">
					<IconButton color="primary" onClick={(e) => listJob()}>
						<LoopIcon fontSize="small" />
					</IconButton>
				</Tooltip>
				<Button
					size="small"
					variant="outlined"
					sx={{ textTransform: 'none', marginLeft: '8px' }}
					onClick={() =>
						formStore.setState({ selectedJobId: null, jobMode: 'detail' })
					}
				>
					Add job
				</Button>
			</div>
			<div className="grow"></div>
			<Pagination
				count={pageCount}
				page={page}
				siblingCount={0}
				boundaryCount={1}
				onChange={handlePageChange}
				size="small"
			/>
		</div>
	);
}
