export const mainClass = {
	numberCard: {
		p: 2,
		minHeight: '150px',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
};
