import ApiFactory from 'src/api/ApiFactory';

const api = {
	listResume({
		query,
		or,
		field,
		sortby,
		order,
		limit,
		offset,
		contain,
		keyword,
		like,
	}) {
		const apiInstance = ApiFactory.create();
		const params = {
			query,
			or,
			field,
			sortby,
			order,
			limit,
			offset,
			contain,
			keyword,
			like,
		};
		return apiInstance.get(`/resume`, { params });
	},
	getResume(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/resume/${id}`);
	},
	updateResume(id, { resumeName, labels, skills, isPrimary }) {
		const apiInstance = ApiFactory.create();
		const data = { id, resumeName, labels, skills, isPrimary };
		return apiInstance.put(`/resume`, data);
	},
	initResume({ userId }) {
		const apiInstance = ApiFactory.create();
		const data = { userId };
		return apiInstance.post(`/resume/init`, data);
	},
	updateProfile(
		id,
		{ firstName, lastName, urls, email, phone, address, city, state, zipCode }
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			firstName,
			lastName,
			urls,
			email,
			phone,
			address,
			city,
			state,
			zipCode,
		};
		return apiInstance.put(`/resume/profile`, data);
	},
	createProfile({
		resumeId,
		firstName,
		lastName,
		urls,
		email,
		phone,
		address,
		city,
		state,
		zipCode,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			resumeId,
			firstName,
			lastName,
			urls,
			email,
			phone,
			address,
			city,
			state,
			zipCode,
		};
		return apiInstance.post(`/resume/profile`, data);
	},
	createSummary({ resumeId, description }) {
		const apiInstance = ApiFactory.create();
		const data = { resumeId, description };
		return apiInstance.post(`/resume/summary`, data);
	},
	updateSummary(id, { description }) {
		const apiInstance = ApiFactory.create();
		const data = { id, description };
		return apiInstance.put(`/resume/summary`, data);
	},
	deleteSummary(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/summary/${id}`);
	},
	createExperience({
		resumeId,
		position,
		companyName,
		location,
		jobType,
		startYear,
		startMonth,
		endYear,
		endMonth,
		isCurrent,
		description,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			resumeId,
			position,
			companyName,
			location,
			jobType,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			description,
		};
		return apiInstance.post(`/resume/experience`, data);
	},
	updateExperience(
		id,
		{
			position,
			companyName,
			location,
			jobType,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			description,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			position,
			companyName,
			location,
			jobType,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			description,
		};
		return apiInstance.put(`/resume/experience`, data);
	},
	deleteExperience(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/experience/${id}`);
	},
	createEducation({
		resumeId,
		schoolName,
		degree,
		studyField,
		startYear,
		startMonth,
		endYear,
		endMonth,
		isCurrent,
		gpa,
		description,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			resumeId,
			schoolName,
			degree,
			studyField,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			gpa,
			description,
		};
		return apiInstance.post(`/resume/education`, data);
	},
	updateEducation(
		id,
		{
			schoolName,
			degree,
			studyField,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			gpa,
			description,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			schoolName,
			degree,
			studyField,
			startYear,
			startMonth,
			endYear,
			endMonth,
			isCurrent,
			gpa,
			description,
		};
		return apiInstance.put(`/resume/education`, data);
	},
	deleteEducation(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/education/${id}`);
	},
	createProject({
		resumeId,
		projectName,
		startYear,
		startMonth,
		endYear,
		endMonth,
		projectUrl,
		description,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			resumeId,
			projectName,
			startYear,
			startMonth,
			endYear,
			endMonth,
			projectUrl,
			description,
		};
		return apiInstance.post(`/resume/project`, data);
	},
	updateProject(
		id,
		{
			projectName,
			startYear,
			startMonth,
			endYear,
			endMonth,
			projectUrl,
			description,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			projectName,
			startYear,
			startMonth,
			endYear,
			endMonth,
			projectUrl,
			description,
		};
		return apiInstance.put(`/resume/project`, data);
	},
	deleteProject(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/project/${id}`);
	},
	createCertification({
		resumeId,
		certificationName,
		provider,
		issueYear,
		issueMonth,
		expireYear,
		expireMonth,
		noExpire,
		certificationId,
		certificationUrl,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			resumeId,
			certificationName,
			provider,
			issueYear,
			issueMonth,
			expireYear,
			expireMonth,
			noExpire,
			certificationId,
			certificationUrl,
		};
		return apiInstance.post(`/resume/certification`, data);
	},
	updateCertification(
		id,
		{
			certificationName,
			provider,
			issueYear,
			issueMonth,
			expireYear,
			expireMonth,
			noExpire,
			certificationId,
			certificationUrl,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			certificationName,
			provider,
			issueYear,
			issueMonth,
			expireYear,
			expireMonth,
			noExpire,
			certificationId,
			certificationUrl,
		};
		return apiInstance.put(`/resume/certification`, data);
	},
	deleteCertification(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/certification/${id}`);
	},
	deleteResume(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/resume/${id}`);
	},
	duplicateResume({ resumeId, jobId }) {
		const apiInstance = ApiFactory.create();
		const data = { resumeId, jobId };
		return apiInstance.post(`/resume/duplicate`, data);
	},
	uploadResume({ jobId = '', resumeFile, skip, isPrimary }) {
		const apiInstance = ApiFactory.create();
		const formData = new FormData();
		formData.append('jobId', jobId);
		if (resumeFile) formData.append('resume', resumeFile);
		if (isPrimary) formData.append('isPrimary', isPrimary);
		if (skip) formData.append('skip', skip);
		return apiInstance.post(`/resume/upload`, formData);
	},
	createSkills({ resumeId, skills }) {
		const apiInstance = ApiFactory.create();
		const data = { resumeId, skills };
		return apiInstance.post(`/resume/skill`, data);
	},
	batchDeleteResume(ids) {
		const apiInstance = ApiFactory.create();
		const data = { ids };
		console.log(data);
		return apiInstance.delete(`/resume/batch`, { data });
	},
	getResumePdf(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/resume/${id}/pdf`, {
			responseType: 'blob',
		});
	},
};
export default api;
