import React, { useEffect, useState, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Prompts from './components/Prompts';
import promptApi from 'src/api/prompt.api';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
const classNames = require('classnames');

export default function Prompt() {
	const [expanded, setExpanded] = useState(0);
	const [promptsOpen, setPromptsOpen] = useState(false);
	const [prompts, setPrompts] = useState([]);
	const randomPrompts = useMemo(() => {
		return [...prompts].sort((a, b) => 0.5 - Math.random()).slice(0, 3);
	}, [prompts]);

	const handleChange = (panel) => (event, isExpanded) => {
		// setExpanded(isExpanded ? panel : false);
		setExpanded(panel);
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await promptApi.listUserPrompt({
					sortby: 'createdAt',
					order: 'desc',
					limit: 0,
					include: 'prompt',
				});
				setPrompts(response.data.rows);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);

	return (
		<>
			{prompts?.length ? (
				<div className="flex w-full h-full max-h-[500px">
					<div className="flex flex-col h-full justify-between bg-white shadow-md p-5 rounded-md">
						<div>
							<div className="text-xl text-slate-700 font-bold mb-2">
								Prompts
							</div>
							{randomPrompts.map((item, index) => (
								<Accordion
									key={item.id}
									expanded={expanded === index}
									onChange={handleChange(index)}
								>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<span className="text-slate-500 text-sm line-clamp-3">
											{item?.prompt?.promptName}
										</span>
									</AccordionSummary>
									<AccordionDetails>
										<div className="break-all text-slate-600 font-medium text-sm overflow-y-auto line-clamp-4">
											<div dangerouslySetInnerHTML={{ __html: item.answer }} />
										</div>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
						<div className="mt-2 flex flex-row justify-between">
							<div
								className={classNames(
									window.location.pathname.indexOf('public') >= 0 && 'hidden'
								)}
							>
								<Button
									component={Link}
									to="/account#prompt-section"
									variant="contained"
									sx={{ textTransform: 'none' }}
								>
									<span className="font-bold text-white text-sm cursor-pointer">
										Edit prompts
									</span>
								</Button>
							</div>
							<button
								type="button"
								onClick={() => setPromptsOpen(true)}
								className="text-xs text-blue-500 border-b border-blue-500 hover:text-blue-900 hover:border-blue-900 px-1 pb-0.5"
							>
								More detail
							</button>
						</div>
						<Prompts
							open={promptsOpen}
							onClose={() => {
								setPromptsOpen(false);
							}}
							list={prompts}
						></Prompts>
					</div>
				</div>
			) : (
				<React.Fragment>
					<div className="overflow-y-auto bg-white rounded-md p-5 shadow-md h-full max-h-[440px]">
						<div className="text-xl text-slate-700 font-bold mb-2">Prompts</div>
						<div className="h-56 mt-8 flex flex-col gap-y-8">
							<span className="text-sm text-slate-700">
								Did you know that sharing more about yourself can increase your
								chances of making professional connections? Head to your account
								page and add a prompt to let others know more about you
							</span>
							<div className="flex flex-row justify-center">
								<Button
									component={Link}
									to="/account#prompt-section"
									variant="contained"
									sx={{ textTransform: 'none' }}
								>
									<span className="font-bold text-white text-sm cursor-pointer">
										Add prompts
									</span>
								</Button>
							</div>
						</div>
					</div>
				</React.Fragment>
			)}
		</>
	);
}
