import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			laptop: 1440,
			desktop: 1920,
			twoK: 2560,
		},
	},
});

export default theme;
