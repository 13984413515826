import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './Navbar.scss';
import Logo from '../../assets/logo.svg';
import userAuthStore from '../../stores/auth.store';
import Chip from '@mui/material/Chip';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { useMemo } from 'react';

const pageList = [
	{
		name: 'Job Tracker',
		path: '/job-tracker',
	},
	// {
	// 	name: 'Resume Builder',
	// 	path: '/resume-builder',
	// },
];

function ResponsiveAppBar() {
	const navigate = useNavigate();
	const [, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const displayName = userAuthStore((state) => state.displayName);
	const credits = userAuthStore((state) => state.credits);
	const isVip = userAuthStore((state) => state.isVip);

	const userChipLabel = useMemo(
		() => (isVip ? 'Plus' : credits),
		[credits, isVip]
	);

	const [showCreditNotification, setshowCreditNotification] =
		React.useState(true);
	const [extensionInstalled, setExtensionInstalled] = React.useState(false);

	const handleCreditBarClick = () => {
		if (showCreditNotification) setshowCreditNotification(false);
		else setshowCreditNotification(true);
	};

	const handleCreditBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setshowCreditNotification(false);
	};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logout = () => {
		setAnchorElUser(null);
		localStorage.removeItem('token');
		navigate('/sign-in');
	};

	// const checkExtensionInstallation = (event) => {
	//   if (event.data && event.data.type === 'CHECK_EXTENSION_INSTALLATION') {
	//     console.log('Extension is installed.');
	//     setExtensionInstalled(true);
	//   }
	//   else{
	//     setExtensionInstalled(false);
	//   }
	// }

	window.addEventListener('message', async function (event) {
		// 確保來源是你信任的網頁
		if (event.source !== window) return;

		// 確保消息是你期望的類型
		if (event.data && event.data.type === 'CHECK_EXTENSION_INSTALLATION') {
			// 在此處處理接收到的消息
			//console.log('Extension is installed.');
			setExtensionInstalled(true);
		} else {
			setExtensionInstalled(false);
		}
	});

	return (
		<div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
			{!extensionInstalled && (
				<Box bgcolor="#faca2c" color="#3b3009" p={2} textAlign="center">
					Add Jobbly extension to your Chrome before starting the
					journey!&nbsp;&nbsp;&nbsp;
					<Button
						variant="contained"
						style={{ backgroundColor: '#1e1e1e', borderRadius: 35 }}
						href="https://chromewebstore.google.com/detail/jobbly/ojodppphhhpngpfnhkfcpgglolfpinoi"
						target="_blank"
					>
						Add to Chrome
					</Button>
				</Box>
			)}

			<AppBar position="static">
				<Container maxWidth="false">
					<Toolbar disableGutters>
						{/* <img alt="jobbly-logo" className="h-8" src={Logo} /> */}
						<Button
							onClick={() => {
								window.open(`https://jobbly.io/`, '_blank');
							}}
						>
							<img alt="jobbly-logo" className="h-8" src={Logo} />
						</Button>
						<Button
							component={Link}
							to="/"
							sx={{
								my: 2,
								color: 'white',
								display: 'block',
								textTransform: 'none',
							}}
						>
							<div className="flex flex-row items-end gap-x-1">
								<span className="font-bold text-white text-2xl cursor-pointer">
									Jobbly
								</span>
								<span className="text-xs text-gray-400">beta</span>
							</div>
						</Button>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{pageList.map((page) => (
								<Button
									component={Link}
									to={page.path}
									key={page.path}
									onClick={handleCloseNavMenu}
									sx={{
										my: 2,
										color: 'white',
										display: 'block',
										textTransform: 'none',
									}}
								>
									<span className="text-lg">{page.name}</span>
								</Button>
							))}
						</Box>

						<div
							className="grow"
							sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
						></div>
						<div className="flex flex-row items-start">
							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Open settings">
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar
											alt={displayName}
											src="/static/images/avatar/2.jpg"
										/>
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: '45px' }}
									id="menu-appbar"
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									<MenuItem
										onClick={handleCloseUserMenu}
										component={Link}
										to="/account"
									>
										<Typography textAlign="center">Account</Typography>
									</MenuItem>
									<MenuItem onClick={logout}>
										<Typography textAlign="center">Logout</Typography>
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											window.open(
												`https://chromewebstore.google.com/detail/jobbly/ojodppphhhpngpfnhkfcpgglolfpinoi`,
												'_blank'
											);
										}}
									>
										<Typography textAlign="center">
											Download Extension
										</Typography>
									</MenuItem>
								</Menu>
							</Box>
							{!!credits && (
								<Tooltip title="Available Credit" placement="bottom">
									<Chip
										size="small"
										icon={<AutoFixHighIcon sx={{ width: '0.6rem' }} />}
										label={userChipLabel}
										color="secondary"
										sx={{ fontSize: '0.6rem', height: 1, mt: -0.8, mr: 0.3 }}
									/>
								</Tooltip>
							)}
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
}
export default ResponsiveAppBar;
