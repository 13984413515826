import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useForm, Controller } from 'react-hook-form';

const Item = forwardRef(function Item(
	{ skillData, index, totalLength, onInputChange, onAddChange, onDeleteChange },
	ref
) {
	const inputRef = useRef();
	const [, setValue] = React.useState(0);
	const [, setHover] = React.useState(-1);
	const [newSkillData, setNewSkillData] = useState({});

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			skillName: newSkillData.skillName,
		},
	});

	const handleInputChange = (newValue, fieldName) => {
		newSkillData[fieldName] = newValue;
		setNewSkillData({ ...newSkillData });
		onInputChange(newSkillData, index);
	};

	useImperativeHandle(
		ref,
		function () {
			return {
				check() {
					inputRef.current.click();
				},
				pass() {
					return formErrors;
				},
			};
		},
		[]
	);

	useEffect(() => {
		setNewSkillData({ ...skillData });
	}, [skillData]);

	return (
		<>
			<div className="flex flex-row items-center gap-x-1">
				<Checkbox
					value={true}
					inputProps={{ 'aria-label': 'controlled' }}
					checked={skillData?.checked}
					onChange={(e) => {
						handleInputChange(e.target.checked, 'checked');
					}}
				/>
				<Controller
					name="skillName"
					control={control}
					rules={{
						required: {
							value: true,
							message: 'Skill Name is required',
						},
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							name="skillName"
							size="small"
							required
							value={newSkillData.skillName}
							onChange={(e) => {
								handleInputChange(e.target.value, 'skillName');
							}}
							label="Skill Name"
							variant="outlined"
							error={!!formErrors.skillName}
							// helperText={error?.message}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				/>
				{/* <TextField size="small" label="Skill Name" required
          InputLabelProps={{ shrink: true }}
          value={skillData?.skillName}
          onChange={(e) => { handleInputChange(e.target.value, 'skillName') }}
          fullWidth sx={{ flexShrink: 1, maxWidth: '50%' }}
        /> */}
				<div className="basis-2"></div>
				<div>
					<Rating
						name="hover-feedback"
						value={parseFloat(skillData.value)}
						precision={0.5}
						onChange={(event, newValue) => {
							let adjustValue = newValue;
							if (!adjustValue) {
								adjustValue = 0;
							}
							handleInputChange(adjustValue, 'value');
							setValue(adjustValue);
						}}
						onChangeActive={(event, newHover) => {
							setHover(newHover);
						}}
						emptyIcon={
							<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
						}
					/>
				</div>
				<div className="grow"></div>
				{
					<IconButton
						color="primary"
						size="small"
						sx={{ flexBasis: '40px' }}
						onClick={() => onDeleteChange(index)}
					>
						<DeleteForeverIcon />
					</IconButton>
				}
				{/* {
          index === totalLength - 1 ?
            <IconButton color="primary" size="small"
              sx={{ flexBasis: '40px' }}
              onClick={() => onAddChange({ checked: true, value: 0, reference: React.createRef() })}>
              <AddIcon />
            </IconButton> :
            <div className="basis-10"></div>
        } */}
				<button
					style={{ display: 'none' }}
					ref={inputRef}
					onClick={handleSubmit((data) => console.log(data))}
				>
					check
				</button>
			</div>
		</>
	);
});

export default Item;
