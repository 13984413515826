import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function useBackdrop() {
	const [open, setOpen] = useState(false);

	const closeBackdrop = () => {
		setOpen(false);
	};
	const showBackdrop = () => {
		setOpen(true);
	};

	const BackdropComponent = () => {
		return (
			<>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={open}
				>
					<div className="flex flex-col items-center gap-y-4">
						<CircularProgress color="inherit" />
						<span>Loading Data...</span>
					</div>
				</Backdrop>
			</>
		);
	};

	return [BackdropComponent, showBackdrop, closeBackdrop];
}
