import userAuthStore from '../../../../../stores/auth.store';
import formStore from '../../../../../stores/form.store';
import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import TextField from '@mui/material/TextField';
import jobApi from '../../../../../api/job.api';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useToast from 'src/components/Toast/useToast';
const classNames = require('classnames');

export default function Contact({ setUpdatedAt }) {
	const userId = userAuthStore((state) => state.userId);
	const selectedJobId = formStore((state) => state.selectedJobId);
	const [contacts, setContacts] = useState([]);
	const [action, setAction] = useState('list'); // view, edit, new, list
	const [selectedContact, setSelectedContact] = useState({});
	const [selectedId, setSelectedId] = useState();
	const titleRef = useRef();
	const nameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			title: selectedContact.title,
			name: selectedContact.name,
		},
	});

	const handleTitleChange = (event) => {
		const value = event.target.value;
		titleRef.current = value;
		setSelectedContact((prevState) => ({ ...prevState, title: value }));
	};
	const handleNameChange = (event) => {
		const value = event.target.value;
		nameRef.current = value;
		setSelectedContact((prevState) => ({ ...prevState, name: value }));
	};
	const handlePhoneChange = (event) => {
		const value = event.target.value;
		phoneRef.current = value;
		setSelectedContact((prevState) => ({ ...prevState, phone: value }));
	};
	const handleEmailChange = (event) => {
		const value = event.target.value;
		emailRef.current = value;
		setSelectedContact((prevState) => ({ ...prevState, email: value }));
	};

	const getJobData = async () => {
		const response = await jobApi.getJob(selectedJobId);
		setUpdatedAt(response.data.updatedAt);
	};

	const listContact = async () => {
		try {
			showBackdrop();
			const response = await jobApi.listContact({
				sortby: 'updatedAt',
				order: 'desc',
				limit: 0,
				field: 'id,name,title,updatedAt',
				query: `jobId:${selectedJobId}`,
			});
			setContacts(response.data.rows);
			getJobData();
		} catch (err) {
		} finally {
			closeBackdrop();
		}
	};

	const handleDeleteContact = async (id) => {
		try {
			await jobApi.deleteContact(id);
			setAction('list');
			setSelectedId();
			setSelectedContact({
				name: null,
				title: null,
				email: null,
				phone: null,
			});
			listContact();
			showSuccess('Your contact has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError();
		} finally {
		}
	};

	const handleSaveContact = async () => {
		try {
			setIsSaving(true);
			if (selectedContact.id) {
				await jobApi.updateContact({ ...selectedContact });
			} else if (!selectedContact.id) {
				const response = await jobApi.createContact({
					userId,
					jobId: selectedJobId,
					name: selectedContact.name,
					title: selectedContact.title,
					email: selectedContact.email,
					phone: selectedContact.phone,
				});
				setSelectedId(response.data.id);
			}
			setAction('view');
			listContact();
			showSuccess('Your contact has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError();
		} finally {
			setIsSaving(false);
		}
	};

	const getClassname = (contactId) => {
		return classNames(
			selectedContact.id === contactId ? 'bg-blue-100' : 'bg-gray-100',
			'rounded-md',
			'shadow-sm',
			'px-3',
			'py-4',
			'flex',
			'flex-row',
			'justify-between',
			'items-center',
			'cursor-pointer'
		);
	};

	useEffect(() => {
		if (action === 'new') {
			setSelectedId();
			setSelectedContact({
				name: null,
				title: null,
				email: null,
				phone: null,
			});
			nameRef.current = '';
			titleRef.current = '';
			emailRef.current = '';
			phoneRef.current = '';
		}
	}, [action]);

	useEffect(() => {
		const getContactData = async () => {
			console.log('selectedId change', selectedId);
			if (selectedId) {
				const response = await jobApi.getContact(selectedId);
				setSelectedContact(response.data);
				setAction('view');
				titleRef.current = response.data.title;
				nameRef.current = response.data.name;
				emailRef.current = response.data.email;
				phoneRef.current = response.data.phone;
			}
		};
		getContactData();
	}, [selectedId]);

	useEffect(() => {
		console.log('Contact componentDidMount');
		listContact();
	}, []);

	return (
		<div className="flex flex-row gap-x-8">
			<div style={{ width: '280px' }}>
				<div className="grid grid-cols-1 gap-y-1">
					{contacts.map((contact) => (
						<div
							key={contact.id}
							className={getClassname(contact.id)}
							onClick={() => setSelectedId(contact.id)}
						>
							<div className="text-slate-700 font-bold text-sm grow ellipsis">
								<span>{contact.name}</span>
							</div>
							<div className="text-slate-500 text-xs">{contact.title}</div>
						</div>
					))}
					<Button
						size="medium"
						variant="outlined"
						sx={{ textTransform: 'none', width: '280px' }}
						onClick={() => setAction('new')}
					>
						Add contact
					</Button>
				</div>
			</div>
			<div className="grow flex flex-col gap-y-1">
				{!!selectedContact.id && (
					<div className="bg-gray-100 rounded-t-md px-3 py-2 flex flex-row items-center">
						<IconButton
							color="primary"
							size="small"
							onClick={() => {
								setAction('edit');
							}}
						>
							<BorderColorOutlinedIcon />
						</IconButton>
						<div className="grow ellipsis ml-2">
							<span className="text-slate-700 text-sm font-bold">
								{selectedContact.name}
							</span>
						</div>
					</div>
				)}
				{action === 'view' && (
					<div className="bg-gray-100 px-3 py-4 text-slate-500 text-sm ">
						<div className="grid grid-cols-[80px_1fr]">
							<span className="leading-6 text-slate-400 font-bold text-sm">
								Name:
							</span>
							<span className="text-sm text-slate-700 leading-6">
								{selectedContact.name}
							</span>
							<span className="leading-6 text-slate-400 font-bold text-sm">
								Title:
							</span>
							<span className="text-sm text-slate-700 leading-6">
								{selectedContact.title}
							</span>
							<span className="leading-6 text-slate-400 font-bold text-sm">
								Email:
							</span>
							<span className="text-sm text-slate-700 leading-6">
								{selectedContact.email}
							</span>
							<span className="leading-6 text-slate-400 font-bold text-sm">
								Phone:
							</span>
							<span className="text-sm text-slate-700 leading-6">
								{selectedContact.phone}
							</span>
						</div>
						<div className="hidden flex-row items-center mt-4">
							<FormControl style={{ minWidth: 220 }}>
								<InputLabel id="demo-action-select-label" size="small">
									Action
								</InputLabel>
								<Select
									labelId="demo-action-select-label"
									label="Action"
									size="small"
									sx={{ borderRadius: '24px' }}
								>
									<MenuItem value={'action1'} size="small">
										Say Hi
									</MenuItem>
									<MenuItem value={'action2'} size="small">
										Email
									</MenuItem>
									<MenuItem value={'action3'} size="small">
										Phone call
									</MenuItem>
								</Select>
							</FormControl>
							<IconButton color="default" size="small">
								<SendIcon />
							</IconButton>
						</div>
					</div>
				)}
				{(action === 'edit' || action === 'new') && (
					<React.Fragment>
						<div className="bg-white text-slate-500 text-sm px-4 py-3">
							<div className="grid grid-cols-1 items-center gap-y-4">
								<Controller
									name="name"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Name is required',
										},
									}}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<TextField
											name="name"
											size="small"
											required
											value={nameRef.current}
											onChange={handleNameChange}
											label="Name"
											variant="outlined"
											error={!!error}
											// helperText={error?.message}
											InputLabelProps={{ shrink: true }}
										/>
									)}
								/>
								<Controller
									name="title"
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Title is required',
										},
									}}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<TextField
											name="title"
											size="small"
											required
											value={titleRef.current}
											onChange={handleTitleChange}
											label="Title"
											variant="outlined"
											error={!!error}
											// helperText={error?.message}
											InputLabelProps={{ shrink: true }}
										/>
									)}
								/>
								<TextField
									label="Email"
									fullWidth
									value={emailRef.current}
									onChange={handleEmailChange}
									size="small"
									InputLabelProps={{ shrink: true }}
								/>
								<TextField
									label="Phone"
									fullWidth
									value={phoneRef.current}
									onChange={handlePhoneChange}
									size="small"
									InputLabelProps={{ shrink: true }}
								/>
								<div className="flex flex-row justify-between">
									{!!selectedContact.id && (
										<Button
											sx={{
												'&:hover': { backgroundColor: '#d1d6d4' },
												textTransform: 'none',
												backgroundColor: '#e2e6e4',
												color: '#0e0e0f',
											}}
											variant="contained"
											size="small"
											onClick={() => {
												handleDeleteContact(selectedContact.id);
											}}
										>
											Delete
										</Button>
									)}
									<div className="grow"></div>
									<LoadingButton
										sx={{ textTransform: 'none' }}
										size="small"
										onClick={handleSubmit((data) => handleSaveContact(data))}
										endIcon={<SaveIcon />}
										loading={isSaving}
										loadingPosition="end"
										variant="contained"
										disabled={isSaving || !!Object.keys(formErrors).length}
									>
										<span>Save</span>
									</LoadingButton>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
			<BackdropComponent></BackdropComponent>
		</div>
	);
}
