import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import formStore from '../../../stores/form.store';
import Item from './Item';

export default function Project({ className, projectsData, onSuccessSaved }) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newProjectsData, setNewProjectsData] = useState([]);
	const action = useMemo(() => {
		const onlyView = newProjectsData.every((item) => {
			if (item.id === undefined) return false;
			return true;
		});
		return onlyView ? 'view' : 'add';
	}, [newProjectsData]);

	const handleAddItem = () => {
		let originList = newProjectsData.map((item) => {
			item.isEdit = false;
			return item;
		});
		setNewProjectsData([{ isEdit: true }, ...originList]);
	};

	const handleCancelItem = (id) => {
		console.log('handleCancelItem', id);
		if (!id) {
			let newList = [...newProjectsData];
			newList.splice(
				newList.findIndex((e) => e.id === id),
				1
			);
			setNewProjectsData(newList);
			return;
		}
		let newList = newProjectsData.map((item) => {
			if (item.id === id) {
				item.isEdit = false;
			}
			return item;
		});
		setNewProjectsData(newList);
	};
	const handleEditItem = (id) => {
		console.log('handleEditItem', id);
		let newList = newProjectsData
			.filter((item) => {
				return !!item.id;
			})
			.map((item) => {
				if (item.id === id) {
					item.isEdit = true;
				} else {
					item.isEdit = false;
				}
				return item;
			});
		setNewProjectsData(newList);
	};

	useEffect(() => {
		console.log("the data of 'Project' component is loaded!!!!!!");
		const newProjectsData = projectsData
			? projectsData.map((item) => {
					return { ...item, isEdit: false };
				})
			: [];
		setNewProjectsData(newProjectsData);
	}, [projectsData]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm`}
		>
			<div className="grid grid-cols-[1fr_60px] h-12">
				<div className="text-2xl font-semibold text-slate-700 self-center">
					Project
				</div>
				<div className="">
					{action === 'view' && (
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							color="primary"
							onClick={handleAddItem}
							startIcon={<AddIcon />}
						>
							Add
						</Button>
					)}
				</div>
			</div>
			<div className="grid grid-cols-1 gap-y-4">
				{!!newProjectsData &&
					newProjectsData.map((newProject) => (
						<Item
							key={newProject.id}
							selectedResumeId={selectedResumeId}
							projectData={newProject}
							onSuccessSaved={onSuccessSaved}
							handleCancelItem={handleCancelItem}
							handleEditItem={handleEditItem}
						></Item>
					))}
			</div>
		</div>
	);
}
