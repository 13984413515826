import { create } from 'zustand';

export default create((set) => ({
	selectedJobId: null,
	setSelectedJobId: () =>
		set((state) => ({ ...state, selectedJobId: state.selectedJobId })),
	jobMode: 'list', // list | detail
	setJobMode: () => set((state) => ({ ...state, jobMode: state.jobMode })),
	selectedResumeId: null,
	setSelectedResumeId: () =>
		set((state) => ({ ...state, selectedResumeId: state.selectedResumeId })),
	resumeMode: 'list', // list | detail
	setResumeMode: () =>
		set((state) => ({ ...state, resumeMode: state.resumeMode })),
}));
