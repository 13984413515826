import ApiFactory from 'src/api/ApiFactory';
import dayjs from 'dayjs';

const api = {
	async addCertification(data) {
		const apiInstance = ApiFactory.create();
		const res = await apiInstance.post(`/resume/certification`, data);
		return res;
	},

	async editCertification(data) {
		const apiInstance = ApiFactory.create();
		const res = await apiInstance.put(`/resume/certification`, data);
		return res;
	},

	async deleteCertification(id = '') {
		const apiInstance = ApiFactory.create();
		const res = await apiInstance.delete(`/resume/certification/${id}`);
		return res;
	},
};

export default api;
