import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import formStore from '../../../stores/form.store';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import styles from './Profile.module.scss';
import resumeApi from '../../../api/resume.api';
import alertStore from 'src/stores/alert.store';
import { useForm, Controller } from 'react-hook-form';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';

const WebUrls = ({ urls, onInputChange, control }) => {
	const handleUrlInputChange = (index, newValue) => {
		const newUrls = [...urls];
		newUrls[index] = newValue;
		onInputChange(newUrls);
	};
	const handleAddClick = () => {
		const newUrls = [...urls];
		newUrls.push('');
		onInputChange(newUrls);
	};
	const handleDeleteClick = (index) => {
		console.log('handleDeleteClick', index);
		const newUrls = [...urls];
		newUrls.splice(index, 1);
		onInputChange(newUrls);
	};

	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="flex-end"
				alignItems="flex-start"
			>
				<Button
					style={{ marginTop: 10 }}
					variant="outlined"
					size="small"
					onClick={handleAddClick}
				>
					Add Link
				</Button>
			</Grid>

			{urls.map((url, index) => (
				<div key={index} className="flex flex-row justify-between">
					<Controller
						name={`urls.${index}`}
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Link is required',
							},
							validate: {
								checkUrl: (v) => {
									try {
										new URL(v);
										return true;
									} catch (err) {
										return false;
									}
								},
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								size="small"
								label="Url (LinkedIn, Social Media, Website)"
								value={url}
								required
								key={index}
								error={!!error}
								InputLabelProps={{ shrink: true }}
								sx={{ width: '85%' }}
								onChange={(e) => {
									handleUrlInputChange(index, e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													window.open(url, '_blank');
												}}
											>
												<DirectionsIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>

					<div className="grow"></div>
					<IconButton
						color="primary"
						size="small"
						sx={{ flexBasis: '40px' }}
						onClick={() => {
							handleDeleteClick(index);
						}}
					>
						<DeleteForeverIcon />
					</IconButton>
					{/* {
            index > 0 &&
            <IconButton color="primary" size="small" sx={{ flexBasis: '40px' }} onClick={() => { handleDeleteClick(index) }}>
              <DeleteForeverIcon />
            </IconButton>
          } */}
					{/* {
            index === urls.length - 1 ?
              <IconButton color="primary" size="small" sx={{ flexBasis: '40px' }} onClick={handleAddClick}>
                <AddIcon />
              </IconButton>
              :
              <div className="basis-10"></div>
          } */}
				</div>
			))}
		</>
	);
};

export default function Profile({ className, profileData, onSuccessSaved }) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newProfileData, setNewProfileData] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [webUrlList, setWebUrlList] = useState(['']);

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			firstName: newProfileData.firstName,
			lastName: newProfileData.lastName,
			phone: newProfileData.phone,
			email: newProfileData.email,
			urls: webUrlList,
		},
	});

	const handleWebUrlChange = (newUrls) => {
		setWebUrlList(newUrls);
	};

	const handleInputChange = (event, fieldName) => {
		const value = event.target.value;
		newProfileData[fieldName] = value;
		setNewProfileData({ ...newProfileData });
	};

	const saveNewProfileData = async () => {
		try {
			setIsSaving(true);
			const {
				id,
				firstName,
				lastName,
				email,
				phone,
				address,
				city,
				state,
				zipCode,
			} = newProfileData;
			if (id) {
				await resumeApi.updateProfile(id, {
					firstName,
					lastName,
					email,
					phone,
					urls: webUrlList,
					address,
					city,
					state,
					zipCode,
				});
			} else {
				await resumeApi.createProfile({
					resumeId: selectedResumeId,
					firstName,
					lastName,
					email,
					phone,
					urls: webUrlList,
					address,
					city,
					state,
					zipCode,
				});
			}
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Your contact informatin has been saved successfully!',
				alertSeverity: 'success',
			});
			onSuccessSaved();
			setIsEdit(false);
		} catch (err) {
			console.error(err);
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Something went wrong!',
				alertSeverity: 'error',
			});
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		console.log("the data of 'Profile' component is loaded!!!!!!");
		if (!isEdit) return;

		setNewProfileData({ ...profileData });
		if (profileData?.urls) setWebUrlList(profileData.urls);
	}, [profileData, isEdit]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm hover:bg-zinc-200`}
		>
			<div className="grid grid-cols-[1fr_60px] h-12">
				<div className="text-2xl font-semibold text-slate-700">
					Contact Information
				</div>
				{!isEdit && (
					<div>
						{profileData?.id ? (
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								color="primary"
								onClick={() => setIsEdit(true)}
								startIcon={<BorderColorOutlinedIcon />}
							>
								Edit
							</Button>
						) : (
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								color="primary"
								onClick={() => setIsEdit(true)}
								startIcon={<AddIcon />}
							>
								Add
							</Button>
						)}
					</div>
				)}
			</div>
			{!isEdit && !!profileData?.id && (
				<React.Fragment>
					<div className="flex flex-col gap-y-1">
						<div className="grid grid-cols-[120px_1fr_120px_1fr]">
							<span className={styles.label}>First Name:</span>
							<span className={styles.value}>{profileData.firstName}</span>
							<span className={`${styles.label}`}>Last Name:</span>
							<span className={styles.value}>{profileData.lastName}</span>
						</div>
						<div className="grid grid-cols-[120px_1fr]">
							<span className={styles.label}>Address:</span>
							<span className={styles.value}>{profileData.address}</span>
						</div>
						<div className="grid grid-cols-[120px_1fr_120px_1fr]">
							<span className={styles.label}>City:</span>
							<span className={styles.value}>{profileData.city}</span>
							<span className={styles.label}>State:</span>
							<span className={styles.value}>{profileData.state}</span>
						</div>
						<div className="grid grid-cols-[120px_1fr]">
							<span className={styles.label}>Postal Code:</span>
							<span className={styles.value}>{profileData.zipCode}</span>
						</div>
						<div className="grid grid-cols-[120px_1fr_120px_1fr]">
							<span className={styles.label}>Email Address:</span>
							<span className={`${styles.value} break-all block w-5/6`}>
								{profileData.email}
							</span>
							<span className={`${styles.label}`}>Phone:</span>
							<span className={styles.value}>{profileData.phone}</span>
						</div>
						<div className="grid grid-cols-[120px_1fr]">
							<span className={styles.label}>URL:</span>
							<div className="flex flex-col gapy-y-2">
								{!!profileData.urls &&
									profileData.urls.map((url, index) => (
										<span
											key={index}
											className={styles.url}
											onClick={() => {
												window.open(url, '_blank');
											}}
										>
											{url}
										</span>
									))}
							</div>
						</div>
					</div>
				</React.Fragment>
			)}
			{!!isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-6">
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="firstName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'First Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="firstname"
										size="small"
										required
										value={newProfileData.firstName}
										onChange={(e) => {
											handleInputChange(e, 'firstName');
										}}
										label="First Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
							<Controller
								name="lastName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Last Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="lastName"
										size="small"
										required
										value={newProfileData.lastName}
										onChange={(e) => {
											handleInputChange(e, 'lastName');
										}}
										label="Last Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-1">
							<TextField
								size="small"
								label="Address"
								value={newProfileData.address}
								onChange={(e) => {
									handleInputChange(e, 'address');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<TextField
								size="small"
								label="City"
								value={newProfileData.city}
								onChange={(e) => {
									handleInputChange(e, 'city');
								}}
								InputLabelProps={{ shrink: true }}
							/>
							<TextField
								size="small"
								label="State"
								value={newProfileData.state}
								onChange={(e) => {
									handleInputChange(e, 'state');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<TextField
								size="small"
								label="Postal Code"
								value={newProfileData.zipCode}
								onChange={(e) => {
									handleInputChange(e, 'zipCode');
								}}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="email"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Email Address is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="email"
										size="small"
										required
										value={newProfileData.email}
										onChange={(e) => {
											handleInputChange(e, 'email');
										}}
										label="Email Address"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
							<Controller
								name="phone"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Phone is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="phone"
										size="small"
										required
										value={newProfileData.phone}
										onChange={(e) => {
											handleInputChange(e, 'phone');
										}}
										label="Phone"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-1 gap-y-6">
							<WebUrls
								urls={webUrlList}
								onInputChange={handleWebUrlChange}
								control={control}
							></WebUrls>
						</div>
					</div>
					<div className="flex flex-row justify-end mt-4 gap-x-4">
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							size="small"
							onClick={() => {
								setIsEdit(false);
							}}
						>
							Cancel
						</Button>
						<LoadingButton
							sx={{ textTransform: 'none' }}
							size="small"
							onClick={handleSubmit((data) => saveNewProfileData(data))}
							endIcon={<SaveIcon />}
							loading={isSaving}
							loadingPosition="end"
							variant="contained"
							disabled={isSaving || !!Object.keys(formErrors).length}
						>
							<span>Save</span>
						</LoadingButton>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}
