import React, { useState, useEffect, useMemo } from 'react';
import './Sidebar.scss';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import Popover from '@mui/material/Popover';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import labelApi from 'src/api/label.api';
import CircularProgress from '@mui/material/CircularProgress';

function Sidebar({ listJob, handleFilterChange }) {
	// FIXME: merge as object ??
	const [status, setStatus] = useState('APPLY');
	const [keyword, setKeyword] = useState();
	const [title, setTitle] = useState();
	const [company, setCompany] = useState();
	const [description, setDescription] = useState();
	const [selectedLabelList, setSelectedLabelList] = useState([]);

	const [filterAnchor, setFilterAnchor] = useState(null);
	const filterOpen = Boolean(filterAnchor);
	const id = filterOpen ? 'simple-popover' : undefined;
	const [options, setOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const hasFilter = useMemo(() => {
		if (title) return true;
		if (company) return true;
		if (description) return true;
		if (selectedLabelList.length) return true;
	}, [title, company, description, selectedLabelList.length]);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			listJob();
		}
	};

	const handleFilterClose = () => {
		setFilterAnchor(null);
		listJob();
	};

	const clearKeyword = () => {
		console.log('clearKeyword() invoke..');
		handleFilterChange('', 'keyword');
		setKeyword('');
		listJob();
	};

	const clearFilter = async (e) => {
		console.log('clearFilter() invoke..');
		handleFilterChange('', 'keyword');
		handleFilterChange('', 'title');
		handleFilterChange('', 'company');
		handleFilterChange('', 'description');
		handleFilterChange([], 'selectedLabelList');
		setKeyword('');
		setTitle('');
		setCompany('');
		setDescription('');
		setSelectedLabelList([]);
		listJob();
	};

	const handleFilterClick = (event) => {
		setFilterAnchor(document.getElementById('keyword'));
	};

	useEffect(() => {
		if (loading) {
			(async () => {
				const response = await labelApi.listJobLabel({
					sortby: 'name',
					order: 'asc',
					limit: 0,
					field: 'id,name',
				});
				setOptions(response.data.rows);
				setLoading(false);
			})();
		}
	}, [loading]);

	useEffect(() => {
		if (open) {
			setLoading(true);
		} else {
			setOptions([]);
		}
	}, [open]);

	return (
		<div className="w-full">
			<Stack spacing={1}>
				<div id="keyword">
					<TextField
						size="small"
						sx={{
							// fieldset: { borderColor: "#1976d2" },
							'& .MuiOutlinedInput-root': {
								border: '1px solid rgba(25, 118, 210, 0.5)',
								':hover': {
									border: '1px solid #1976d2 !important',
								},
							},
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none',
							},
						}}
						value={keyword}
						// sx={{border: '2px solid red', borderRadius: '5px'}}
						onChange={(e) => {
							handleFilterChange(e.target.value, 'keyword');
							setKeyword(e.target.value);
						}}
						onKeyDown={handleKeyDown}
						placeholder="search"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<IconButton
									size="small"
									position="start"
									className="cursor-pointer"
									color="primary"
								>
									<SearchIcon />
								</IconButton>
							),
							endAdornment: (
								<>
									<IconButton
										size="small"
										className="cursor-pointer"
										onClick={clearKeyword}
										style={{ display: keyword ? 'block' : 'none' }}
									>
										<ClearIcon />
									</IconButton>
									<IconButton
										size="small"
										position="end"
										onClick={handleFilterClick}
										sx={[
											hasFilter && {
												color: '#EC4899',
												backgroundColor: '#FBCFE8',
												'&:hover': {
													color: '#EC4899',
													backgroundColor: '#FBCFE8',
												},
											},
										]}
									>
										<TuneSharpIcon />
									</IconButton>
								</>
							),
						}}
					/>
				</div>
				<Popover
					id={id}
					open={filterOpen}
					anchorEl={filterAnchor}
					onClose={handleFilterClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					PaperProps={{
						style: { width: '340px' },
						className: 'px-6 pt-4 pb-4',
					}}
				>
					<div className="grid grid-cols-1 gap-y-4">
						<TextField
							size="small"
							label="Include the words"
							value={keyword}
							onChange={(e) => {
								setKeyword(e.target.value);
								handleFilterChange(e.target.value, 'keyword');
							}}
						/>
						<TextField
							size="small"
							label="Title"
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
								handleFilterChange(e.target.value, 'title');
							}}
						/>
						<TextField
							size="small"
							label="Company"
							value={company}
							onChange={(e) => {
								setCompany(e.target.value);
								handleFilterChange(e.target.value, 'company');
							}}
						/>
						<TextField
							size="small"
							label="Description"
							value={description}
							onChange={(e) => {
								setDescription(e.target.value);
								handleFilterChange(e.target.value, 'description');
							}}
						/>
						<Autocomplete
							multiple
							sx={{ minWidth: 240 }}
							size="small"
							value={selectedLabelList}
							onChange={(event, newValue) => {
								setSelectedLabelList(newValue);
								handleFilterChange(newValue, 'selectedLabelList');
							}}
							loading={loading}
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							options={options}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) =>
								option.name === value.name
							}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									label="Key Skills"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
						<div className="flex flex-row justify-end gap-x-4">
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={clearFilter}
							>
								Clear filter
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={listJob}
								endIcon={<SearchIcon />}
								loadingPosition="end"
								variant="contained"
							>
								<span>Search</span>
							</LoadingButton>
						</div>
					</div>
				</Popover>
				<Button
					sx={{ textTransform: 'none' }}
					className="w-full"
					size="medium"
					variant={status === 'APPLY' ? 'contained' : 'outlined'}
					onClick={() => {
						setStatus('APPLY');
						handleFilterChange('APPLY', 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<BookmarksOutlinedIcon
							style={{ color: status === 'APPLY' ? 'white' : '#1976d2' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">Saved</span>
					</div>
				</Button>
				<Button
					sx={{ textTransform: 'none' }}
					className="w-full"
					size="medium"
					variant={status === 'REQUEST' ? 'contained' : 'outlined'}
					onClick={() => {
						setStatus('REQUEST');
						handleFilterChange('REQUEST', 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<MarkEmailReadOutlinedIcon
							style={{ color: status === 'REQUEST' ? 'white' : '#1976d2' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">Applied</span>
					</div>
				</Button>
				<Button
					sx={{ textTransform: 'none' }}
					size="medium"
					className="w-full"
					variant={status === 'INTERVIEW' ? 'contained' : 'outlined'}
					onClick={() => {
						setStatus('INTERVIEW');
						handleFilterChange('INTERVIEW', 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<PermContactCalendarOutlinedIcon
							style={{ color: status === 'INTERVIEW' ? 'white' : '#1976d2' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">Interviewing</span>
					</div>
				</Button>
				<Button
					sx={{ textTransform: 'none' }}
					size="medium"
					className="w-full"
					variant={status === 'OFFER' ? 'contained' : 'outlined'}
					onClick={() => {
						setStatus('OFFER');
						handleFilterChange('OFFER', 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<MilitaryTechOutlinedIcon
							style={{ color: status === 'OFFER' ? 'white' : '#1976d2' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">Job Offered</span>
					</div>
				</Button>
				<Button
					sx={{ textTransform: 'none' }}
					size="medium"
					className="w-full"
					variant={status === 'ARCHIVE' ? 'contained' : 'outlined'}
					onClick={() => {
						setStatus('ARCHIVE');
						handleFilterChange('ARCHIVE', 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<ArchiveOutlinedIcon
							style={{ color: status === 'ARCHIVE' ? 'white' : '#1976d2' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">Archived</span>
					</div>
				</Button>
				<Button
					sx={{ textTransform: 'none' }}
					size="medium"
					className="w-full"
					variant={status ? 'outlined' : 'contained'}
					onClick={() => {
						setStatus(null);
						handleFilterChange(null, 'status');
					}}
				>
					<div className="w-full flex flex-row items-center py-1">
						<InsertEmoticonIcon
							style={{ color: status ? '#1976d2' : 'white' }}
							fontSize="small"
							sx={{ marginLeft: 2 }}
						/>
						<span className="ml-2 text-sm">All</span>
					</div>
				</Button>
			</Stack>
		</div>
	);
}

export default Sidebar;
