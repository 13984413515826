import ApiFactory from 'src/api/ApiFactory';

const api = {
	listJobLabel({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/label/job`, { params });
	},
	createJobLabel({ userId, name, seq }) {
		const apiInstance = ApiFactory.create();
		const data = {
			userId,
			name,
			seq,
		};
		return apiInstance.post(`/label/job`, data);
	},
	listResumeLabel({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/label/resume`, { params });
	},
	createResumeLabel({ userId, name, seq }) {
		const apiInstance = ApiFactory.create();
		const data = {
			userId,
			name,
			seq,
		};
		return apiInstance.post(`/label/resume`, data);
	},
	listSponsorLabel({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/label/sponsor`, { params });
	},
	createSponsorLabel({ userId, name, seq }) {
		const apiInstance = ApiFactory.create();
		const data = {
			userId,
			name,
			seq,
		};
		return apiInstance.post(`/label/sponsor`, data);
	},
};

export default api;
