import ApiFactory from 'src/api/ApiFactory';

const api = {
	confirmPayment({ orderId, orderLink }) {
		const apiInstance = ApiFactory.create();
		const data = { orderId, orderLink };
		return apiInstance.post(`/payment/confirm`, data);
	},
	obtainCoupon({ couponNo }) {
		const apiInstance = ApiFactory.create();
		const data = { couponNo };
		return apiInstance.post(`/payment/coupon`, data);
	},
	getPayment(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/payment/${id}`);
	},
	getSubscriptionPlans() {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/payment/plan/list`);
	},
	postSubscription({ plan_id, application_context, custom_id }) {
		const apiInstance = ApiFactory.create();
		const data = { plan_id, application_context, custom_id };
		return apiInstance.post(`/payment/subscription`, data);
	},
	patchSubscriptionCancel({ reason }) {
		const apiInstance = ApiFactory.create();
		return apiInstance.patch(`/payment/subscription/cancel`, { reason });
	},
};
export default api;
