/* global chrome */
import { useEffect } from 'react';
import { getHashParams } from '../../libs/utils';

const extensionId = process.env.REACT_APP_EXTENSION_ID;

function OauthResult() {
	const token = getHashParams()['token'];
	useEffect(() => {
		chrome.runtime.sendMessage(extensionId, {
			action: 'LOGIN', // call extension background script to login user with credential, please be careful if you want to change it.
			token,
		});
	}, []);
	return (
		<div>
			<span className="text-2xl text-gray-600 font-bold m-0 p-0 h-screen">
				oauth result extension page
			</span>
		</div>
	);
}

export default OauthResult;
