import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import userApi from 'src/api/user.api';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import userAuthStore from 'src/stores/auth.store';
import useToast from 'src/components/Toast/useToast';
import Grid from '@mui/material/Grid';

export default function Personal() {
	const [userData, setUserData] = useState({
		employmentStatus: '',
		visaStatus: '',
	});
	const [isSaving, setIsSaving] = useState(false);
	const userId = userAuthStore((state) => state.userId);
	const { showSuccess, showError } = useToast();

	const handleInputChange = (value, fieldName) => {
		userData[fieldName] = value;
		setUserData({ ...userData });
	};

	const saveUserData = async () => {
		try {
			setIsSaving(true);
			const {
				citizenship,
				employmentStatus,
				visaStatus,
				address,
				city,
				state,
				zipCode,
			} = userData;
			await userApi.updateUser(userId, {
				citizenship,
				employmentStatus,
				visaStatus,
				address,
				city,
				state,
				zipCode,
			});
			showSuccess('Your personal information has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		async function getCurrentWebUser() {
			const response = await userApi.getCurrentWebUser();
			setUserData(response.data);
		}
		getCurrentWebUser();
	}, []);

	return (
		<div className="bg-white border border-gray-200 rounded-lg shadow w1024:px-5 w1024:py-5 px-10 py-8 grid grid-cols-1 gap-y-4 relative">
			<Grid container rowSpacing={2}>
				<Grid item xs={12}>
					<span className="text-xl text-slate-500 font-bold">
						Personal Information
					</span>
				</Grid>
				<Grid item xs={12}>
					<Grid container rowSpacing={1} columnSpacing={2} alignItems="center">
						<Grid item xs={12} sm={4}>
							<span className="text-gray-500">What's your citizenship?</span>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								size="small"
								style={{ maxWidth: 600, width: '100%' }}
								value={userData.citizenship}
								onChange={(e) => {
									handleInputChange(e.target.value, 'citizenship');
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container rowSpacing={1} columnSpacing={2} alignItems="center">
						<Grid item xs={12} sm={4}>
							<span className="text-gray-500">What's your visa status?</span>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Select
								size="small"
								style={{ maxWidth: 600, width: '100%' }}
								value={userData.visaStatus}
								onChange={(e) => {
									handleInputChange(e.target.value, 'visaStatus');
								}}
							>
								<MenuItem value={'H'} size="small">
									H Visa (H-1B, H-2A, H-3…)
								</MenuItem>
								<MenuItem value={'L'} size="small">
									L-1
								</MenuItem>
								<MenuItem value={'J'} size="small">
									J-1
								</MenuItem>
								<MenuItem value={'F'} size="small">
									F-1
								</MenuItem>
								<MenuItem value={'O'} size="small">
									O Visa (O-1, O-2)
								</MenuItem>
								<MenuItem value={'P'} size="small">
									P Visa (P-1, P-2, P-3)
								</MenuItem>
								<MenuItem value={'Q'} size="small">
									Q Visa (Q-1, Q-2)
								</MenuItem>
								<MenuItem value={'R'} size="small">
									R-1
								</MenuItem>
								<MenuItem value={'T'} size="small">
									TC, TN
								</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container rowSpacing={1} columnSpacing={2} alignItems="center">
						<Grid item xs={12} sm={4}>
							<span className="text-gray-500">
								What's your employment status?
							</span>
						</Grid>
						<Grid item xs={12} sm={8}>
							<Select
								size="small"
								style={{ maxWidth: 600, width: '100%' }}
								value={userData.employmentStatus}
								onChange={(e) => {
									handleInputChange(e.target.value, 'employmentStatus');
								}}
							>
								<MenuItem value={'EMPLOY'} size="small">
									Employed
								</MenuItem>
								<MenuItem value={'UNEMPLOY'} size="small">
									Unemployed
								</MenuItem>
								<MenuItem value={'IN_SCHOOL'} size="small">
									In school
								</MenuItem>
								<MenuItem value={'SELF_EMPLOY'} size="small">
									Self-employed
								</MenuItem>
								<MenuItem value={'CONTRACTOR'} size="small">
									Contractor
								</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container rowSpacing={1} columnSpacing={2}>
						<Grid item xs={12} sm={4}>
							<span className="text-gray-500">Where do you live?</span>
						</Grid>
						<Grid item xs={12} sm={8}>
							<div size="small" style={{ maxWidth: 600, width: '100%' }}>
								<TextField
									size="small"
									label="Address"
									fullWidth
									InputLabelProps={{ shrink: true }}
									value={userData.address}
									onChange={(e) => {
										handleInputChange(e.target.value, 'address');
									}}
								/>
								<div className="flex flex-row gap-x-4 mt-4">
									<TextField
										size="small"
										label="City"
										fullWidth
										InputLabelProps={{ shrink: true }}
										value={userData.city}
										onChange={(e) => {
											handleInputChange(e.target.value, 'city');
										}}
									/>
									<TextField
										size="small"
										label="State"
										fullWidth
										InputLabelProps={{ shrink: true }}
										value={userData.state}
										onChange={(e) => {
											handleInputChange(e.target.value, 'state');
										}}
									/>
								</div>
								<div className="flex gap-4 mt-4 items-end">
									<TextField
										className="flex-1"
										size="small"
										label="Postal Code"
										InputLabelProps={{ shrink: true }}
										value={userData.zipCode}
										onChange={(e) => {
											handleInputChange(e.target.value, 'zipCode');
										}}
									/>
									<div className="flex flex-1 justify-end h-fit">
										<LoadingButton
											sx={{ textTransform: 'none', width: '90px' }}
											size="small"
											onClick={saveUserData}
											endIcon={<SaveIcon />}
											loading={isSaving}
											loadingPosition="end"
											variant="contained"
										>
											<span>Save</span>
										</LoadingButton>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
