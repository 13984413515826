import React, { useState, useEffect, useMemo } from 'react';
import { loadScript } from '@paypal/paypal-js';
import CircularProgress from '@mui/material/CircularProgress';
import useToast from 'src/components/Toast/useToast';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import paymentApi from 'src/api/payment.api';
import styles from './PricingTable.module.scss';

import Grid from '@mui/material/Grid';

const classNames = require('classnames');

const loadConfig = {
	clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
	currency: 'USD',
	components: ['buttons', 'marks', 'messages'],
	// commit: true, *
	// debug: true,
	intent: 'capture',
	dataPageType: 'checkout',
	locale: 'en_US',
};
const payaplButtonStyle = {
	layout: 'horizontal',
	color: 'gold',
	shape: 'rect',
	label: 'buynow',
	// label: 'checkout',
	height: 35,
	tagline: false,
};

export default function PricingTable() {
	const [isReady, setIsReady] = useState(false);
	const { showSuccess, showError } = useToast();
	const navigate = useNavigate();
	const [userData, setUserData] = useState({});
	const [isSaving, setIsSaving] = useState(false);

	const loadCredit700 = (paypal) => {
		return paypal
			.Buttons({
				style: { ...payaplButtonStyle },
				createOrder(data, actions) {
					return actions.order.create(paymentSetting.CREDIT700);
				},
				onApprove,
			})
			.render('#CREDIT700');
	};

	const loadCredit1000 = (paypal) => {
		return paypal
			.Buttons({
				style: { ...payaplButtonStyle },
				createOrder(data, actions) {
					return actions.order.create(paymentSetting.CREDIT1000);
				},
				onApprove,
			})
			.render('#CREDIT1000');
	};

	const loadCredit1300 = (paypal) => {
		return paypal
			.Buttons({
				style: { ...payaplButtonStyle },
				createOrder(data, actions) {
					return actions.order.create(paymentSetting.CREDIT1300);
				},
				onApprove,
			})
			.render('#CREDIT1300');
	};

	const onApprove = async (data, actions) => {
		return actions.order.capture().then(async (details) => {
			console.log('details', JSON.stringify(details));
			let response;
			try {
				const link = R.find(R.propEq('self', 'rel'))(details.links);
				response = await paymentApi.confirmPayment({
					orderId: details.id,
					orderLink: link.href,
				});
				showSuccess('Your payment has been created successfully!');
			} catch (err) {
				console.error(err);
				showError('Something went wrong!');
			} finally {
				navigate(`/payment/confirm/${response.data.id}`);
			}
		});
	};

	const renderPaypalButton = async () => {
		try {
			const paypal = await loadScript({ ...loadConfig });
			await loadCredit700(paypal);
			await loadCredit1000(paypal);
			await loadCredit1300(paypal);
		} catch (error) {
			console.error('failed to load the PayPal JS SDK script', error);
		}
		setIsReady(true);
	};
	useEffect(() => {
		renderPaypalButton();
	}, []);

	return (
		<div className="bg-white border border-gray-200 rounded-lg shadow w1024:px-5 w1024:py-5 px-10 py-8 pb-10">
			<Grid
				container
				rowSpacing={{ xs: 2, md: 1 }}
				columnSpacing={{ xs: 3 }}
				maxWidth={'desktop'}
				className={!isReady && 'hidden'}
			>
				<Grid item xs={12}>
					<span className="text-xl text-slate-500 font-bold">
						Pricing Table
					</span>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div className={styles.card}>
						<span className={styles.price}>$29.99</span>
						<span className={styles.price_type}>Starter</span>
						<span className={styles.price_type_description}>
							For those at the beginning of their job search.
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.credit}>700 credits</span>
						<span className={styles.credit_description}>
							Analyzing 700 job descriptions for key skills or tailoring 700
							resumes
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.valid}>Valid for 90 days</span>
						<hr className={styles.hr}></hr>
						<div id="CREDIT700" className="w-full mt-2"></div>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div className={styles.card}>
						<span className={styles.price}>$39.99</span>
						<span className={styles.price_type}>Professional</span>
						<span className={styles.price_type_description}>
							For those actively seeking their next job opportunity.
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.credit}>1000 credits</span>
						<span className={styles.credit_description}>
							Analyzing 1000 job descriptions for key skills or tailoring 1000
							resumes
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.valid}>Valid for 90 days</span>
						<hr className={styles.hr}></hr>
						<div id="CREDIT1000" className="w-full mt-2"></div>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div className={styles.card}>
						<span className={styles.price}>$49.99</span>
						<span className={styles.price_type}>Ultimate</span>
						<span className={styles.price_type_description}>
							For those who go beyond the ordinary pursuing their career.
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.credit}>1300 credits</span>
						<span className={styles.credit_description}>
							Analyzing 1300 job descriptions for key skills or tailoring 1300
							resumes
						</span>
						<hr className={styles.hr}></hr>
						<span className={styles.valid}>Valid for 90 days</span>
						<hr className={styles.hr}></hr>
						<div id="CREDIT1300" className="w-full mt-2"></div>
					</div>
				</Grid>
			</Grid>

			<div
				className={classNames(
					'mt-4 text-slate-500 italic',
					!isReady && 'hidden'
				)}
			>
				Disclaimer: We are calculating the 90-day validity period using the most
				recent credit date.
			</div>

			<div
				className={classNames(
					'flex flex-row justify-center',
					isReady && 'hidden'
				)}
			>
				<div className="text-slate-500">
					<CircularProgress color="inherit" size="2rem" />
				</div>
			</div>
		</div>
	);
}

const paymentSource = {
	paypal: {
		experience_context: {
			payment_method_preference: 'IMMEDIATE_PAYMENT_REQUIRED',
			payment_method_selected: 'PAYPAL',
			brand_name: 'Jobbly Inc.',
			locale: 'en-US',
			landing_page: 'GUEST_CHECKOUT',
			user_action: 'PAY_NOW',
		},
	},
};

const paymentSetting = {
	CREDIT700: {
		purchase_units: [
			{
				items: [
					{
						name: 'CREDIT700',
						quantity: '1',
						unit_amount: {
							currency_code: 'USD',
							value: '29.99',
						},
						tax: {
							currency_code: 'USD',
							value: '0',
						},
					},
				],
				amount: {
					currency_code: 'USD',
					value: '29.99',
					breakdown: {
						item_total: {
							currency_code: 'USD',
							value: '29.99',
						},
						tax_total: {
							currency_code: 'USD',
							value: '0',
						},
					},
				},
			},
		],
		intent: 'CAPTURE',
		payment_source: { ...paymentSource },
	},
	CREDIT1000: {
		purchase_units: [
			{
				items: [
					{
						name: 'CREDIT1000',
						quantity: '1',
						unit_amount: {
							currency_code: 'USD',
							value: '39.99',
						},
						tax: {
							currency_code: 'USD',
							value: '0',
						},
					},
				],
				amount: {
					currency_code: 'USD',
					value: '39.99',
					breakdown: {
						item_total: {
							currency_code: 'USD',
							value: '39.99',
						},
						tax_total: {
							currency_code: 'USD',
							value: '0',
						},
					},
				},
			},
		],
		intent: 'CAPTURE',
		payment_source: { ...paymentSource },
	},
	CREDIT1300: {
		purchase_units: [
			{
				items: [
					{
						name: 'CREDIT1300',
						quantity: '1',
						unit_amount: {
							currency_code: 'USD',
							value: '49.99',
						},
						tax: {
							currency_code: 'USD',
							value: '0',
						},
					},
				],
				amount: {
					currency_code: 'USD',
					value: '49.99',
					breakdown: {
						item_total: {
							currency_code: 'USD',
							value: '49.99',
						},
						tax_total: {
							currency_code: 'USD',
							value: '0',
						},
					},
				},
			},
		],
		intent: 'CAPTURE',
		payment_source: { ...paymentSource },
	},
};
