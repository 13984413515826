import useBackdrop from 'src/components/Backdrop/useBackdrop';
import React, { useEffect, useState } from 'react';
import useToast from 'src/components/Toast/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import paymentApi from 'src/api/payment.api';

export default function Coupon() {
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const { showSuccess, showError } = useToast();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		(async () => {
			try {
				console.log('id', id);
				showBackdrop();
				await paymentApi.obtainCoupon({ couponNo: id });
				showSuccess('Your credits has been obtained successfully!');
			} catch (err) {
				console.error(err);
				showError(err?.errorMsg || 'Something went wrong!');
			} finally {
				closeBackdrop();
				navigate('/account');
			}
		})();
	}, []);

	return (
		<>
			i am coupon page
			<BackdropComponent></BackdropComponent>
		</>
	);
}
