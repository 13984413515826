import React, { useState, useEffect } from 'react';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import resumeApi from '../../../api/resume.api';
import labelApi from '../../../api/label.api';
import userAuthStore from '../../../stores/auth.store';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import formStore from '../../../stores/form.store';
import * as R from 'ramda';
import AdjustIcon from '@mui/icons-material/Adjust';
import Tooltip from '@mui/material/Tooltip';
import { useForm, Controller } from 'react-hook-form';
import alertStore from 'src/stores/alert.store';

const filter = createFilterOptions();

export default function Basic({ className, resumeData, onSuccessSaved }) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newResumeData, setNewResumeData] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [options, setOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const userId = userAuthStore((state) => state.userId);
	const [isSaving, setIsSaving] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			resumeName: newResumeData.resumeName,
		},
	});

	const handleLabelChange = async (event, newValue, reason, details) => {
		let valueList = [...selectedLabels];
		if (!!details && details.option.create && reason === 'selectOption') {
			const response = await labelApi.createResumeLabel({
				userId: userId,
				name: details.option.name,
			});

			// 2) set selected label
			valueList.push({ name: details.option.name, id: response.data.id });
			setSelectedLabels(valueList);
		} else {
			setSelectedLabels(newValue);
		}
	};

	const handleInputChange = (value, fieldName) => {
		newResumeData[fieldName] = value;
		setNewResumeData({ ...newResumeData });
	};

	const saveNewResumeData = async () => {
		try {
			setIsSaving(true);
			const { resumeName, isPrimary } = newResumeData;
			const labels = R.map((x) => x.name, selectedLabels);
			await resumeApi.updateResume(selectedResumeId, {
				resumeName,
				labels,
				isPrimary,
			});
			onSuccessSaved();
			setIsEdit(false);
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Your resume has been saved successfully!',
				alertSeverity: 'success',
			});
		} catch (err) {
			console.error(err);
			alertStore.setState({
				alertOpen: true,
				alertMessage: 'Something went wrong!',
				alertSeverity: 'error',
			});
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		console.log("the data of 'Basic' component is loaded!!!!!!");
		if (!isEdit) return;

		setNewResumeData({ ...resumeData });
		if (resumeData.labels) {
			const labels = [];
			resumeData.labels.map((label) => labels.push({ name: label }));
			setSelectedLabels(labels);
		}
	}, [resumeData, isEdit]);

	useEffect(() => {
		if (loading) {
			(async () => {
				const response = await labelApi.listResumeLabel({
					sortby: 'name',
					order: 'asc',
					limit: 0,
					field: 'id,name',
				});
				setOptions(response.data.rows);
				setLoading(false);
			})();
		}
	}, [loading]);

	useEffect(() => {
		if (open) {
			setLoading(true);
		} else {
			setOptions([]);
		}
	}, [open]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm hover:bg-zinc-200`}
		>
			{isEdit ? (
				<React.Fragment>
					<div className="flex flex-col gap-y-4 mt-4">
						<div className="grid grid-cols-[1fr] gap-x-8">
							<Controller
								name="resumeName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Resume Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="resumeName"
										size="small"
										required
										value={newResumeData.resumeName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'resumeName');
										}}
										label="Resume Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
							{/* <TextField
                  id="standard-password-input"
                  size="small"
                  label="Resume Name"
                  value={newResumeData.resumeName}
                  onChange={(e) => { handleInputChange(e.target.value, 'resumeName') }}
                /> */}
						</div>
						<Autocomplete
							sx={{}}
							value={selectedLabels}
							size="small"
							multiple
							loading={loading}
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							onChange={handleLabelChange}
							isOptionEqualToValue={(option, value) =>
								option.name === value.name
							}
							filterSelectedOptions
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								const { inputValue } = params;

								const isExisting = options.some(
									(option) => inputValue === option.name
								);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										name: inputValue,
										label: `Add "${inputValue}"`,
										create: true,
									});
								}
								return filtered;
							}}
							options={options}
							getOptionLabel={(option) => {
								if (option.label) {
									return option.name;
								}
								// Regular option
								return option.name;
							}}
							renderOption={(props, option) => (
								<li {...props}>{option.create ? option.label : option.name}</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Labels"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</div>
					<div className="flex flex-row justify-end mt-4 gap-x-4">
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							size="small"
							onClick={() => {
								setIsEdit(false);
							}}
						>
							Cancel
						</Button>
						<LoadingButton
							sx={{ textTransform: 'none' }}
							size="small"
							onClick={handleSubmit((data) => saveNewResumeData(data))}
							endIcon={<SaveIcon />}
							loading={isSaving}
							loadingPosition="end"
							variant="contained"
							disabled={isSaving || !!Object.keys(formErrors).length}
						>
							<span>Save</span>
						</LoadingButton>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="grid grid-cols-[1fr_60px]">
						<div className="flex flex-col justify-start items-start gap-y-4">
							<div className="flex flex-row gap-x-1 items-center">
								<div className="text-slate-700 font-bold text-2xl grow underline underline-offset-8">
									&nbsp;{resumeData.resumeName}&nbsp;
								</div>
								{resumeData.isPrimary && (
									<Tooltip title="primary">
										<AdjustIcon color="primary" />
									</Tooltip>
								)}
							</div>
							<div className="flex flex-row flex-wrap">
								{!!resumeData.labels && (
									<div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
										{resumeData.labels.map((label, index) => (
											<Chip
												key={index}
												label={label}
												variant="outlined"
												size="small"
												sx={{ cursor: 'pointer' }}
											/>
										))}
									</div>
								)}
							</div>
						</div>
						<div className="">
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								color="primary"
								onClick={() => setIsEdit(true)}
								startIcon={<BorderColorOutlinedIcon />}
							>
								Edit
							</Button>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}
