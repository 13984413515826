import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Switch,
	Box,
	Grid,
	FormControlLabel,
	Stack,
	Button,
	Fade,
} from '@mui/material';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import userAuthStore from 'src/stores/auth.store';
import { useLocation } from 'react-router-dom';
import userApi from 'src/api/user.api';

export default function Top({ setOpenUploadDialog }) {
	const displayName = userAuthStore((state) => state.displayName);
	const userId = userAuthStore((state) => state.userId);
	const location = useLocation();
	const [user, setUser] = useState({});
	const getUser = async () => {
		const response = await userApi.getCurrentWebUser();
		setUser(response.data);
	};

	const handleChange = async (event) => {
		try {
			await userApi.updateUser(userId, { isPublic: event.target.checked });
			await getUser();
		} catch (err) {}
	};

	useEffect(() => {
		getUser();
	}, []);

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid item>
				<Button
					variant="outlined"
					size="small"
					onClick={() => {
						setOpenUploadDialog(true);
					}}
					endIcon={<FileUploadOutlinedIcon />}
				>
					Import from Resume
				</Button>
			</Grid>
			<Grid item>
				{location.pathname.indexOf('public') < 0 && (
					<Stack direction="row" spacing={2}>
						{user?.isPublic && (
							<Fade in={user?.isPublic}>
								<Button
									variant="outlined"
									size="small"
									onClick={() => {
										window.open(
											`/public/dashboard/${userId}`,
											'_blank',
											'noreferrer'
										);
									}}
									endIcon={<InsertLinkOutlinedIcon />}
								>
									Share Page
								</Button>
							</Fade>
						)}
						<FormControlLabel
							control={
								<Switch
									size="small"
									checked={user.isPublic}
									onChange={handleChange}
								/>
							}
							label="Publish Page"
							labelPlacement="start"
						/>
					</Stack>
				)}
			</Grid>
		</Grid>
	);
}
