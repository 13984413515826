import React, { useState, useEffect, useMemo } from 'react';
import userApi from 'src/api/user.api';
import { useRequest } from 'ahooks';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
//
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { PaymentEvents } from './PaymentEvents';
import { Link, useNavigate } from 'react-router-dom';

let dollarUSLocale = Intl.NumberFormat('en-US');

export default function PaymentHistory() {
	const { data, loading } = useRequest(() => userApi.getUserPaymentHistory());
	const navigate = useNavigate();

	const rowData = useMemo(() => {
		const list = data?.data?.rows || [];
		// .split("(?<=\\d)(?=\\D)|(?=\\d)(?<=\\D)");
		return _.chain(list)
			.map((i) => {
				const itemNumber = i.description.match(/\d+/g);
				const itemString = i.description.match(/[a-zA-Z]+/g);
				// console.log(itemString)

				return {
					...i,
					datetime: dayjs(i.month, 'YYYYMM'),
					paidDateTime: dayjs(i.paidAt).format('YYYY-MM-DD HH:mm:ss'),
					item: `${itemString || ''} ${itemNumber || ''}`,
				};
			})
			.value();
	}, [data]);

	return (
		<div>
			<span className="text-xl text-slate-500 font-bold">Payment History</span>
			<TableContainer component={Paper} style={{ marginTop: 20 }}>
				<Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
					<TableHead id="payment-history-section">
						<TableRow>
							<TableCell style={{ fontWeight: 600 }}>Date Time</TableCell>
							{/* <TableCell style={{ fontWeight: 600 }}>Order Number</TableCell> */}
							<TableCell style={{ fontWeight: 600 }}>Item</TableCell>
							<TableCell style={{ fontWeight: 600 }}>Total</TableCell>
							<TableCell style={{ fontWeight: 600 }}>Status</TableCell>
							<TableCell style={{ fontWeight: 600 }}>Payment ID</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell colSpan={6} align="center">
									<CircularProgress />
								</TableCell>
							</TableRow>
						) : rowData.length > 0 ? (
							rowData.map((row, index) => (
								<StyledTableRow
									key={index}
									onClick={() => {
										navigate(`/payment/confirm/${row.id}`);
									}}
								>
									<TableCell component="th" scope="row">
										{row.paidDateTime}
									</TableCell>
									{/* <TableCell>
                      {row.paypalOrderId || '-'}
                    </TableCell> */}
									<TableCell>
										{/* {PaymentEvents[row.description]} */}
										{row.item}
									</TableCell>
									<TableCell
									// style={{ textAlign: 'right' }}
									>
										$ {dollarUSLocale.format(row.amount)}
									</TableCell>
									<TableCell>{row.status}</TableCell>
									<TableCell>{row.paypalOrderId}</TableCell>
								</StyledTableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={6} component="th" scope="row">
									No Payment History Found
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

const StyledTableRow = styled(TableRow)`
	cursor: pointer;
	:hover {
		background-color: #ecf2ff;
	}
`;
