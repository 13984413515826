import React, { useMemo } from 'react';
import './List.scss';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import jobApi from 'src/api/job.api';
import Detail from './Detail';
import formStore from 'src/stores/form.store';
import { trimHtml } from '../../../libs/utils';
const dayjs = require('dayjs');

export default function Content({
	className,
	list,
	listJob,
	handleCheckChange,
	checkedList,
}) {
	const jobMode = formStore((state) => state.jobMode);
	const selectedJobId = formStore((state) => state.selectedJobId);
	const drawerOpen = useMemo(() => {
		return jobMode === 'detail';
	}, [jobMode]);

	const openDetail = (id) => {
		formStore.setState({ selectedJobId: id, jobMode: 'detail' });
	};
	const closeDetail = () => {
		formStore.setState({ jobMode: 'list' });
	};

	const updateStatus = async (e, jobId, status) => {
		e.stopPropagation();
		const requestBody = { status };
		await jobApi.updateJob(jobId, requestBody);
		listJob();
	};

	return (
		<>
			<div
				className={`${className} border-2 border-gray-200 rounded-sm px-3 py-2`}
			>
				<div className={`row hidden flex-row px-4 h-10`}>
					<div className="w-8"></div>
					<div className="w-28 pl-4 flex items-center text-sm text-slate-700 ml-2 font-bold">
						Company
					</div>
					<div className="w-48 flex items-center text-sm text-slate-700 font-bold">
						Title
					</div>
					<div className="ml-2 grow flex items-center text-sm text-slate-700 font-bold">
						Description
					</div>
					<div className="w-28 flex items-center justify-end text-sm text-slate-700 font-bold">
						Saved Date
					</div>
				</div>
				{list.map((item) => (
					<div key={item.id} className="row">
						<div className={'flex flex-row px-4 h-12'}>
							<div
								style={{ width: '32px' }}
								className="flex items-center justify-center text-sm text-slate-700"
							>
								<Checkbox
									value={item.id}
									onChange={handleCheckChange}
									checked={checkedList.includes(item.id)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</div>
							<div
								style={{ width: '40px' }}
								className="flex items-center justify-center"
							>
								{item.status === 'APPLY' && (
									<Tooltip title="Saved">
										<BookmarksOutlinedIcon fontSize="small" color="primary" />
									</Tooltip>
								)}
								{item.status === 'REQUEST' && (
									<Tooltip title="Applied">
										<MarkEmailReadOutlinedIcon
											fontSize="small"
											color="primary"
										/>
									</Tooltip>
								)}
								{item.status === 'INTERVIEW' && (
									<Tooltip title="Interviewing">
										<PermContactCalendarOutlinedIcon
											fontSize="small"
											color="primary"
										/>
									</Tooltip>
								)}
								{item.status === 'OFFER' && (
									<Tooltip title="Job Offered">
										<MilitaryTechOutlinedIcon
											fontSize="small"
											color="primary"
										/>
									</Tooltip>
								)}
								{item.status === 'ARCHIVE' && (
									<Tooltip title="Archived">
										<ArchiveOutlinedIcon fontSize="small" color="primary" />
									</Tooltip>
								)}
							</div>
							<div
								style={{
									width: '128px',
									marginLeft: '8px',
								}}
								className="flex items-center text-sm text-slate-700 ellipsis"
								onClick={() => openDetail(item.id)}
							>
								<span>{item.company}</span>
							</div>
							<div
								style={{ width: '192px' }}
								className="flex items-center text-sm text-slate-700 ellipsis"
								onClick={() => openDetail(item.id)}
							>
								<span>{item.title}</span>
							</div>
							<div
								style={{ marginLeft: '8px' }}
								className="grow ellipsis flex items-center text-sm text-slate-700"
								onClick={() => openDetail(item.id)}
							>
								<span>{trimHtml(item.description)}</span>
							</div>
							<div
								style={{ width: '112px' }}
								className="date flex items-center justify-end text-sm text-slate-700"
							>
								{dayjs(item.createdAt).format('YYYY-MM-DD')}
							</div>
							<div className="action text-right hidden">
								<div className="flex flex-row items-center">
									{item.status !== 'APPLY' && (
										<Tooltip title="Saved">
											<IconButton
												color="primary"
												onClick={(e) => updateStatus(e, item.id, 'APPLY')}
											>
												<BookmarksOutlinedIcon />
											</IconButton>
										</Tooltip>
									)}
									{item.status !== 'REQUEST' && (
										<Tooltip title="Applied">
											<IconButton
												color="primary"
												onClick={(e) => updateStatus(e, item.id, 'REQUEST')}
											>
												<MarkEmailReadOutlinedIcon />
											</IconButton>
										</Tooltip>
									)}
									{item.status !== 'INTERVIEW' && (
										<Tooltip title="Interviewing">
											<IconButton
												color="primary"
												onClick={(e) => updateStatus(e, item.id, 'INTERVIEW')}
											>
												<PermContactCalendarOutlinedIcon />
											</IconButton>
										</Tooltip>
									)}
									{item.status !== 'OFFER' && (
										<Tooltip title="Job Offered">
											<IconButton
												color="primary"
												onClick={(e) => updateStatus(e, item.id, 'OFFER')}
											>
												<MilitaryTechOutlinedIcon />
											</IconButton>
										</Tooltip>
									)}
									{item.status !== 'ARCHIVE' && (
										<Tooltip title="Archived">
											<IconButton
												color="primary"
												onClick={(e) => updateStatus(e, item.id, 'ARCHIVE')}
											>
												<ArchiveOutlinedIcon />
											</IconButton>
										</Tooltip>
									)}
								</div>
							</div>
						</div>
						{!!item?.labels?.length && (
							<div
								style={{ minHeight: '32px' }}
								className="flex flex-row items-center px-4"
								onClick={() => openDetail(item.id)}
							>
								<div className="shrink-0" style={{ flexBasis: '408px' }}></div>
								<div className="flex flex-row flex-wrap grow  gap-x-1 gap-y-1 max-w-max pb-1">
									{item.labels.map((label, index) => (
										<Chip
											key={index}
											label={label}
											variant="outlined"
											size="small"
										/>
									))}
								</div>
							</div>
						)}
					</div>
				))}
			</div>
			<Detail
				key={selectedJobId}
				open={drawerOpen}
				onClose={closeDetail}
			></Detail>
		</>
	);
}
