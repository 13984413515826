import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import {
	GoogleLoginButton,
	LinkedInLoginButton,
} from 'react-social-login-buttons';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import userApi from 'src/api/user.api';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import userAuthStore from 'src/stores/auth.store';
import useToast from 'src/components/Toast/useToast';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';

export default function Basic() {
	const [userData, setUserData] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const userId = userAuthStore((state) => state.userId);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			firstName: userData.firstName,
			lastName: userData.lastName,
			phone: userData.phone,
		},
	});

	const oauths = useMemo(() => {
		return userData.oauths ? userData.oauths.map((item) => item.provider) : [];
	}, [userData.oauths]);

	const handleInputChange = (value, fieldName) => {
		userData[fieldName] = value;
		setUserData({ ...userData });
	};

	const getUserData = async () => {
		const response = await userApi.getCurrentWebUser();
		userAuthStore.setState({ credits: response.data.credits });
		setUserData(response.data);
	};

	const tooltipdescription = useMemo(() => {
		if (userData.lastPaidAt) {
			return `Your credit will expire after ${dayjs(
				userData.lastPaidAt,
				'YYYY-MM-DDTHH:mm:ssZ'
			)
				.add(dayjs.duration({ days: 120 }))
				.format('YYYY-MM-DD')}`;
		}
	}, [userData.lastPaidAt]);

	const saveUserData = async () => {
		try {
			setIsSaving(true);
			const { firstName, lastName, phone } = userData;
			await userApi.updateUser(userId, { firstName, lastName, phone });
			showSuccess('Your account information has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		if (!isSaving) getUserData();
	}, [isSaving]);

	return (
		<div className="h-full bg-white border border-gray-200 rounded-lg shadow px-10 w1024:px-5 w1024:py-5 py-8 flex flex-col gap-y-4 relative">
			<div className="h-full w-full flex flex-col justify-between gap-5">
				<div className="flex flex-col gap-y-4 relative">
					<div className="flex flex-row justify-between">
						<span className="text-xl text-slate-500 font-bold">
							Account Information
						</span>
						{!!userData?.lastPaidAt && (
							<div className="flex flex-row gap-2 items-center">
								<span className="text-base text-slate-700 font-bold">
									{userData.credits}
								</span>
								<div className="text-slate-700 self-center cursor-pointer">
									{userData.credits ? (
										<Tooltip title={tooltipdescription}>
											<InfoIcon fontSize="small" color="inherit" />
										</Tooltip>
									) : (
										<></>
									)}
								</div>
							</div>
						)}
					</div>
					<Controller
						name="firstName"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'First Name',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="firstName"
								size="small"
								required
								value={userData.firstName}
								onChange={(e) => {
									handleInputChange(e.target.value, 'firstName');
								}}
								label="First Name"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="lastName"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Last Name',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="lastName"
								size="small"
								required
								value={userData.lastName}
								onChange={(e) => {
									handleInputChange(e.target.value, 'lastName');
								}}
								label="Last Name"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="phone"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Phone',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="phone"
								size="small"
								required
								value={userData.phone}
								onChange={(e) => {
									handleInputChange(e.target.value, 'phone');
								}}
								label="Phone"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<div>
						<Grid container rowSpacing={1}>
							<Grid item xs={12}>
								<span className="text-slate-600 text-sm">Login Method</span>
							</Grid>
							<Grid item xs={12}>
								<Grid container columnSpacing={1}>
									<Grid item xs={6}>
										<GoogleLoginButton
											iconSize="16px"
											style={{ height: '30px', margin: '0', width: '100%' }}
										>
											<div className="flex items-center justify-center text-slate-600">
												{oauths.includes('GOOGLE') && (
													<CheckCircleOutlineIcon fontSize="small" />
												)}
												{/* <CloseIcon fontSize="small" /> */}
											</div>
										</GoogleLoginButton>
									</Grid>
									<Grid item xs={6}>
										<LinkedInLoginButton
											iconSize="16px"
											style={{ height: '30px', margin: '0', width: '100%' }}
										>
											<div className="flex items-center justify-center">
												{oauths.includes('LINKEDIN') && (
													<CheckCircleOutlineIcon fontSize="small" />
												)}
											</div>
										</LinkedInLoginButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="w-full flex justify-end">
					<LoadingButton
						className=""
						sx={{ textTransform: 'none', width: '90px' }}
						size="small"
						onClick={handleSubmit(() => saveUserData())}
						endIcon={<SaveIcon />}
						loading={isSaving}
						loadingPosition="end"
						variant="contained"
						disabled={isSaving || !!Object.keys(formErrors).length}
					>
						<span>Save</span>
					</LoadingButton>
				</div>
			</div>
		</div>
	);
}
