import React, { useState, useEffect } from 'react';
import styles from './Skill.module.scss';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import formStore from '../../../stores/form.store';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Item from './Item';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import resumeApi from 'src/api/resume.api';
import useToast from 'src/components/Toast/useToast';
import AddIcon from '@mui/icons-material/Add';

export default function Skill({ className, skillsData, onSuccessSaved }) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newSkillsData, setNewSkillsData] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const handleInputChange = (skillData, index) => {
		const tempData = [...newSkillsData];
		tempData[index] = skillData;
		setNewSkillsData([...tempData]);
	};
	const handleAddChange = (skill) => {
		setNewSkillsData((prev) => [...prev, skill]);
	};
	const handleDeleteChange = (index) => {
		console.log('handleDeleteClick', index);
		const tempData = [...newSkillsData];
		tempData.splice(index, 1);
		console.log(tempData);
		setNewSkillsData([...tempData]);
	};

	const saveNewSkillsData = async () => {
		try {
			setIsSaving(true);
			newSkillsData.map((item, index) => {
				item.reference.current.check();
			});

			// FIXME: "newSkillsData[i].reference.current.pass()" not work
			let flag = true;
			for (let i = 0; i < newSkillsData.length; i++) {
				if (!newSkillsData[i].skillName) {
					flag = false;
					break;
				}
			}
			if (!flag) return;

			const tempData = newSkillsData.map((item) => {
				return {
					skillName: item.skillName,
					checked: item.checked,
					value: parseFloat(item.value),
				};
			});
			await resumeApi.createSkills({
				resumeId: selectedResumeId,
				skills: [...tempData],
			});
			setIsEdit(false);
			onSuccessSaved();
			showSuccess('Your skills has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		console.log("the data of 'Skill' component is loaded!!!!!!");
		if (isEdit) {
			const newSkillsData = skillsData?.length
				? skillsData.map((item) => {
						return { ...item, isEdit: false, reference: React.createRef() };
					})
				: [
						{
							checked: true,
							value: 0,
							skillName: '',
							reference: React.createRef(),
						},
					];
			setNewSkillsData(newSkillsData);
		}
	}, [isEdit]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm`}
		>
			<div className="grid grid-cols-[1fr_60px] h-12">
				<div className="flex flex-row gap-x-1 items-end">
					<span className="text-2xl font-semibold text-slate-700 self-center">
						Skill
					</span>
					<div className="text-slate-700 self-center cursor-pointer">
						<Tooltip title="When you check the skill box, your skill will appear on the dashboard, allowing you to share it with others.">
							<HelpOutlineIcon fontSize="small" color="inherit" />
						</Tooltip>
					</div>
				</div>
				{isEdit ? (
					<div className="">
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							color="primary"
							onClick={() =>
								handleAddChange({
									checked: true,
									value: 0,
									reference: React.createRef(),
								})
							}
							startIcon={<AddIcon />}
						>
							Add
						</Button>
					</div>
				) : (
					<div className="">
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							color="primary"
							onClick={() => setIsEdit(true)}
							startIcon={<BorderColorOutlinedIcon />}
						>
							Edit
						</Button>
					</div>
				)}
			</div>
			{isEdit ? (
				<React.Fragment>
					<div className="flex flex-col gap-y-4">
						{newSkillsData.map((skill, index) => (
							<Item
								key={index}
								index={index}
								ref={skill.reference}
								skillData={skill}
								totalLength={newSkillsData.length}
								onInputChange={handleInputChange}
								onAddChange={handleAddChange}
								onDeleteChange={handleDeleteChange}
							></Item>
						))}
					</div>
					<div className="flex flex-row justify-end mt-4 gap-x-4">
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							size="small"
							onClick={() => {
								setIsEdit(false);
							}}
						>
							Cancel
						</Button>
						<LoadingButton
							sx={{ textTransform: 'none' }}
							size="small"
							onClick={saveNewSkillsData}
							endIcon={<SaveIcon />}
							loading={isSaving}
							loadingPosition="end"
							variant="contained"
						>
							<span>Save</span>
						</LoadingButton>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					{skillsData?.map((skillData) => (
						<div
							key={skillData.id}
							className={`${styles.item} flex flex-row justify-between items-center`}
						>
							<div className="grow grid grid-cols-[40px_140px_120px] gap-x-8">
								<div className={`flex flex-row gap-x-2 ${styles.location}`}>
									{skillData.checked ? (
										<CheckIcon size="small" color="default"></CheckIcon>
									) : (
										<CloseIcon size="small" color="default"></CloseIcon>
									)}
								</div>
								<div className={'ellipsis'}>
									<span className={`${styles.company}`}>
										{skillData.skillName}
									</span>
								</div>
								<Rating
									size="small"
									value={parseFloat(skillData.value)}
									precision={0.5}
									readOnly
									emptyIcon={
										<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
									}
								/>
							</div>
						</div>
					))}
				</React.Fragment>
			)}
		</div>
	);
}
