import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import jobApi from '../../../../../api/job.api';
import labelApi from '../../../../../api/label.api';
import userAuthStore from '../../../../../stores/auth.store';
import formStore from '../../../../../stores/form.store';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import * as R from 'ramda';
import { getQuillModules, getQuillFormats } from 'src/libs/utils';
import { useForm, Controller } from 'react-hook-form';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import Tooltip from '@mui/material/Tooltip';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import useToast from 'src/components/Toast/useToast';

const filter = createFilterOptions();

export default function Info({ setUpdatedAt }) {
	const userId = userAuthStore((state) => state.userId);
	const selectedJobId = formStore((state) => state.selectedJobId);
	const [jobData, setJobData] = useState({ id: null, status: 'APPLY' });
	const [isSaving, setIsSaving] = useState(false);
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [selectedSponsors, setSelectedSponsors] = useState([]);
	const [options, setOptions] = useState([]);
	const [sponsorOptions, setSponsorOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [sponsorOpen, setSponsorOpen] = useState(false);
	const loading = open && options.length === 0;
	const sponsorLoading = sponsorOpen && sponsorOptions.length === 0;
	const [description, setDescription] = useState(null);
	const [status, setStatus] = useState('APPLY');
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			title: jobData.title,
			company: jobData.company,
			jobType: jobData.jobType,
			location: jobData.location,
		},
	});

	const getJobData = async () => {
		// must put inside "useEffect"
		// React Hook useEffect has a missing dependency: 'getJobData'. Either include it or remove the dependency array
		if (!selectedJobId) {
			return;
		}
		try {
			showBackdrop();
			const response = await jobApi.getJob(selectedJobId);
			setJobData(response.data);
			setStatus(response.data.status);
			setUpdatedAt(response.data.updatedAt);
			const labels = [];
			const sponsors = [];
			if (Array.isArray(response.data.labels)) {
				response.data.labels.map((label) => labels.push({ name: label }));
			}
			if (Array.isArray(response.data.sponsors)) {
				response.data.sponsors.map((label) => sponsors.push({ name: label }));
			}
			setSelectedLabels(labels);
			setSelectedSponsors(sponsors);
			setDescription(response.data.description);
		} catch (err) {
			console.error(err);
		} finally {
			closeBackdrop();
		}
	};

	const saveJobData = async () => {
		setIsSaving(true);
		try {
			const {
				status,
				title,
				postUrl,
				company,
				location,
				jobType,
				experience,
				salary,
			} = jobData;
			const labels = R.map((x) => x.name, selectedLabels);
			const sponsors = R.map((x) => x.name, selectedSponsors);
			if (selectedJobId) {
				await jobApi.updateJob(selectedJobId, {
					status,
					title,
					postUrl,
					company,
					labels,
					description,
					location,
					jobType,
					experience,
					salary,
					sponsors,
				});
			} else {
				const response = await jobApi.createJob({
					userId,
					status,
					title,
					postUrl,
					company,
					labels,
					description,
					location,
					jobType,
					experience,
					sponsors,
					salary,
				});
				formStore.setState({ selectedJobId: response.data.id });
			}
			showSuccess('Your job has been saved successfully!');
			getJobData();
		} catch (err) {
			console.error(err);
			showError();
		} finally {
			setIsSaving(false);
		}
	};

	const handleInputChange = (value, fieldName) => {
		jobData[fieldName] = value;
		setJobData({ ...jobData });
	};

	const handleLabelChange = async (event, newValue, reason, details) => {
		let valueList = [...selectedLabels];
		if (!!details && details.option.create && reason === 'selectOption') {
			const response = await labelApi.createJobLabel({
				userId: userId,
				name: details.option.name,
			});

			// 2) set selected label
			valueList.push({ name: details.option.name, id: response.data.id });
			setSelectedLabels(valueList);
		} else {
			setSelectedLabels(newValue);
		}
	};

	const handleSponsorChange = async (event, newValue, reason, details) => {
		let valueList = [...selectedSponsors];
		if (!!details && details.option.create && reason === 'selectOption') {
			const response = await labelApi.createSponsorLabel({
				userId: userId,
				name: details.option.name,
			});

			// 2) set selected sponsor
			valueList.push({ name: details.option.name, id: response.data.id });
			setSelectedSponsors(valueList);
		} else {
			setSelectedSponsors(newValue);
		}
	};

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			const response = await labelApi.listJobLabel({
				sortby: 'name',
				order: 'asc',
				limit: 0,
				field: 'id,name',
			});
			if (active) {
				setOptions(response.data.rows);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	useEffect(() => {
		let active = true;

		if (!sponsorLoading) {
			return undefined;
		}

		(async () => {
			const response = await labelApi.listSponsorLabel({
				sortby: 'name',
				order: 'asc',
				limit: 0,
				field: 'id,name',
			});
			if (active) {
				setSponsorOptions(response.data.rows);
			}
		})();

		return () => {
			active = false;
		};
	}, [sponsorLoading]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	useEffect(() => {
		if (!sponsorOpen) {
			setSponsorOptions([]);
		}
	}, [sponsorOpen]);

	useEffect(() => {
		if (selectedJobId) {
			getJobData();
		}
	}, [selectedJobId]);

	const handleStatusChange = (event, newStatus) => {
		if (newStatus !== null) {
			setStatus(newStatus);
			handleInputChange(newStatus, 'status');
		}
	};

	return (
		<div className="flex flex-col mt-2">
			<div className="grid grid-cols-2 gap-x-8">
				<div className="flex flex-col gap-y-6">
					<Controller
						name="title"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Title is required',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="title"
								size="small"
								required
								value={value}
								onChange={(e) => {
									handleInputChange(e.target.value, 'title');
								}}
								label="Title"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="company"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Company is required',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="company"
								size="small"
								required
								value={value}
								onChange={(e) => {
									handleInputChange(e.target.value, 'company');
								}}
								label="Company"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<Controller
						name="jobType"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Job Type is required',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="jobType"
								size="small"
								required
								value={value}
								onChange={(e) => {
									handleInputChange(e.target.value, 'jobType');
								}}
								label="Job Type"
								variant="outlined"
								error={!!error}
								// helperText={error?.message}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<TextField
						size="small"
						label="Salary"
						value={jobData.salary}
						onChange={(e) => {
							handleInputChange(e.target.value, 'salary');
						}}
						InputLabelProps={{ shrink: true }}
					/>
				</div>
				<div className="flex flex-col gap-y-6">
					<ToggleButtonGroup
						color="primary"
						value={status}
						exclusive
						onChange={handleStatusChange}
						size="small"
						fullWidth
					>
						<ToggleButton value="APPLY" aria-label="left aligned">
							<Tooltip title="Saved">
								<BookmarksOutlinedIcon />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="REQUEST" aria-label="left aligned">
							<Tooltip title="Applied">
								<MarkEmailReadOutlinedIcon />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="INTERVIEW" aria-label="centered">
							<Tooltip title="Interviewing">
								<PermContactCalendarOutlinedIcon />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="OFFER" aria-label="centered">
							<Tooltip title="Job Offered">
								<MilitaryTechOutlinedIcon />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="ARCHIVE" aria-label="right aligned">
							<Tooltip title="Archived">
								<ArchiveOutlinedIcon />
							</Tooltip>
						</ToggleButton>
					</ToggleButtonGroup>
					<Controller
						name="location"
						control={control}
						rules={{
							required: {
								value: true,
								message: 'Location is required',
							},
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								name="location"
								size="small"
								required
								value={value}
								onChange={(e) => {
									handleInputChange(e.target.value, 'location');
								}}
								label="Location"
								variant="outlined"
								error={!!error}
								InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
					<TextField
						size="small"
						label="Job posting URL"
						value={jobData.postUrl}
						onChange={(e) => {
							handleInputChange(e.target.value, 'postUrl');
						}}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											window.open(jobData.postUrl, '_blank');
										}}
									>
										<DirectionsIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						size="small"
						label="Year of experience"
						value={jobData.experience}
						onChange={(e) => {
							handleInputChange(e.target.value, 'experience');
						}}
						InputLabelProps={{ shrink: true }}
					/>
				</div>
			</div>

			<Autocomplete
				className="mt-6"
				value={selectedSponsors}
				size="small"
				multiple
				loading={sponsorLoading}
				open={sponsorOpen}
				onOpen={() => {
					setSponsorOpen(true);
				}}
				onClose={() => {
					setSponsorOpen(false);
				}}
				onChange={handleSponsorChange}
				isOptionEqualToValue={(option, value) => option.name === value.name}
				filterSelectedOptions
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					const { inputValue } = params;

					const isExisting = options.some(
						(option) => inputValue === option.name
					);
					if (inputValue !== '' && !isExisting) {
						filtered.push({
							name: inputValue,
							label: `Add "${inputValue}"`,
							create: true,
						});
					}
					return filtered;
				}}
				options={sponsorOptions}
				getOptionLabel={(option) => {
					if (option.label) {
						return option.name;
					}
					// Regular option
					return option.name;
				}}
				renderOption={(props, option) => (
					<li {...props}>{option.create ? option.label : option.name}</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Sponsors"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{sponsorLoading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>

			<Autocomplete
				className="mt-6"
				value={selectedLabels}
				size="small"
				multiple
				loading={loading}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onChange={handleLabelChange}
				isOptionEqualToValue={(option, value) => option.name === value.name}
				filterSelectedOptions
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					const { inputValue } = params;

					const isExisting = options.some(
						(option) => inputValue === option.name
					);
					if (inputValue !== '' && !isExisting) {
						filtered.push({
							name: inputValue,
							label: `Add "${inputValue}"`,
							create: true,
						});
					}
					return filtered;
				}}
				options={options}
				getOptionLabel={(option) => {
					if (option.label) {
						return option.name;
					}
					// Regular option
					return option.name;
				}}
				renderOption={(props, option) => (
					<li {...props}>{option.create ? option.label : option.name}</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Key Skills"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>

			<ReactQuill
				className="mt-8"
				value={description}
				formats={getQuillFormats()}
				modules={getQuillModules()}
				onChange={setDescription}
			/>
			<div className="flex flex-row justify-end">
				<LoadingButton
					sx={{ textTransform: 'none', marginTop: '20px' }}
					size="small"
					// onClick={saveJobData}
					onClick={handleSubmit((data) => saveJobData(data))}
					endIcon={<SaveIcon />}
					loading={isSaving}
					loadingPosition="end"
					variant="contained"
					disabled={isSaving || !!Object.keys(formErrors).length}
				>
					<span>Save</span>
				</LoadingButton>
			</div>
			<BackdropComponent></BackdropComponent>
		</div>
	);
}
