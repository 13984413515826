import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import './Detail.scss';
import Note from './components/Note';
import Contact from './components/Contact';
import Resume from './components/Resume';
import Info from './components/Info';
import formStore from '../../../../stores/form.store';
import { formatDateTime } from 'src/libs/utils';

export default function Detail({ open, onClose }) {
	const selectedJobId = formStore((state) => state.selectedJobId);
	const [currentTab, setCurrentTab] = useState('1');
	const [updatedAt, setUpdatedAt] = useState(null);

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	return (
		<Drawer
			open={open}
			anchor="right"
			PaperProps={{
				sx: {
					width: {
						laptop: '50%',
						desktop: '40%',
						twoK: 980,
					},
					height: 'calc(100% - 76px)',
					top: 76,
				},
			}}
			onClose={onClose}
		>
			<div className="px-2 py-4 flex flex-col h-full">
				<div className="">
					<IconButton color="primary">
						<KeyboardTabIcon onClick={onClose} />
					</IconButton>
				</div>
				<Box
					sx={{ width: '100%', height: '100%', typography: 'body1' }}
					className="grow shrink basis-auto overflow-y-auto"
				>
					<TabContext value={currentTab}>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
								position: 'fixed',
								bgcolor: 'background.paper',
								zIndex: '100',
								width: '100%',
							}}
						>
							<TabList
								onChange={handleTabChange}
								variant="scrollable"
								scrollButtons="auto"
								aria-label="lab API tabs example"
							>
								<Tab sx={{ textTransform: 'none' }} label="Info" value="1" />
								{selectedJobId ? (
									<Tab
										sx={{ textTransform: 'none' }}
										label="Resume"
										value="2"
									/>
								) : (
									<></>
								)}
								{selectedJobId ? (
									<Tab
										sx={{ textTransform: 'none' }}
										label="Contact"
										value="3"
									/>
								) : (
									<></>
								)}
								{selectedJobId ? (
									<Tab sx={{ textTransform: 'none' }} label="Note" value="4" />
								) : (
									<></>
								)}
							</TabList>
						</Box>
						<TabPanel value="1">
							<div className="mt-12">
								<Info setUpdatedAt={setUpdatedAt}></Info>
							</div>
						</TabPanel>
						<TabPanel value="2">
							<div className="mt-12">
								<Resume setUpdatedAt={setUpdatedAt}></Resume>
							</div>
						</TabPanel>
						<TabPanel value="3">
							<div className="mt-12">
								<Contact setUpdatedAt={setUpdatedAt}></Contact>
							</div>
						</TabPanel>
						<TabPanel value="4">
							<div className="mt-12">
								<Note setUpdatedAt={setUpdatedAt}></Note>
							</div>
						</TabPanel>
					</TabContext>
				</Box>
				<div className="px-8">
					<Divider sx={{ marginTop: '0px' }}></Divider>
					<div className="flex flex-row justify-between items-center mt-1">
						{updatedAt && (
							<div className="text-sm text-slate-700">
								Last updated on{' '}
								{formatDateTime(updatedAt, 'MMM DD,YYYY h:mm A')}
							</div>
						)}
					</div>
				</div>
			</div>
		</Drawer>
	);
}
