import React, { useState, useEffect, useMemo } from 'react';
import styles from './Experience.module.scss';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {
	trimHtml,
	getDuration,
	getStartDate,
	getEndDate,
	getYears,
	getMonths,
	getQuillSimpleFormats,
	getQuillSimpleModules,
} from 'src/libs/utils';
import { useForm, Controller } from 'react-hook-form';
import resumeApi from 'src/api/resume.api';
import OutlinedInput from '@mui/material/OutlinedInput';
import useToast from 'src/components/Toast/useToast';
const classNames = require('classnames');

export default function Item({
	selectedResumeId,
	experienceData,
	handleCancelItem,
	handleEditItem,
	onSuccessSaved,
}) {
	const [newExperienceData, setNewExperienceData] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			position: newExperienceData.position,
			companyName: newExperienceData.companyName,
			startYear: newExperienceData.startYear,
			startMonth: newExperienceData.startMonth,
		},
	});

	const startDate = useMemo(() => {
		return getStartDate(experienceData.startYear, experienceData.startMonth);
	}, [experienceData.startYear, experienceData.startMonth]);

	const endDate = useMemo(() => {
		return getEndDate(
			experienceData.isCurrent,
			experienceData.endYear,
			experienceData.endMonth
		);
	}, [
		experienceData.isCurrent,
		experienceData.endYear,
		experienceData.endMonth,
	]);

	const duration = useMemo(() => {
		return getDuration(
			experienceData.startYear,
			experienceData.startMonth,
			experienceData.endYear,
			experienceData.endMonth,
			experienceData.isCurrent
		);
	}, [
		experienceData.startYear,
		experienceData.startMonth,
		experienceData.endYear,
		experienceData.endMonth,
		experienceData.isCurrent,
	]);

	const handleInputChange = (value, fieldName) => {
		newExperienceData[fieldName] = value;
		setNewExperienceData({ ...newExperienceData });
	};

	const handleDeleteItem = async (id) => {
		try {
			await resumeApi.deleteExperience(id);
			onSuccessSaved();
			showSuccess('Your work experience has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		}
	};

	const handleSaveItem = async (itemData) => {
		try {
			setIsSaving(true);
			if (itemData.id) {
				await resumeApi.updateExperience(itemData.id, { ...itemData });
			} else {
				await resumeApi.createExperience({
					resumeId: selectedResumeId,
					...itemData,
				});
			}
			onSuccessSaved();
			showSuccess('Your work experience has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError('Something went wrong!');
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		if (newExperienceData?.isCurrent) {
			handleInputChange(null, 'endMonth');
			handleInputChange(null, 'endYear');
		}
	}, [newExperienceData?.isCurrent]);

	useEffect(() => {
		console.log("the data of 'Experience/Item' component is loaded!!!!!!");
		setNewExperienceData({ ...experienceData });
	}, [experienceData.isEdit]);

	return (
		<div
			className={classNames(
				'flex flex-col',
				styles.item,
				experienceData.isEdit ? styles.edit : styles.view
			)}
		>
			{!experienceData.isEdit && (
				<React.Fragment>
					<div className="flex flex-row justify-between items-center">
						<span className={`${styles.position}`}>
							{newExperienceData.position}
						</span>
						<div className="basis-10">
							<IconButton
								color="primary"
								size="small"
								onClick={() => {
									handleEditItem(newExperienceData.id);
								}}
							>
								<BorderColorOutlinedIcon />
							</IconButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!!experienceData.isEdit && (
				<React.Fragment>
					<div className="mt-4 flex flex-col gap-y-6 pb-4">
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="position"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Position is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="position"
										size="small"
										required
										value={newExperienceData.position}
										onChange={(e) => {
											handleInputChange(e.target.value, 'position');
										}}
										label="Position"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
							<Controller
								name="companyName"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Company Name is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										name="companyName"
										size="small"
										required
										value={newExperienceData.companyName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'companyName');
										}}
										label="Company Name"
										variant="outlined"
										error={!!error}
										// helperText={error?.message}
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<TextField
								size="small"
								label="Location"
								value={newExperienceData.location}
								onChange={(e) => {
									handleInputChange(e.target.value, 'location');
								}}
								InputLabelProps={{ shrink: true }}
							/>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label" shrink size="small">
									Job Type
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									size="small"
									value={newExperienceData.jobType}
									input={<OutlinedInput notched label="Job Type" />}
									onChange={(e) => {
										handleInputChange(e.target.value, 'jobType');
									}}
								>
									<MenuItem value={'Full-Time'} size="small">
										Full-Time
									</MenuItem>
									<MenuItem value={'Part-Time'} size="small">
										Part-Time
									</MenuItem>
									<MenuItem value={'Intership'} size="small">
										Intership
									</MenuItem>
									<MenuItem value={'Co-founder'} size="small">
										Co-founder
									</MenuItem>
									<MenuItem value={'Founder'} size="small">
										Founder
									</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="grid grid-cols-1">
							<div className="justify-self-start">
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												value={true}
												checked={newExperienceData.isCurrent}
												onChange={(e) => {
													handleInputChange(e.target.checked, 'isCurrent');
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label="I am currently working in this position"
									/>
								</FormGroup>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-x-4">
							<Controller
								name="startMonth"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Start Month is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Start Month
										</InputLabel>
										<Select
											name="startMonth"
											label="Start Month"
											size="small"
											value={newExperienceData.startMonth}
											input={
												<OutlinedInput required notched label="Start Month" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'startMonth');
											}}
											error={!!error}
										>
											{getMonths().map((month, index) => (
												<MenuItem key={index} value={month.value} size="small">
													{month.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
							<Controller
								name="startYear"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Start Year is required',
									},
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<FormControl fullWidth required>
										<InputLabel error={!!error} shrink size="small">
											Start Year
										</InputLabel>
										<Select
											name="startYear"
											label="Start Year"
											size="small"
											value={newExperienceData.startYear}
											input={
												<OutlinedInput required notched label="Start Year" />
											}
											onChange={(e) => {
												handleInputChange(e.target.value, 'startYear');
											}}
											error={!!error}
										>
											{getYears().map((year, index) => (
												<MenuItem key={index} value={year} size="small">
													{year}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
						</div>
						{!newExperienceData?.isCurrent && (
							<div className="grid grid-cols-2 gap-x-4">
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										End Month
									</InputLabel>
									<Select
										label="End Month"
										size="small"
										value={newExperienceData.endMonth}
										input={<OutlinedInput notched label="End Month" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'endMonth');
										}}
									>
										{getMonths().map((month, index) => (
											<MenuItem key={index} value={month.value} size="small">
												{month.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl fullWidth>
									<InputLabel shrink size="small">
										End Year
									</InputLabel>
									<Select
										label="End Year"
										size="small"
										value={newExperienceData.endYear}
										input={<OutlinedInput notched label="End Year" />}
										onChange={(e) => {
											handleInputChange(e.target.value, 'endYear');
										}}
									>
										{getYears().map((year, index) => (
											<MenuItem key={index} value={year} size="small">
												{year}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						)}
						<div>
							<ReactQuill
								value={newExperienceData.description}
								onChange={(value) => {
									handleInputChange(value, 'description');
								}}
								formats={getQuillSimpleFormats()}
								modules={getQuillSimpleModules()}
							/>
						</div>
						<div className="flex flex-row gap-x-4">
							{!!newExperienceData.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteItem(newExperienceData.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<Button
								sx={{ textTransform: 'none' }}
								variant="text"
								size="small"
								onClick={() => {
									handleCancelItem(newExperienceData.id);
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit(() => handleSaveItem(newExperienceData))}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</div>
				</React.Fragment>
			)}
			{!experienceData.isEdit && (
				<React.Fragment>
					<span className={`${styles.company}`}>
						{experienceData.companyName} · {experienceData.jobType}
					</span>
					<span className={`${styles.date}`}>
						{startDate} - {endDate} · {duration}
					</span>
					<span className={`${styles.location}`}>
						{experienceData.location}
					</span>
					<div className="basis-4"></div>
					<div
						className={`${styles.description}`}
						dangerouslySetInnerHTML={{ __html: experienceData?.description }}
					/>
				</React.Fragment>
			)}
		</div>
	);
}
