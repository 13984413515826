import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export function formatDateTime(inputStr, format = 'YYYY-MM-DD HH:mm:ss') {
	return dayjs(inputStr).format(format);
}

export function getImageUrl(person, size = 's') {
	return 'https://i.imgur.com/' + person.imageId + size + '.jpg';
}

export function getHashParams() {
	const hash = window.location.hash.substr(1);
	const result = hash.split('&').reduce(function (res, item) {
		const parts = item.split('=');
		res[parts[0]] = parts[1];
		return res;
	}, {});
	return result;
}

export function trimHtml(str) {
	if (str) {
		const regex = /(<([^>]+)>)/gi;
		return str.replace(regex, '');
	}
}

/**
 *
 * @param {string} input, format as 'Y M'
 */
export function formatDuration(input) {
	const inputs = input.split(' ');
	let result = '';
	if (parseInt(inputs[0]) === 1) {
		result += inputs[0] + ' yr';
	} else if (parseInt(inputs[0]) > 1) {
		result += inputs[0] + ' yrs';
	}

	if (parseInt(inputs[1]) === 1) {
		result += ' ' + inputs[1] + ' mo';
	} else if (parseInt(inputs[1]) > 1) {
		result += ' ' + inputs[1] + ' mos';
	}
	if (parseInt(inputs[0]) === 0 && parseInt(inputs[1]) === 0) {
		result += '0 mo';
	}
	return result;
}

export function getDuration(
	startYear,
	startMonth,
	endYear,
	endMonth,
	isCurrent
) {
	const currentEndYear = !isCurrent ? endYear : dayjs().format('YYYY');
	const currentEndMonth = !isCurrent ? endMonth : dayjs().format('MM');
	const yearGap = parseInt(currentEndYear) - parseInt(startYear);
	const monthGap = parseInt(currentEndMonth) - parseInt(startMonth);

	return formatDuration(`${yearGap} ${monthGap}`);
}

export function getStartDate(startYear, startMonth) {
	return dayjs(`${startYear}-${startMonth}`).format('MMM YYYY');
}

export function getEndDate(isCurrent, endYear, endMonth) {
	if (isCurrent) return 'current';
	return dayjs(`${endYear}-${endMonth}`).format('MMM YYYY');
}

const years = Array(100)
	.fill(new Date().getFullYear())
	.map((year, i) => {
		return (year - i).toString();
	});
const simpleYears = Array(60)
	.fill(new Date().getFullYear() + 10)
	.map((year, i) => {
		return (year - i).toString();
	});
export function getYears() {
	return years;
}
export function getSimpleYears() {
	return simpleYears;
}

const months = [
	{ value: '01', label: 'January' },
	{ value: '02', label: 'February' },
	{ value: '03', label: 'March' },
	{ value: '04', label: 'April' },
	{ value: '05', label: 'May' },
	{ value: '06', label: 'June' },
	{ value: '07', label: 'July' },
	{ value: '08', label: 'August' },
	{ value: '09', label: 'September' },
	{ value: '10', label: 'October' },
	{ value: '11', label: 'November' },
	{ value: '12', label: 'December' },
];
export function getMonths() {
	return months;
}

const quillSimpleFormats = [
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'list',
	'bullet',
	'align',
	'link',
];
export function getQuillSimpleFormats() {
	return quillSimpleFormats;
}

const quillSimpleModules = {
	toolbar: [
		[
			{ font: [] },
			{ size: [] },
			'bold',
			'italic',
			'underline',
			'strike',
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ align: [] },
			'link',
		],
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};
export function getQuillSimpleModules() {
	return quillSimpleModules;
}

const quillFormats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
];
export function getQuillFormats() {
	return quillFormats;
}

const quillModules = {
	toolbar: [
		[{ header: '1' }, { header: '2' }, { font: [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
		],
		['link'],
		['clean'],
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};
export function getQuillModules() {
	return quillModules;
}
