import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import formStore from '../../../stores/form.store';
import Item from './Item';

export default function Education({
	className,
	educationsData,
	onSuccessSaved,
}) {
	const selectedResumeId = formStore((state) => state.selectedResumeId);
	const [newEducationsData, setNewEducationsData] = useState([]);
	const action = useMemo(() => {
		const onlyView = newEducationsData.every((item) => {
			if (item.id === undefined) return false;
			return true;
		});
		return onlyView ? 'view' : 'add';
	}, [newEducationsData]);

	const handleAddItem = () => {
		let originList = newEducationsData.map((item) => {
			item.isEdit = false;
			return item;
		});
		setNewEducationsData([{ isEdit: true }, ...originList]);
	};

	const handleCancelItem = (id) => {
		console.log('handleCancelItem', id);
		if (!id) {
			let newList = [...newEducationsData];
			newList.splice(
				newList.findIndex((e) => e.id === id),
				1
			);
			setNewEducationsData(newList);
			return;
		}
		let newList = newEducationsData.map((item) => {
			if (item.id === id) {
				item.isEdit = false;
			}
			return item;
		});
		setNewEducationsData(newList);
	};
	const handleEditItem = (id) => {
		console.log('handleEditItem', id);
		let newList = newEducationsData
			.filter((item) => {
				return !!item.id;
			})
			.map((item) => {
				if (item.id === id) {
					item.isEdit = true;
				} else {
					item.isEdit = false;
				}
				return item;
			});
		setNewEducationsData(newList);
	};

	useEffect(() => {
		console.log("the data of 'Education' component is loaded!!!!!!");
		const newEducationsData = educationsData
			? educationsData.map((item) => {
					return { ...item, isEdit: false };
				})
			: [];
		setNewEducationsData(newEducationsData);
	}, [educationsData]);

	return (
		<div
			className={`${className} bg-gray-100 px-4 pt-3 pb-4 rounded-sm shadow-sm`}
		>
			<div className="grid grid-cols-[1fr_60px] h-12">
				<div className="text-2xl font-semibold text-slate-700 self-center">
					Education
				</div>
				<div className="">
					{action === 'view' && (
						<Button
							sx={{ textTransform: 'none' }}
							variant="text"
							color="primary"
							onClick={handleAddItem}
							startIcon={<AddIcon />}
						>
							Add
						</Button>
					)}
				</div>
			</div>
			<div className="grid grid-cols-1 gap-y-4">
				{!!newEducationsData &&
					newEducationsData.map((newEducation) => (
						<Item
							key={newEducation.id}
							selectedResumeId={selectedResumeId}
							educationData={newEducation}
							onSuccessSaved={onSuccessSaved}
							handleCancelItem={handleCancelItem}
							handleEditItem={handleEditItem}
						></Item>
					))}
			</div>
		</div>
	);
}
