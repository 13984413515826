import React, { useEffect } from 'react';
import Prompt from './components/Prompt';
import Basic from './components/Basic';
import PricingTable from './components/PricingTable';
import Personal from './components/Personal';
import Activities from './components/Activities';
import PaymentHistory from './components/PaymentHistory';
import Subscriptions from './components/Subscriptions';
import paymentApi from 'src/api/payment.api';
import userApi from 'src/api/user.api';
import alertStore from 'src/stores/alert.store';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import userAuthStore from '../../stores/auth.store';
import { useState } from 'react';

export default function Account() {
	// const userId = userAuthStore((state) => state.userId);

	const [user, setUser] = useState({});
	const getUser = async () => {
		const response = await userApi.getCurrentWebUser();
		setUser(response.data);
	};

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		if (window.location.hash.substr(1)) {
			window.location = window.location.hash;
		}
		return () => {
			alertStore.setState({ alertOpen: false });
		};
	}, []);

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				rowSpacing={3}
				columnSpacing={{ xs: 3 }}
				maxWidth={'laptop'}
			>
				<Grid item xs={12} md={12}>
					<Subscriptions user={user} />
				</Grid>
				<Grid item xs={12} md={4}>
					<Basic />
				</Grid>
				<Grid item xs={12} md={8}>
					<Personal />
				</Grid>
				{/* <Grid item xs={12}>
					<h1>Banner!</h1>
				</Grid>
				<Grid item xs={12}>
					<PricingTable />
				</Grid> */}
				{/* <Grid item xs={12}>
					<Prompt />
				</Grid> */}
				<Grid item xs={12}>
					<Activities />
				</Grid>
				<Grid item xs={12}>
					<PaymentHistory />
				</Grid>
			</Grid>
		</div>
	);
}
