import userAuthStore from '../../../../../stores/auth.store';
import formStore from '../../../../../stores/form.store';
import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import jobApi from '../../../../../api/job.api';
import * as dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
	trimHtml,
	getQuillSimpleFormats,
	getQuillSimpleModules,
} from 'src/libs/utils';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import useToast from 'src/components/Toast/useToast';
const classNames = require('classnames');

export default function Note({ setUpdatedAt }) {
	const userId = userAuthStore((state) => state.userId);
	const selectedJobId = formStore((state) => state.selectedJobId);
	const [notes, setNotes] = useState([]);
	const [action, setAction] = useState(); // view, edit, new, list
	const [selectedNote, setSelectedNote] = useState({});
	const [selectedId, setSelectedId] = useState();
	const descriptionRef = useRef();
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [isSaving, setIsSaving] = useState(false);
	const { showSuccess, showError } = useToast();

	const {
		control,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		values: {
			description: selectedNote.description,
		},
	});

	const handleChange = (value) => {
		descriptionRef.current = value;
		setSelectedNote((prevState) => ({ ...prevState, description: value }));
	};

	const handleDeleteNote = async (id) => {
		try {
			await jobApi.deleteNote(id);
			setAction('list');
			setSelectedId();
			setSelectedNote({
				description: null,
			});
			listNote();
			showSuccess('Your note has been deleted successfully!');
		} catch (err) {
			console.error(err);
			showError();
		} finally {
		}
	};

	const getJobData = async () => {
		const response = await jobApi.getJob(selectedJobId);
		setUpdatedAt(response.data.updatedAt);
	};

	const listNote = async () => {
		try {
			showBackdrop();
			const response = await jobApi.listNote({
				sortby: 'updatedAt',
				order: 'desc',
				limit: 0,
				field: 'id,description,updatedAt',
				query: `jobId:${selectedJobId}`,
			});
			setNotes(response.data.rows);
			getJobData();
		} catch (err) {
		} finally {
			closeBackdrop();
		}
	};

	const handleSaveNote = async () => {
		try {
			setIsSaving(true);
			if (selectedNote.id) {
				await jobApi.updateNote({ ...selectedNote });
			} else if (!selectedNote.id) {
				const response = await jobApi.createNote({
					jobId: selectedJobId,
					userId,
					description: selectedNote.description,
				});
				setSelectedId(response.data.id);
			}
			setAction('view');
			listNote();
			showSuccess('Your note has been saved successfully!');
		} catch (err) {
			console.error(err);
			showError();
		} finally {
			setIsSaving(false);
		}
	};

	const wordCounter = (value) => {
		return value ? trimHtml(value).length : 0;
	};

	const getClassname = (noteId) => {
		return classNames(
			selectedNote.id === noteId ? 'bg-blue-100' : 'bg-gray-100',
			'rounded-md',
			'shadow-sm',
			'px-3',
			'py-4',
			'flex',
			'flex-row',
			'justify-between',
			'items-center',
			'cursor-pointer'
		);
	};

	useEffect(() => {
		if (action === 'new') {
			setSelectedId();
			setSelectedNote({});
			setAction('new');
			descriptionRef.current = '';
		}
	}, [action]);

	useEffect(() => {
		const getNoteData = async () => {
			console.log('selectedId change', selectedId);
			if (selectedId) {
				const response = await jobApi.getNote(selectedId);
				setSelectedNote(response.data);
				setAction('view');
				descriptionRef.current = response.data.description;
			}
		};
		getNoteData();
	}, [selectedId]);

	useEffect(() => {
		console.log('Note componentDidMount');
		listNote();
	}, []);

	return (
		<div className="flex flex-row gap-x-8">
			<div style={{ width: '280px', flexShrink: '0' }}>
				<div className="flex flex-col gap-y-1">
					{notes.map((note) => (
						<div
							key={note.id}
							className={getClassname(note.id)}
							onClick={() => setSelectedId(note.id)}
						>
							<div className="text-slate-700 font-bold text-sm grow ellipsis">
								<span>{trimHtml(note.description)}</span>
							</div>
							<div className="text-slate-500 text-xs">
								{dayjs(note.updatedAt).format('MMM DD @ hh:mm A')}
							</div>
						</div>
					))}
					<Button
						size="medium"
						variant="outlined"
						sx={{ textTransform: 'none', width: '280px' }}
						onClick={() => setAction('new')}
					>
						Add note
					</Button>
				</div>
			</div>
			<div className="grow flex flex-col gap-y-1">
				{!!selectedNote.id && (
					<div className="bg-gray-100 rounded-t-md px-3 py-2 flex flex-row items-center">
						<IconButton
							color="primary"
							size="small"
							onClick={() => {
								setAction('edit');
							}}
						>
							<BorderColorOutlinedIcon />
						</IconButton>
						<div className="grow ellipsis ml-2">
							<span className="text-slate-700 text-sm font-bold">
								{trimHtml(descriptionRef.current)}
							</span>
						</div>
					</div>
				)}
				{action === 'view' && (
					<div className="bg-gray-100 px-3 py-4 text-slate-500 text-sm">
						<div dangerouslySetInnerHTML={{ __html: descriptionRef.current }} />
					</div>
				)}
				{(action === 'edit' || action === 'new') && (
					<React.Fragment>
						<div>
							<Controller
								name="description"
								control={control}
								rules={{
									validate: (value) =>
										wordCounter(value) > 0 || 'Description is required',
								}}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<ReactQuill
										value={descriptionRef.current || ''}
										formats={getQuillSimpleFormats()}
										modules={getQuillSimpleModules()}
										onChange={handleChange}
									/>
								)}
							/>
						</div>
						<div className="flex flex-row justify-between items-center">
							{!!selectedNote.id && (
								<Button
									sx={{
										'&:hover': { backgroundColor: '#d1d6d4' },
										textTransform: 'none',
										backgroundColor: '#e2e6e4',
										color: '#0e0e0f',
									}}
									variant="contained"
									size="small"
									onClick={() => {
										handleDeleteNote(selectedNote.id);
									}}
								>
									Delete
								</Button>
							)}
							<div className="grow"></div>
							<span className="text-xs text-red-700 mr-2">
								{formErrors?.description?.message}
							</span>
							{/* <Button sx={{ textTransform: 'none' }} size="small" variant="contained" onClick={handleSaveNote}>Save</Button> */}
							<LoadingButton
								sx={{ textTransform: 'none' }}
								size="small"
								onClick={handleSubmit((data) => handleSaveNote(data))}
								endIcon={<SaveIcon />}
								loading={isSaving}
								loadingPosition="end"
								variant="contained"
								disabled={isSaving || !!Object.keys(formErrors).length}
							>
								<span>Save</span>
							</LoadingButton>
						</div>
					</React.Fragment>
				)}
			</div>
			<BackdropComponent></BackdropComponent>
		</div>
	);
}
