import ApiFactory from 'src/api/ApiFactory';
import dayjs from 'dayjs';

const api = {
	updateIdealJob(id, location, jobType, jobLevel, workType) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			location,
			jobType,
			jobLevel,
			workType,
		};
		return apiInstance.put(`/user/idea-job`, data);
	},
};
export default api;
