import React, { useEffect, useState, useMemo } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import {
	ToggleButton,
	ToggleButtonGroup,
	Grid,
	Button,
	Paper,
	Typography,
	CircularProgress,
} from '@mui/material';
import jobAPi from 'src/api/job.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Link } from 'react-router-dom';
import { mainClass } from './styles';
import { useRequest } from 'ahooks';
const classNames = require('classnames');

dayjs.extend(utc);

export default function StatusTracking() {
	const [alignment, setAlignment] = useState(30);
	const [list, setList] = useState([]);

	const stackData = useMemo(() => {
		return list.map((item) => {
			return {
				time: dayjs(item.time).local().format('YYYY-MM-DD'),
				Saved: item.APPLY,
				Applied: item.REQUEST,
				Interview: item.INTERVIEW,
				Offer: item.OFFER,
				Archived: item.ARCHIVE,
			};
		});
	}, [list]);

	useEffect(() => {
		(async () => {
			const response = await jobAPi.getStatusTracking({
				startDate: dayjs().subtract(alignment, 'd').format('YYYY-MM-DD'),
				endDate: dayjs().add(1, 'd').format('YYYY-MM-DD'),
			});

			// console.log(`response.data`)
			// console.log(response.data)
			setList(response.data);
		})();
	}, [alignment]);

	const { data: jobActivitiesSummary, loading: jobActivitiesSummaryLoading } =
		useRequest(jobAPi.getStatusTrackingSummary);

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="secondary" sx={{ fontWeight: 700 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.USAGE || 0
						)}
					</Typography>
					<Typography variant="caption">Tailored Resume</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="secondary" sx={{ fontWeight: 700 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.COVER_LETTER_USAGE || 0
						)}
					</Typography>
					<Typography variant="caption">Tailored Cover Letter</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="primary" sx={{ fontWeight: 700 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.APPLY || 0
						)}
					</Typography>
					<Typography variant="caption">Saved Jobs</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="#009688" sx={{ fontWeight: 700 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.REQUEST || 0
						)}
					</Typography>
					<Typography variant="caption">Applied Positions</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="#81c784" sx={{ fontWeight: 700 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.INTERVIEW || 0
						)}
					</Typography>
					<Typography variant="caption">Interviewing</Typography>
				</Paper>
			</Grid>
			<Grid item xs={6} sm={4} md={2}>
				<Paper sx={mainClass.numberCard}>
					<Typography variant="h4" color="#ff8a65" sx={{ fontWeight: 900 }}>
						{jobActivitiesSummaryLoading ? (
							<CircularProgress />
						) : (
							jobActivitiesSummary?.data?.OFFER || 0
						)}
					</Typography>
					<Typography variant="caption">Offer Received</Typography>
				</Paper>
			</Grid>
			{list?.length ? (
				<Grid item xs={12}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography variant="h6" gutterBottom>
							Activities over the last 30 days
						</Typography>
						<ResponsiveContainer height={300}>
							<BarChart
								data={stackData}
								margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
							>
								<XAxis dataKey="time" tick={{ fontSize: 12 }} />
								<YAxis tick={{ fontSize: 12 }} />
								<Tooltip wrapperStyle={{ fontSize: '12px' }} />
								<Legend wrapperStyle={{ fontSize: '12px' }} />
								<Bar dataKey="Saved" stackId="a" fill="#64b5f6" />
								<Bar dataKey="Applied" stackId="a" fill="#009688" />
								<Bar dataKey="Interview" stackId="a" fill="#81c784" />
								<Bar dataKey="Offer" stackId="a" fill="#ff8a65" />
								<Bar dataKey="Archived" stackId="a" fill="#90a4ae" />
							</BarChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>
			) : (
				<Grid item size={{ xs: 12 }}>
					<Typography variant="h6" gutterBottom>
						Activities over the last 30 days
					</Typography>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{window.location.pathname.indexOf('public') < 0 && (
							<>
								<span className="text-sm text-slate-700">
									After you begin your application journey, we’ll display your
									progress here
								</span>
								<div className="flex flex-row">
									<Button
										component={Link}
										to="/job-tracker"
										variant="contained"
										sx={{ textTransform: 'none' }}
									>
										<span className="font-bold text-white text-sm cursor-pointer">
											Job Tracker
										</span>
									</Button>
								</div>
							</>
						)}
					</Paper>
				</Grid>
			)}
		</Grid>
	);
}
