import './styles/theme.scss';
import './App.css';
import Router from 'src/routes';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from 'src/libs/history';

function App() {
	return (
		<HistoryRouter history={history}>
			<Router></Router>
		</HistoryRouter>
	);
}

export default App;
